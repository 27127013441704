import { useCallback, useState, useEffect } from 'react';
import { flow, get, difference, differenceWith, size, isEmpty } from 'lodash';
import classNames from 'classnames';
import { connect } from 'react-redux';
import { useRouteMatch, useLocation } from 'react-router-dom';
import { t } from 'i18next';
import { parse } from 'qs';
import { useQuery } from '@apollo/client';
import { useSnackbar } from 'notistack';

import { useFormContext } from 'react-hook-form';
import { getCatalogByArchitectureId } from 'src/pages/Program/queries';
import { businessObjectsFromArchitecture } from 'src/common/businessObjects';

import { Button, Divider, Typography, IconButton, Box } from '@mui/material';
import withStyles from '@mui/styles/withStyles';

import DoneAllIcon from '@mui/icons-material/DoneAll';
import InfoIcon from '@mui/icons-material/InfoOutlined';
import EditIcon from '@mui/icons-material/EditOutlined';

import Instrumentation from 'src/instrumentation';
import { generateQueryParams } from 'src/routes/RouteUtil';
import useIsProgramCreatePage from 'src/routes/useIsProgramCreatePage';

import ContentTable from 'src/components/ContentTable/ContentTable';

import Modal from 'src/components/Modal';

import BusinessObjectList from './BusinessObjectList';

import {
  closeBusinessObjectSelectorModal,
  showBusinessObjectSelectorModal
} from './actions';

const styles = theme => ({
  container: {
    position: 'relative',
    width: '100%'
  },
  title: {
    alignItems: 'center',
    display: 'flex'
  },
  button: {
    width: '100%'
  },
  buttonIcon: {
    marginRight: theme.spacing(1)
  },
  error: {
    color: theme.palette.error.main
  },
  errorButton: {
    color: theme.palette.error.main,
    borderColor: theme.palette.error.main
  },

  minMax: {
    color: theme.palette.success.main,
    paddingStart: theme.spacing(2),
    fontWeight: 'bold',
    flexGrow: 2,
    '& span': {
      fontWeight: 'normal'
    }
  },
  minMaxWarn: {
    color: theme.palette.warning.main
  }
});

const BusinessObjectSelector = props => {
  const {
    architecture,
    classes,
    contentName,
    onChange,
    name,
    selectedIds = [],
    closeBusinessObjectSelectorModal,
    modalOpen,
    selectedBusinessObjects,
    contentGroupKey,
    minMaxSelection,
    showBusinessObjectSelectorModal,
    selectedBlueprint,
    meta: { error, touched },
    label,
    skipStep,
    setSkipStep,
    setSelectedBusinessObjects,
    isHookForm,
    allFormValues
  } = props;
  const match = useRouteMatch();
  const location = useLocation();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const [contentCountMeta, setContentCountMeta] = useState({});
  const [rowSelectionModel, setRowSelectionModel] = useState(selectedIds);
  // we only care about invalid content from deeplinks which is only the first load only
  const [hasRunOnce, setHasRunOnce] = useState(false);

  const formMethods = useFormContext();

  let formContentIds = isHookForm
    ? formMethods?.getValues()?.configureStep?.businessObjectSelector
    : allFormValues?.configureStep?.businessObjectSelector;

  formContentIds = formContentIds || [];

  const selectedContent = useQuery(getCatalogByArchitectureId, {
    skip: formContentIds.length <= 0,
    variables: {
      architectureId: match?.params?.architectureId,
      ...(formContentIds.length && {
        externalIds: formContentIds
      })
    }
  });

  const updateQueryParam = useCallback(
    (selectedIdsArray = []) => {
      const currentQueryParams =
        { ...parse(location.search.substr(1)), contentIds: selectedIdsArray } ||
        {};

      if (isEmpty(selectedIdsArray)) {
        delete currentQueryParams.contentIds;
      }
      // Note: dealing with the url this way because it causes a refresh
      //       when using history.push or .replace. This will update the url
      //       alone and not trigger a refresh.
      window.history.replaceState(
        null,
        null,
        `#${location.pathname}?${generateQueryParams(currentQueryParams)}`
      );
    },
    [location]
  );

  const contentMissingAction = ids => {
    const newSelectedIds = difference(selectedIds, ids);
    updateQueryParam(newSelectedIds);

    onChange(newSelectedIds);

    const action = key => (
      <>
        <Button
          onClick={() => {
            closeSnackbar(key);
          }}
        >
          {t('businessObjectSelector:snackbar.dismiss')}
        </Button>
      </>
    );

    Instrumentation.logEvent('program-selected-content-not-found', {
      selectedContentIds: ids,
      architectureId: match?.params?.architectureId,
      selectedBlueprint: selectedBlueprint.id
    });

    enqueueSnackbar(
      t('businessObjectSelector:snackbar.missingBOs', {
        contentName: contentName ? contentName.toLowerCase() : 'content items'
      }),
      {
        persist: true,
        variant: 'warning',
        action
      }
    );
  };

  const handleMissingContent = useCallback(
    ids => {
      contentMissingAction(ids);
      if (skipStep) {
        setSkipStep(null);
      }
    },
    [contentMissingAction, setSkipStep, skipStep]
  );

  useEffect(() => {
    setSelectedBusinessObjects({
      selectedBusinessObjects: selectedContent?.loading
        ? []
        : businessObjectsFromArchitecture(selectedContent?.data?.architecture),
      loading: selectedContent?.loading,
      error: selectedContent?.error
    });
  }, [selectedContent]);

  // missing Content
  useEffect(() => {
    if (
      !selectedBusinessObjects?.loading &&
      selectedIds.length > 0 &&
      !hasRunOnce
    ) {
      const missingContentIds = differenceWith(
        selectedIds,
        selectedBusinessObjects?.selectedBusinessObjects,
        (id, bo) => bo.id === id
      );

      if (!selectedBusinessObjects.loading) {
        if (missingContentIds.length) {
          handleMissingContent(missingContentIds);
        } else if (skipStep && !skipStep?.contentVerified) {
          setSkipStep(skipStep => ({ ...skipStep, contentVerified: true }));
        }
      }
      setHasRunOnce(true);
    }
    if (!selectedBusinessObjects?.loading && !hasRunOnce) {
      setHasRunOnce(true);
    }
  }, [selectedBusinessObjects.loading, selectedIds, hasRunOnce]);

  const isProgramCreatePage = useIsProgramCreatePage();

  const singleSelect = minMaxSelection.max === 1;

  const handleOpenModal = useCallback(() => {
    showBusinessObjectSelectorModal();
  }, [selectedIds, showBusinessObjectSelectorModal]);

  const handleAddBusinessObject = useCallback(() => {
    const selectedIds = rowSelectionModel;

    updateQueryParam(selectedIds);

    onChange(selectedIds);

    Instrumentation.logEvent(
      'click-program-business-objects-selector-confirm-clicked',
      {
        contentIds: selectedIds
      }
    );

    closeBusinessObjectSelectorModal();
  }, [
    closeBusinessObjectSelectorModal,
    onChange,
    rowSelectionModel,
    minMaxSelection,
    updateQueryParam,
    name
  ]);

  const handleRemoveBusinessObject = useCallback(
    removeId => {
      const newSelectedIds = rowSelectionModel.filter(id => id !== removeId);
      setRowSelectionModel(newSelectedIds);
      updateQueryParam(newSelectedIds);
      onChange(newSelectedIds);
    },
    [onChange, rowSelectionModel, updateQueryParam, setRowSelectionModel]
  );

  const architectureId = get(match, 'params.architectureId');
  const headerText = `${t('businessObjectSelector:header.select')} ${
    contentName || t('businessObjectSelector:header.content')
  }`;
  const emptyBusinessObjectText = `${t(
    'businessObjectSelector:noObjectsSelected'
  )} ${contentName || t('businessObjectSelector:header.content')}`;

  const validNumberSelected =
    size(rowSelectionModel) >= minMaxSelection.min &&
    size(rowSelectionModel) <= minMaxSelection.max;

  const inputInError = isHookForm ? error : error && touched;

  return (
    <div className={classes.container}>
      <Modal
        dialogContentSx={{ pt: '0' }} // override default padding
        fullWidth
        headerText={headerText}
        maxWidth="lg"
        onClose={() => {
          closeBusinessObjectSelectorModal();
          // reset selected ids
          setRowSelectionModel(selectedIds);
          Instrumentation.logEvent(
            Instrumentation.Events.ProgramBusinessObjectsSelectorCloseClicked,
            contentCountMeta
          );
        }}
        open={modalOpen}
        data-cy="businessObjects-modal"
        loading={selectedBusinessObjects?.loading}
        FooterComponent={
          <>
            <div className={classes.minMax}>
              {!singleSelect && (
                <div
                  className={classNames({
                    [classes.minMaxWarn]: !validNumberSelected
                  })}
                >
                  {validNumberSelected ? (
                    <DoneAllIcon
                      style={{
                        verticalAlign: 'bottom'
                      }}
                    />
                  ) : (
                    <InfoIcon
                      style={{
                        verticalAlign: 'bottom'
                      }}
                    />
                  )}{' '}
                  {size(rowSelectionModel)} selected{' '}
                  <span>
                    ({minMaxSelection.min}{' '}
                    {minMaxSelection.min < minMaxSelection.max && (
                      <>to {minMaxSelection.max} </>
                    )}
                    required)
                  </span>
                </div>
              )}
            </div>

            <Button
              variant="contained"
              color="primary"
              onClick={handleAddBusinessObject}
              data-cy="select-content-button"
              disabled={!validNumberSelected}
            >
              {t('businessObjectSelector:modal.doneButton')}
            </Button>
          </>
        }
      >
        <Box sx={{ height: '600px' }}>
          <ContentTable
            minMaxSelection={minMaxSelection}
            architectureId={architectureId}
            displayCollapseKey={contentGroupKey}
            setContentCountMeta={setContentCountMeta}
            rowSelectionModel={rowSelectionModel}
            setRowSelectionModel={setRowSelectionModel}
            pageSize={25}
            showBorder={false}
          />
        </Box>
      </Modal>

      {!isProgramCreatePage && (
        <>
          <Typography
            className={classNames(classes.title, {
              [classes.error]: error && touched
            })}
            variant="body2"
          >
            {label}

            {!isEmpty(selectedBusinessObjects?.selectedBusinessObjects) && (
              <IconButton
                size="small"
                onClick={handleOpenModal}
                color={inputInError ? 'error' : 'default'}
                data-cy="configure-content-button"
              >
                <EditIcon fontSize="inherit" />
              </IconButton>
            )}
          </Typography>
          <Divider />{' '}
        </>
      )}

      <BusinessObjectList
        architecture={architecture}
        error={inputInError}
        emptyBusinessObjectText={emptyBusinessObjectText}
        onDelete={handleRemoveBusinessObject}
        selectedBusinessObjects={selectedBusinessObjects}
        selectedIds={selectedIds}
        contentName={contentName}
        openModal={handleOpenModal}
      />

      {inputInError && (
        <Typography
          className={classNames(classes.title, {
            [classes.error]: inputInError
          })}
          variant="body2"
        >
          {error}
        </Typography>
      )}
    </div>
  );
};

const mapStateToProps = state => ({
  modalOpen: get(state, 'businessObjectSelector.modalOpen')
});

export default flow(
  connect(mapStateToProps, {
    closeBusinessObjectSelectorModal,
    showBusinessObjectSelectorModal
  }),
  withStyles(styles)
)(BusinessObjectSelector);
