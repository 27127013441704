import { t } from 'i18next';

export const aiChatTypes = {
  copywriter: t('aiSuggestion:chat.type')
} as const;

export type AiChatType = (typeof aiChatTypes)[keyof typeof aiChatTypes];

export const aiChatWindowDimensions = {
  width: 375,
  height: '65vh',
  minHeight: 300,
  xPosition: 20
};

export const aiChatMessageTypes = {
  text: 'text',
  buttonInput: 'buttonInput',
  adCopySuggestion: 'adCopySuggestion'
} as const;

export type AiChatMessageType =
  (typeof aiChatMessageTypes)[keyof typeof aiChatMessageTypes];
