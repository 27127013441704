import {
  Box,
  Skeleton,
  TableRow,
  TableCell,
  TableContainer,
  TableBody,
  Table
} from '@mui/material';

const DataTableSkeleton = ({ numRows = 5 }) => {
  return (
    <Box
      sx={{
        width: '100%',
        border: theme => `1px solid ${theme.palette.divider}`,
        paddingTop: theme => theme.spacing(6.5)
      }}
    >
      <TableContainer>
        <Table>
          <TableBody>
            {[...Array(numRows)].map(row => (
              <TableRow key={row}>
                <TableCell component="th" scope="row">
                  <Skeleton animation="wave" variant="text" />
                </TableCell>
                <TableCell>
                  <Skeleton animation="wave" variant="text" />
                </TableCell>
                <TableCell>
                  <Skeleton animation="wave" variant="text" />
                </TableCell>
                <TableCell>
                  <Skeleton animation="wave" variant="text" />
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};

export default DataTableSkeleton;
