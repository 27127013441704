import { sortBy, isNumber } from 'lodash';

import { Tooltip, Box, Radio } from '@mui/material';
import {
  GridRenderCellParams,
  getGridDateOperators,
  getGridNumericOperators,
  getGridStringOperators,
  GRID_CHECKBOX_SELECTION_COL_DEF,
  GridFilterModel,
  GridValueFormatterParams
} from '@mui/x-data-grid-pro';

import formatters from 'src/common/formatters';

import { INPUT_TYPES } from 'src/components/ReduxForm/DynamicForm/constants';
import ImageCell from 'src/components/DataTable/Cells/ImageCell';
import JsonCell from 'src/components/DataTable/Cells/JsonCell';

const SUPPORTED_STRING_FILTERS = ['contains', 'equals'];
const SUPPORTED_NUMERIC_FILTERS = ['=', '!=', '>', '>=', '<', '<='];
const SUPPORTED_DATE_FILTERS = ['before', 'after'];

export const buildContentFilter = (filterModel: GridFilterModel) => {
  const elements = filterModel.items
    .map((i: any) => {
      switch (i.operator) {
        // string
        case 'contains':
          return { [i.field]: { contains: i.value } };
        case 'equals':
          return { [i.field]: { eq: i.value } };
        case 'isEmpty':
          return {
            or: [{ [i.field]: 'is_null' }, { [i.field]: { eq: '' } }]
          };
        case 'isNotEmpty':
          return {
            and: [
              { not: { [i.field]: 'is_null' } },
              { not: { [i.field]: { eq: '' } } }
            ]
          };

        // number
        case '=':
          return { [i.field]: { eq: Number(i.value) } };
        case '!=':
          return {
            not: { [i.field]: { eq: Number(i.value) } }
          };
        case '>':
          return { [i.field]: { gt: Number(i.value) } };
        case '>=':
          return { [i.field]: { gte: Number(i.value) } };
        case '<':
          return { [i.field]: { lt: Number(i.value) } };
        case '<=':
          return { [i.field]: { lte: Number(i.value) } };

        // date
        case 'before':
          return {
            [i.field]: {
              before: new Date(i.value).toISOString()
            }
          };
        case 'after':
          return {
            [i.field]: {
              after: new Date(i.value).toISOString()
            }
          };

        // boolean
        case 'is':
          return {
            [i.field]: i.value === 'true' ? 'is_true' : 'is_false'
          };

        default:
          return undefined;
      }
    })
    .filter((e: any) => !!e);

  switch (filterModel.logicOperator) {
    case 'and':
      return { and: [...elements] };
    case 'or':
      return { or: [...elements] };
    default:
      throw Error('Uknown link operator type');
  }
};

export const getSupportedFilterOperators = (type: string) => {
  switch (type) {
    case 'string':
    case 'ID':
    case INPUT_TYPES.FB_AUDIENCE_ID:
    case INPUT_TYPES.SINGLE_LINE_STRING:
    case INPUT_TYPES.MULTI_LINE_STRING:
    case INPUT_TYPES.ZIP:
    case INPUT_TYPES.VIDEO_URL:
    case INPUT_TYPES.LINK_URL:
    case INPUT_TYPES.IMAGE_URL:
      return getGridStringOperators().filter(({ value }) =>
        SUPPORTED_STRING_FILTERS.includes(value)
      );
    case 'number':
    case INPUT_TYPES.PERCENTAGE_DECIMAL:
    case INPUT_TYPES.POSITIVE_NUM:
    case INPUT_TYPES.ANY_NUMBER:
    case INPUT_TYPES.POSITIVE_INT:
    case INPUT_TYPES.PRICE_DECIMAL:
    case INPUT_TYPES.PRICE_INT:
      return getGridNumericOperators().filter(({ value }) =>
        SUPPORTED_NUMERIC_FILTERS.includes(value)
      );
    case 'date_time':
    case 'date':
    case 'datetime_utc':
    case INPUT_TYPES.DATE_UTC:
      return getGridDateOperators(true).filter(({ value }) =>
        SUPPORTED_DATE_FILTERS.includes(value)
      );
    case 'boolean':
    case INPUT_TYPES.BOOLEAN:
    default:
      return undefined;
  }
};

// TODO: check with BE to see if this is still the case
// Note these values are returned by the content API right now but the user
// should never see them. A card has been created to have this removed at the
// API level but just in case they can't get to it, we're hiding them in the ui.
export const INTERNAL_ONLY_FIELDS: { [key: string]: boolean } = {
  feed_item_id: true,
  edit_item_id: true,
  feed_item_created_at: true,
  edit_item_created_at: true,
  feed_item_updated_at: true,
  edit_item_updated_at: true,
  user_id_external: true,
  group_id_external: true
};

const getColumnTypeByColumnMetadata = (
  columnMetadata: any,
  highlightedColumns?: string[]
) => {
  const type = columnMetadata?.displayMethodId;
  const highlightColumn = highlightedColumns
    ? highlightedColumns.includes(columnMetadata?.fieldName)
    : false;

  switch (type) {
    case INPUT_TYPES.IMAGE_URL:
      return {
        headerName: columnMetadata?.displayName,
        field: columnMetadata?.fieldName,
        align: 'center',
        disableColumnMenu: true,
        disableReorder: true,
        editable: false,
        headerAlign: 'center',
        hideable: true,
        sortable: false,
        type,
        filterable: columnMetadata?.isFilterable || false,
        filterOperators: getSupportedFilterOperators(type),
        width: 75,
        renderCell: ({ value }: GridRenderCellParams) => {
          return <ImageCell value={value} />;
        },
        ...(highlightColumn && {
          cellClassName: 'highlight-cell'
        })
      };
    case INPUT_TYPES.PRICE_DECIMAL:
    // falls through
    case INPUT_TYPES.PRICE_INT:
      return {
        headerName: columnMetadata?.displayName,
        field: columnMetadata?.fieldName,
        align: 'left',
        disableColumnMenu: true,
        disableReorder: true,
        editable: false,
        headerAlign: 'left',
        hideable: true,
        sortable: false,
        type,
        filterable: columnMetadata?.isFilterable || false,
        filterOperators: getSupportedFilterOperators(type),
        valueFormatter: ({ value }: GridValueFormatterParams) => {
          return formatters.CURRENCY(value);
        },
        ...(highlightColumn && {
          cellClassName: 'highlight-cell'
        }),
        maxWidth: 300
      };
    case INPUT_TYPES.POSITIVE_INT:
      return {
        headerName: columnMetadata?.displayName,
        field: columnMetadata?.fieldName,
        align: 'left',
        disableColumnMenu: true,
        disableReorder: true,
        editable: false,
        headerAlign: 'left',
        hideable: true,
        sortable: false,
        type,
        filterable: columnMetadata?.isFilterable || false,
        filterOperators: getSupportedFilterOperators(type),
        renderCell: ({ value }: GridRenderCellParams) => {
          return formatters.NUMBER(value);
        },
        ...(highlightColumn && {
          cellClassName: 'highlight-cell'
        }),
        maxWidth: 300
      };
    case INPUT_TYPES.POSITIVE_NUM:
    // falls through
    case INPUT_TYPES.ANY_NUMBER:
      return {
        headerName: columnMetadata?.displayName,
        field: columnMetadata?.fieldName,
        align: 'left',
        disableColumnMenu: true,
        disableReorder: true,
        editable: false,
        headerAlign: 'left',
        hideable: true,
        sortable: false,
        type,
        filterable: columnMetadata?.isFilterable || false,
        filterOperators: getSupportedFilterOperators(type),
        renderCell: ({ value }: GridRenderCellParams) => {
          return formatters.FLOAT(value);
        },
        ...(highlightColumn && {
          cellClassName: 'highlight-cell'
        }),
        maxWidth: 300
      };
    case 'datetime_utc':
    // falls through
    case INPUT_TYPES.DATE_UTC:
      return {
        headerName: columnMetadata?.displayName,
        field: columnMetadata?.fieldName,
        align: 'left',
        disableColumnMenu: true,
        disableReorder: true,
        editable: false,
        headerAlign: 'left',
        hideable: true,
        sortable: false,
        type,
        filterable: columnMetadata?.isFilterable || false,
        filterOperators: getSupportedFilterOperators(type),
        renderCell: ({ value }: GridRenderCellParams) => {
          return formatters.DATE(value);
        },
        ...(highlightColumn && {
          cellClassName: 'highlight-cell'
        }),
        maxWidth: 300
      };
    case INPUT_TYPES.PERCENTAGE_DECIMAL:
      return {
        headerName: columnMetadata?.displayName,
        field: columnMetadata?.fieldName,
        align: 'left',
        disableColumnMenu: true,
        disableReorder: true,
        editable: false,
        headerAlign: 'left',
        hideable: true,
        sortable: false,
        type,
        filterable: columnMetadata?.isFilterable || false,
        filterOperators: getSupportedFilterOperators(type),
        renderCell: ({ value }: GridRenderCellParams) => {
          let display = value;
          const num = parseFloat(value);
          if (!Number.isNaN(num) && isNumber(num)) {
            display = `${Math.round(num * 100)}%`;
          }
          return display;
        },
        ...(highlightColumn && {
          cellClassName: 'highlight-cell'
        }),
        maxWidth: 300
      };
    case INPUT_TYPES.VIDEO_URL:
    // falls through
    case INPUT_TYPES.LINK_URL:
      return {
        headerName: columnMetadata?.displayName,
        field: columnMetadata?.fieldName,
        align: 'left',
        disableColumnMenu: true,
        disableReorder: true,
        editable: false,
        headerAlign: 'left',
        hideable: true,
        sortable: false,
        type,
        filterable: columnMetadata?.isFilterable || false,
        filterOperators: getSupportedFilterOperators(type),
        renderCell: ({ value }: GridRenderCellParams) => {
          return (
            <a href={value} rel="noopener noreferrer" target="_blank">
              {value}
            </a>
          );
        },
        ...(highlightColumn && {
          cellClassName: 'highlight-cell'
        }),
        maxWidth: 250
      };
    case INPUT_TYPES.BOOLEAN:
      return {
        headerName: columnMetadata?.displayName,
        field: columnMetadata?.fieldName,
        align: 'left',
        disableColumnMenu: true,
        disableReorder: true,
        editable: false,
        headerAlign: 'left',
        hideable: true,
        sortable: false,
        type,
        filterable: columnMetadata?.isFilterable || false,
        filterOperators: getSupportedFilterOperators(type),
        renderCell: ({ value }: GridRenderCellParams) => {
          return value === true ? 'true' : 'false';
        },
        ...(highlightColumn && {
          cellClassName: 'highlight-cell'
        }),
        maxWidth: 200
      };
    case INPUT_TYPES.FB_AUDIENCE_ID:
    // falls through
    case INPUT_TYPES.SINGLE_LINE_STRING:
    // falls through
    case INPUT_TYPES.MULTI_LINE_STRING:
      return {
        headerName: columnMetadata?.displayName,
        field: columnMetadata?.fieldName,
        align: 'left',
        disableColumnMenu: true,
        disableReorder: true,
        editable: false,
        headerAlign: 'left',
        hideable: true,
        sortable: false,
        type,
        filterable: columnMetadata?.isFilterable || false,
        filterOperators: getSupportedFilterOperators(type),
        renderCell: ({ value }: GridRenderCellParams) => {
          // for now, we will show the tooltip if the text length is over 50
          // characters long.
          return (
            <>
              {value && (
                <Tooltip title={value && value.length > 50 ? value : ''}>
                  {value}
                </Tooltip>
              )}
            </>
          );
        },
        ...(highlightColumn && {
          cellClassName: 'highlight-cell'
        }),
        maxWidth: 400
      };
    case 'ID':
      return {
        headerName: columnMetadata?.displayName,
        field: columnMetadata?.fieldName,
        align: 'left',
        disableColumnMenu: true,
        disableReorder: true,
        editable: false,
        headerAlign: 'left',
        hideable: true,
        sortable: false,
        type,
        filterable: columnMetadata?.isFilterable || false,
        filterOperators: getSupportedFilterOperators(type),
        renderCell: ({ value }: GridRenderCellParams) => {
          return <Box sx={{ fontSize: '10px' }}>{value}</Box>;
        },
        ...(highlightColumn && {
          cellClassName: 'highlight-cell'
        }),
        maxWidth: 300
      };
    case INPUT_TYPES.JSON:
      return {
        headerName: columnMetadata?.displayName,
        field: columnMetadata?.fieldName,
        align: 'left',
        disableColumnMenu: true,
        disableReorder: true,
        editable: false,
        headerAlign: 'left',
        hideable: true,
        sortable: false,
        type,
        renderCell: ({ value }: GridRenderCellParams) => {
          return <JsonCell value={value} />;
        },
        ...(highlightColumn && {
          cellClassName: 'highlight-cell'
        }),
        maxWidth: 300
      };
    case INPUT_TYPES.ZIP:
    // falls through
    default:
      return {
        headerName: columnMetadata?.displayName,
        field: columnMetadata?.fieldName,
        align: 'left',
        disableColumnMenu: true,
        disableReorder: true,
        editable: false,
        headerAlign: 'left',
        hideable: true,
        sortable: false,
        type,
        filterable: columnMetadata?.isFilterable || false,
        filterOperators: getSupportedFilterOperators(type),
        ...(highlightColumn && {
          cellClassName: 'highlight-cell'
        }),
        maxWidth: 200
      };
  }
};

export const getTableColumns = (
  fieldMetadata: any = [],
  singleSelect: boolean,
  highlightedColumns?: string[]
) => {
  const sortedFieldMetadata = sortBy(fieldMetadata, metadata => {
    return metadata?.displaySortOrder;
  });

  const filteredSortedFieldMetadata = sortedFieldMetadata
    .filter((columnMetadata: any) => {
      return !(
        columnMetadata?.isHidden ||
        INTERNAL_ONLY_FIELDS?.[columnMetadata?.fieldName as string]
      );
    })
    .map((columnMetadata: any) => {
      return getColumnTypeByColumnMetadata(columnMetadata, highlightedColumns);
    });

  const checkboxColumn = {
    ...GRID_CHECKBOX_SELECTION_COL_DEF,
    hideable: false,
    cellClassName: 'MuiDataGrid-cellCheckbox',
    headerClassName: 'MuiDataGrid-columnHeaderCheckbox', // used to display: none the select all checkbox as there is not a way to turn the heading checkbox off
    // use radio buttons when only single select is allowed
    ...(singleSelect && {
      renderCell: ({ value, api, id }: GridRenderCellParams) => {
        return (
          <Radio
            checked={value}
            onChange={() => api.selectRow(id, true, true)}
            value={id}
            name="radioSelections"
            data-cy="table-row-checkbox"
          />
        );
      }
    })
  };

  const withCheckboxColumn = [checkboxColumn, ...filteredSortedFieldMetadata];

  return withCheckboxColumn || [];
};

export const getContentRows = (content: any) => {
  const contentEdge = content || [];
  return contentEdge.reduce((all: any, contentItem: any) => {
    const items = contentItem?.node?.items || [];
    if (items.length > 1) {
      return [...all, items.map((item: any) => item?.fields)];
    }
    return [...all, items?.[0]?.fields];
  }, []);
};
