import { useState } from 'react';
import { t } from 'i18next';
import { useQuery, useMutation } from '@apollo/client';

import { Grid, Paper, Typography, Alert, Divider, Box } from '@mui/material';

import { useAppSettings } from 'src/AppSettings';
import Logger from 'src/common/Logger';

import Heading from 'src/components/PageElements/Heading';

import { fetchEmailNotificationAsset, getEmailIdentityId } from './queries';
import {
  createEmailNotificationAsset,
  updateEmailNotificationAsset
} from './mutations';
import EmailNotificationForm from './EmailNotificationForm';

interface SubmitInputs {
  primaryColor: 'string';
  secondaryColor: 'string';
  mainLogo: 'string';
  productName: 'string';
  font: 'string';
  emailIdentityId?: 'string';
}

const NotificationPreferences = () => {
  const appSettings = useAppSettings();
  const organizationId = appSettings?.organizationId;
  const defaultPrimaryColor = appSettings?.app?.palette?.primary?.main;
  const defaultSecondaryColor = appSettings?.app?.palette?.secondary?.main;
  const defaultFont = appSettings?.fonts?.primary?.fontFamily;
  const appLogo = appSettings?.images?.logoUrl;
  // svg are not allowed so don't use appLogo as the default if it's an svg
  const defaultMainLogo = appLogo.split('.').pop() === 'svg' ? '' : appLogo;

  const [success, setSuccess] = useState(false);

  const { data, loading, error, refetch } = useQuery(
    fetchEmailNotificationAsset,
    {
      variables: {
        organizationId
      }
    }
  );

  const {
    data: emailIdentityData,
    loading: emailIdentityLoading,
    error: emailIdentityError
  } = useQuery(getEmailIdentityId, {
    variables: {
      organizationId
    }
  });

  const emailIdentityId = emailIdentityData?.fetchEmailIdentity?.id;

  const emailNotificationAssetData = data?.fetchEmailNotificationAsset;

  const initialValues = {
    emailIdentityId,
    primaryColor: emailNotificationAssetData
      ? emailNotificationAssetData?.primaryColor
      : defaultPrimaryColor,
    secondaryColor: emailNotificationAssetData
      ? emailNotificationAssetData?.secondaryColor
      : defaultSecondaryColor,
    mainLogo: emailNotificationAssetData
      ? emailNotificationAssetData?.mainLogo
      : defaultMainLogo,
    productName: emailNotificationAssetData?.productName,
    font: emailNotificationAssetData
      ? emailNotificationAssetData?.font
      : defaultFont
  };

  const [
    createEmailNotificationAssetMutation,
    { loading: loadingCreateMutation, error: createMutationError }
  ] = useMutation(createEmailNotificationAsset);

  const [
    updateEmailNotificationAssetMutation,
    { loading: loadingUpdateMutation, error: updateMutationError }
  ] = useMutation(updateEmailNotificationAsset);

  const onSubmit = async (input: SubmitInputs) => {
    if (emailNotificationAssetData?.id) {
      // update
      const updateInput = { ...input };
      delete updateInput.emailIdentityId;

      try {
        await updateEmailNotificationAssetMutation({
          variables: {
            input: {
              ...updateInput,
              id: emailNotificationAssetData.id
            }
          }
        });
        setSuccess(true);
        await refetch();
      } catch (error) {
        setSuccess(false);
        Logger.error('updateEmailNotificationAssetMutation error', error);
      }
      return;
    }

    try {
      await createEmailNotificationAssetMutation({
        variables: {
          input: {
            ...input,
            organizationId
          }
        }
      });
      setSuccess(true);
      await refetch();
    } catch (error) {
      setSuccess(false);
      Logger.error('createEmailNotificationAssetMutation error', error);
    }
  };

  const disabledAll =
    loading ||
    loadingCreateMutation ||
    loadingUpdateMutation ||
    emailIdentityLoading ||
    emailIdentityError;

  return (
    <>
      <Heading
        title={t('adminDashboard:notificationPreferences.name')}
        subTitle={t('adminDashboard:notificationPreferences.description')}
        pageTitle={t('adminDashboard:notificationPreferences.name')}
      />
      <Paper
        sx={{
          padding: theme => theme.spacing(2)
        }}
      >
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Box sx={{ margin: theme => `${theme.spacing()} 0px` }}>
              <Typography>
                {t('adminDashboard:notificationPreferences.name')}
              </Typography>
            </Box>

            <Divider sx={{ marginBottom: theme => theme.spacing() }} />

            {success && (
              <Alert severity="success">
                {t('adminDashboard:notificationPreferences.updateSuccess')}
              </Alert>
            )}
            {error && (
              <Alert severity="error">
                {t('adminDashboard:notificationPreferences.queryError')}
              </Alert>
            )}
            {(createMutationError || updateMutationError) && (
              <Alert severity="error">
                {t('adminDashboard:notificationPreferences.updateError')}
              </Alert>
            )}

            <EmailNotificationForm
              initialValues={initialValues}
              onSubmit={onSubmit}
              disabledAll={disabledAll}
              isSetup={!!emailIdentityId}
            />
          </Grid>
        </Grid>
      </Paper>
    </>
  );
};

export default NotificationPreferences;
