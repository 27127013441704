import { useState, useEffect, useCallback } from 'react';
import { head } from 'lodash';
import { useArchitectureAnywhere } from 'src/pages/Architecture/ArchitectureProvider';
import {
  Typography,
  Grid,
  Paper,
  Box,
  CircularProgress,
  Alert,
  Tooltip,
  IconButton
} from '@mui/material';
import { Edit as EditIcon } from '@mui/icons-material';
import { useQuery } from '@apollo/client';
import { styled } from '@mui/system';
import Loading from 'src/components/Loading';
import ErrorMessage from 'src/components/Containers/ErrorMessage';
import { t } from 'i18next';
import { useHistory, useParams } from 'react-router-dom';
import PageTitle from 'src/components/PageTitle';
import { BreadcrumbTrail } from 'src/components/BreadcrumbTrail/BreadcrumbTrail';
import { paths } from 'src/routes/paths';
import {
  formatBlueprintsForIcon,
  getOneOfEachChannelKeys
} from 'src/common/blueprints';
import BlueprintDisplayListItem from 'src/components/BlueprintSelector/BlueprintDisplayListItem';
import OrderStatus from 'src/components/Status/OrderStatus';
import PlaceIcon from '@mui/icons-material/Place';
import { MultiLocationProgram } from 'src/generated/gql/graphql';
import Instrumentation from 'src/instrumentation';
import { mlpParentOrderStatusCode } from 'src/components/Status/Constants';
import { generateLinkPath } from 'src/routes/RouteUtil';

import ProgramsTable from '../Programs/ProgramsTable';
import { getMLPOrder } from './queries';

const Section = styled(Box)(({ theme }) => ({
  marginBottom: theme.spacing(3)
}));

const DetailBox = styled(Box)(({ theme }) => ({
  padding: `0px ${theme.spacing(4)}`
}));

const pageText = ({ orderId }: { orderId: string }) => {
  return {
    apiError: t('programPerf:error.apiError'),
    notFound: t('programPerf:messages.notFound', {
      orderId
    }),
    noPermission: t('programPerf:messages.cannotViewNoPermission', {
      orderId
    }),
    pageTitle: t('programPerf:page.title'),
    programs: t('programPerf:header.programs'),
    mlpSubtitle: t('programPerf:MLP.subtitle'),
    mlpDefault: t('programPerf:MLP.defaultName'),

    locationsHeading: t('programPerf:MLP.locationsHeading'),
    numLocations: t('programPerf:MLP.numLocations'),
    totalBudget: t('programPerf:MLP.totalBudget'),
    loadingLocations: t('programPerf:MLP.loadingLocations')
  };
};

const MLPProgramPerformance = () => {
  const { orderId } = useParams<{
    orderId: string;
    architectureId: string;
  }>();
  const architecture = useArchitectureAnywhere();
  const [polling, setPolling] = useState(false);
  const [pollingError, setPollingError] = useState(false);
  // const globalContext = useGlobalContext();
  // const userId = globalContext?.me?.id;
  // const isTeamsEnabled = globalContext?.office?.isTeamsEnabled;
  const text = pageText({ orderId });

  const history = useHistory();

  const editMlpParent = () => {
    history.push(
      generateLinkPath(paths.architecture.multiLocationProgramEdit, {
        architectureId: architecture?.id,
        orderId
      })
    );
  };

  const { loading, data, error, refetch } = useQuery(getMLPOrder, {
    variables: {
      multiLocationProgramId: orderId
    }
  });

  const pollRef = useCallback(() => {
    refetch()
      .then(() => {
        setPolling(false);
      })
      .catch(() => {
        setPollingError(true);
        setPolling(false);
      });
  }, [refetch, setPolling]);

  useEffect(() => {
    if (
      !loading &&
      !polling &&
      data?.getMultiLocationProgram?.placementProgress &&
      data?.getMultiLocationProgram?.placementProgress?.soFar <
        data?.getMultiLocationProgram?.placementProgress?.outOf
    ) {
      setPolling(true);
      setTimeout(() => {
        pollRef();
      }, 2000);
    }
  }, [loading, data, pollRef, polling]);

  const program = data?.getMultiLocationProgram as MultiLocationProgram;

  useEffect(() => {
    if (program) {
      Instrumentation.logEvent(Instrumentation.Events.ViewMlpParent, {
        mlpParentId: orderId,
        architectureId: architecture?.id,
        productId: program?.product?.id,
        channel: getOneOfEachChannelKeys(program?.product?.blueprint?.channels)
      });
    }
  }, [program]);
  // const orderPermissions = program?.accessControl?.permissions || [];

  // const hasWritePermission = hasPermissions({
  //   permissions: [PERMISSIONS.write],
  //   contentPermissions: orderPermissions
  // });

  // const hasDeletePermission = hasPermissions({
  //   permissions: [PERMISSIONS.delete],
  //   contentPermissions: orderPermissions
  // });

  // const hasReadPermission = hasPermissions({
  //   permissions: [PERMISSIONS.read],
  //   contentPermissions: orderPermissions
  // });

  // Handle the case for when we don't find a program.
  if (!loading && !program) {
    return (
      <ErrorMessage
        sx={{
          margin: '0 auto'
        }}
      >
        {text.notFound}
      </ErrorMessage>
    );
  }

  if (error || pollingError) {
    let errorMessage = null;

    if (
      (error as any)?.graphQLErrors?.[0]?.extensions?.errorName ===
      'LithiumUnauthorizedException'
    ) {
      errorMessage = text.noPermission;
    } else {
      errorMessage = text.apiError;
    }

    return <Loading error={errorMessage} errorMessage={errorMessage} />;
  }

  if (loading) {
    return <Loading />;
  }

  // Handle the case for the user doesn't have permission to view the program.
  // if (isTeamsEnabled && !hasReadPermission) {
  //   return (
  //     <ErrorMessage
  //       sx={{
  //         margin: '0 auto'
  //       }}
  //     >
  //       {text.noPermission}
  //     </ErrorMessage>
  //   );
  // }

  const blueprintItem = head(formatBlueprintsForIcon([program?.product]));

  // loading stuff
  const { soFar, outOf } = program?.placementProgress;
  const stillCreatingOrders = soFar < outOf;
  const finishedValue = Math.round((soFar / outOf) * 100);

  const isActive = program?.status === mlpParentOrderStatusCode.active;

  return (
    <Box sx={{ height: '100vh', display: 'flex', flexDirection: 'column' }}>
      <Section>
        <Grid
          container
          direction="row"
          justifyContent="space-between"
          alignItems="flex-start"
          rowGap={1}
        >
          <Grid item xs={6}>
            <PageTitle subPageTitle={text.mlpSubtitle} />
            <BreadcrumbTrail
              sx={theme => ({ marginBottom: theme.spacing(1) })}
              pieces={[
                {
                  text: text.programs,
                  to: paths.programs.base
                },
                {
                  text: text.pageTitle
                }
              ]}
            />

            <Typography variant="h4" sx={{ fontWeight: 'bold' }}>
              {program?.name ? program?.name : text.mlpDefault}
            </Typography>
            <Typography variant="subtitle2" sx={{ fontWeight: 'normal' }}>
              <PlaceIcon
                fontSize="small"
                sx={{ position: 'relative', top: '3px' }}
              />{' '}
              {text.mlpDefault}
            </Typography>
          </Grid>
        </Grid>
      </Section>
      <Box sx={{ marginBottom: 3, display: 'flex' }}>
        <Tooltip
          title={
            isActive
              ? ''
              : t('programPerf:MLP.editButtonTooltip.innactiveStatus')
          }
        >
          <span>
            <IconButton
              color="primary"
              onClick={editMlpParent}
              data-cy="edit-program-button"
              sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                gap: 0.5
              }}
              disabled={!isActive}
            >
              <EditIcon fontSize="small" />
              <Typography component="span" variant="body2">
                {t('programPerf:editModal.edit')}
              </Typography>
            </IconButton>
          </span>
        </Tooltip>
      </Box>
      <Paper
        sx={{
          padding: 2,
          marginBottom: 3
        }}
      >
        <Grid
          container
          direction="row"
          justifyContent="flex-start"
          alignItems="stretch"
          spacing={0}
          sx={theme => ({
            '& > :not(:first-child)': {
              borderLeft: `1px solid ${theme.palette.grey[300]}`
            }
          })}
        >
          <Grid item>
            <DetailBox sx={{ paddingLeft: 0 }}>
              <strong>{t('programPerf:header.orderStatus')}</strong>
              <br />
              <OrderStatus
                mlpParentOrderStatusCode={program?.status}
                renderTextStatus
              />
              <br />
              {stillCreatingOrders && (
                <Alert
                  color="success"
                  icon
                  sx={{
                    maxWidth: `250px`,
                    padding: '0 10px 0 0',
                    marginTop: 1
                  }}
                >
                  {`${soFar}/${outOf} ${t('programPerf:MLP.locationsCreated')}`}
                </Alert>
              )}
            </DetailBox>
          </Grid>
          <Grid item>
            <DetailBox>
              <Box sx={{ maxWidth: '280px' }}>
                <strong>{t('programPerf:MLP.theme')}</strong>
                <br />
                <BlueprintDisplayListItem
                  blueprint={blueprintItem}
                  container="div"
                  disableGutters
                  hasChannels
                  key="programDetialsBlueprint"
                />
              </Box>
            </DetailBox>
          </Grid>
          <Grid item>
            <DetailBox data-cy="mlp-perf-number-of-locations">
              <strong>{text.numLocations}</strong>
              <br />
              {program?.placementProgress?.outOf}
            </DetailBox>
          </Grid>
          <Grid item>
            <DetailBox data-cy="mlp-perf-total-budget">
              <strong>{text.totalBudget}</strong>
              <br />
              {stillCreatingOrders || !program?.summedPriceAmount ? (
                <Alert
                  color="info"
                  icon
                  sx={{
                    maxWidth: `250px`,
                    padding: '0 10px 0 0',
                    marginTop: 1
                  }}
                >
                  {t('programPerf:MLP.pricePending')}
                </Alert>
              ) : (
                `$${program?.summedPriceAmount}`
              )}
            </DetailBox>
          </Grid>
        </Grid>
      </Paper>

      <Typography variant="subtitle1" sx={{ fontWeight: 'bold' }}>
        {text.locationsHeading}
      </Typography>
      <Box sx={{ flex: 1, display: 'flex', flexDirection: 'column' }}>
        {stillCreatingOrders ? (
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              alignContent: 'space-between',
              flexDirection: 'column'
            }}
          >
            <Box sx={{ position: 'relative', display: 'inline-block' }}>
              <CircularProgress variant="determinate" value={finishedValue} />
              <Box
                sx={{
                  top: '-3px',
                  left: 0,
                  bottom: 0,
                  right: 0,
                  position: 'absolute',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center'
                }}
              >
                <Typography
                  variant="caption"
                  component="div"
                  color="text.secondary"
                >{`${finishedValue}%`}</Typography>
              </Box>
            </Box>
            <div>
              <Typography variant="caption">{text.loadingLocations}</Typography>
            </div>
          </Box>
        ) : (
          <Box sx={{ flex: 1, overflow: 'auto', maxHeight: '600px' }}>
            <ProgramsTable
              multiLocationProgramId={program?.id}
              showDrafts={false}
            />
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default MLPProgramPerformance;
