import { Button } from '@mui/material';

import ClipboardButtonIcon from './ClipboardButtonIcon';
import ClipboardButtonText from './ClipboardButtonText';

const CopyToClipboardButton = props => {
  const { onClick, isCopied, variant = 'outlined' } = props;

  return (
    <Button
      color="secondary"
      variant={variant}
      data-cy="copy-to-clipboard-button"
      startIcon={<ClipboardButtonIcon isCopied={isCopied} />}
      onClick={onClick}
    >
      <ClipboardButtonText isCopied={isCopied} />
    </Button>
  );
};

export default CopyToClipboardButton;
