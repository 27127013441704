import {
  Chip,
  Divider,
  Fade,
  Paper,
  Popper,
  Tooltip,
  Typography
} from '@mui/material';
import { noop, sortBy } from 'lodash';
import { DynamicValue } from 'src/components/ReduxForm/commonTypes';
import classNames from 'classnames';
import AddIcon from '@mui/icons-material/Add';
import { t } from 'i18next';
import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/system';
import { ThemeAugmentations } from 'src/AppThemeWrapper';
import { preventDefault } from 'src/common/functionUtil';

const useStyles = makeStyles((theme: Theme & ThemeAugmentations) => ({
  chipContainer: {
    marginBottom: theme.spacing(2),
    maxWidth: '350px' // TODO: We should probably figure out how to make this more dynamic
  },
  chipTitle: {
    padding: theme.spacing(1),
    textAlign: 'center',
    fontWeight: 'bold'
  },
  chipScroller: {
    maxHeight: '200px',
    overflowY: 'scroll',
    margin: `0 ${theme.spacing(2)}`
  },
  chipScroll: {
    textAlign: 'center',
    fontStyle: 'italic',
    padding: theme.spacing(1)
  },
  chipPopper: {
    zIndex: theme.zIndex.tooltip + 1
  },
  chip: {
    margin: theme.spacing(1)
  },
  chipGone: {
    color: '#bbb',
    borderColor: '#ddd',
    backgroundColor: '#f9f9f9'
  },
  missingChip: {
    colorPrimary: {
      color: 'red'
    }
  }
}));

const pageText = () => ({
  chipHeader: t('renderTemplateStringTextField:chipSelector.header'),
  missingChipTooltip: t('renderTemplateStringTextField:missingTooltipChip'),
  scrollForMore: t('renderTemplateStringTextField:chipSelector.scrollMore')
});

export interface DynamicValueSelectorProps {
  missingColumns: Set<string>;
  dynamicValues: DynamicValue[] | undefined;
  anchorElement: HTMLElement | null;
  handleAddTag: (tagName: string) => void;
  active: boolean | undefined;
}

export const DynamicValueSelector = ({
  dynamicValues,
  anchorElement,
  active,
  missingColumns,
  handleAddTag
}: DynamicValueSelectorProps) => {
  const classes = useStyles();
  const text = pageText();

  // Only render the column picker if we have dynamic values.
  if (!Array.isArray(dynamicValues) || !dynamicValues.length) {
    return <span />;
  }

  return (
    <Popper
      className={classes.chipPopper}
      anchorEl={anchorElement}
      id="temp-string-popper"
      open={Boolean(active)}
      placement="top"
      transition
    >
      {({ TransitionProps }) => (
        <Fade {...TransitionProps} timeout={350}>
          <Paper
            className={classes.chipContainer}
            data-cy="dynamic-field-chips"
          >
            <Typography className={classes.chipTitle} variant="body2">
              {text.chipHeader}
            </Typography>
            <Divider />
            <div className={classes.chipScroller}>
              {sortBy(dynamicValues, o => o.displaySortOrder).map(item => {
                const isMissing = missingColumns.has(item.fieldName);

                let clickHandler = () => handleAddTag(item.value);
                if (isMissing) {
                  clickHandler = noop;
                }
                const chip = (
                  <Chip
                    key={`rtstf-${item.value}`}
                    className={classNames(classes.chip, {
                      [classes.chipGone]: isMissing
                    })}
                    icon={isMissing ? undefined : <AddIcon />}
                    label={item.label}
                    // Prevent default so we don't lose focus
                    onMouseDown={preventDefault}
                    clickable={!isMissing}
                    onClick={clickHandler}
                    variant={isMissing ? 'outlined' : undefined}
                  />
                );
                if (!isMissing) {
                  return chip;
                }
                return (
                  <Tooltip
                    title={text.missingChipTooltip}
                    key={`rtstf-${item.value}`}
                  >
                    {chip}
                  </Tooltip>
                );
              })}
            </div>
            <Typography className={classes.chipScroll} variant="body2">
              {text.scrollForMore}
            </Typography>
          </Paper>
        </Fade>
      )}
    </Popper>
  );
};
