import { createContext, useContext, cloneElement } from 'react';
import { flow } from 'lodash';
import { graphql } from '@apollo/client/react/hoc';
import { withAppSettings } from 'src/AppSettings';
import { allowListPaths } from 'src/routes/paths';
import { withRouter } from 'react-router-dom';

import { EV_ORGANIZATION_ID } from 'src/Auth/common';

import { getUser } from './queries';

export const GlobalContext = createContext({});

export const withGlobalContext = component => {
  const Component = component;

  return props => {
    const settings = useContext(GlobalContext);
    return <Component {...props} globalContext={settings} />;
  };
};

export const useGlobalContext = () => useContext(GlobalContext);

export const getIsTeamsEnabled = role => {
  return !!role?.toLowerCase()?.includes('team');
};

const GlobalContextProvider = ({
  children,
  location: { pathname },
  getUser = { me: {}, us: {}, myOrganization: {} }
}) => {
  const { loading, error, refetch, me, us, myOrganization } = getUser;

  const offices = (me?.availableGroups || [])?.map(group => ({
    ...group,
    isTemasEnabled: getIsTeamsEnabled(us?.role)
  }));
  const officeId = us?.id || null;
  const selectedOffice = offices?.find(obj => obj.id === officeId) || {};
  const officeName = selectedOffice?.name || 'office';
  const isTeamsEnabled = getIsTeamsEnabled(us?.role);

  const appPermissions = {
    isEvocalizeOrg: myOrganization?.id === EV_ORGANIZATION_ID
  };

  const globalContext = {
    me,
    us,
    myOrganization,
    appPermissions,
    architectures: us?.architectures,
    office: {
      id: officeId,
      offices,
      officeName,
      selectedOffice,
      isTeamsEnabled
    },
    // we pass loading handling to chrome
    // so loading state has the chrome on display
    isAllowListPath: allowListPaths.includes(pathname),
    loading,
    refetch,
    error
  };

  return (
    <GlobalContext.Provider value={globalContext}>
      {cloneElement(children, {
        globalContext
      })}
    </GlobalContext.Provider>
  );
};

export default flow(
  graphql(getUser, {
    name: 'getUser',
    options: props => ({
      variables: {
        ...(props?.appSettings?.app?.features?.showInactiveBlueprints && {
          showInactive: true
        })
      }
    }),
    skip: ({ location: { pathname } }) => {
      if (allowListPaths.filter(url => pathname === url).length) {
        // Note: skip the me query if at an allowed url so we can show unauthed pages
        //       like the activation page
        return true;
      }
    }
  }),
  withRouter,
  withAppSettings
)(GlobalContextProvider);
