import { every, find, forEach, get, isArray, isEmpty, keys } from 'lodash';
import { ParameterType, ProductInputSection } from 'src/generated/gql/graphql';
import { INPUT_TYPES } from 'src/components/ReduxForm/DynamicForm/constants';
import {
  Input,
  isConditionalVisible,
  BusinessObjects,
  UserMetaDataFields,
  FormValues
} from 'src/common/conditionals';

export type InputField = Input & {
  isMultiInput: boolean;
  fieldName: string;
  disabled: boolean;
  defaultValue: string;
  friendlyName: string;
  isExpressionAllowed: boolean;
  isRequired: boolean;
  id: string;
};

type InputSection = ProductInputSection & { inputFields: InputField[] };
interface GetAiAdCopyWriterInputsArgs {
  dynamicUserInputs: Record<string, any>;
  selectedBlueprint: { inputSections: InputSection[] };
  businessObjects: BusinessObjects[];
  userMetadataFields: UserMetaDataFields;
  formValues: FormValues;
}

const eligibleInputConditions = {
  isMultiInput: false,
  isHidden: false,
  'blueprintVariable.isArray': false,
  'blueprintVariable.type': ParameterType.String,
  'displayParameters.inputData.disableAiGenerationChatSupport': [
    undefined,
    false,
    null
  ]
} as const;

type EligibleInputConditionsKey = keyof typeof eligibleInputConditions;

export const getAiAdCopyWriterInputs = ({
  dynamicUserInputs,
  selectedBlueprint,
  businessObjects,
  userMetadataFields,
  formValues
}: GetAiAdCopyWriterInputsArgs) => {
  const adCopyWriterInputs: InputField[] = [];

  forEach(dynamicUserInputs, (_, key) => {
    const fieldName = key;

    forEach(selectedBlueprint?.inputSections, inputSection => {
      const eligibleField = find(inputSection?.inputFields, inputField => {
        const isMultiOrSingleLineString =
          inputField?.displayMethodId === INPUT_TYPES.SINGLE_LINE_STRING ||
          inputField?.displayMethodId === INPUT_TYPES.MULTI_LINE_STRING;

        const isEligibleConfig = every(
          keys(eligibleInputConditions) as EligibleInputConditionsKey[],
          key => {
            const eligibilityCondition = eligibleInputConditions[key];
            const inputConfigValue = get(inputField, key);

            if (isArray(eligibilityCondition)) {
              return eligibilityCondition.includes(inputConfigValue);
            }

            return inputConfigValue === eligibilityCondition;
          }
        );

        const hasConditional = !isEmpty(
          inputField?.displayParameters?.inputData?.conditionalRender
        );

        if (hasConditional) {
          const conditionalRender =
            inputField?.displayParameters?.inputData?.conditionalRender;

          if (
            !isConditionalVisible({
              inputMetadata: inputField,
              formValues,
              conditionalRender,
              userMetadataFields,
              businessObjects
            })
          ) {
            return false;
          }
        }

        return (
          isMultiOrSingleLineString &&
          isEligibleConfig &&
          fieldName === inputField.fieldName
        );
      });

      if (eligibleField) {
        adCopyWriterInputs.push(eligibleField);
      }
    });
  });

  return adCopyWriterInputs;
};
