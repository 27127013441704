import { t } from 'i18next';

import {
  GridColDef,
  GridRenderCellParams,
  GridAlignment
} from '@mui/x-data-grid-pro';

import { getSpendAmount } from 'src/common/billingDetails';
import DateCell from 'src/components/Table/DateCell';
import StatusGroupingCell from 'src/components/Table/StatusGroupingCell';
import { programOrderStatusCode } from 'src/components/Status/Constants';

import {
  getProgramNameOperators,
  getProgramOrderIdOperators,
  getThemeOperators,
  getTypeOperators
} from '../filterOperators';
import {
  getBlueprintOptionsFromArchitectures,
  getTypeByIdFromArchitectures
} from '../helpers';
import { DATE_FORMAT, PROGRAM_TABLE_FIELDS } from './constants';
import ProgramNameColumn from './ProgramNameColumn';

export const groupingColumn = (
  setGroupExpandedByDefault: (expanded: boolean) => Promise<void>
) => {
  return {
    align: 'center' as GridAlignment,
    disableColumnMenu: true,
    disableReorder: true,
    editable: false,
    field: PROGRAM_TABLE_FIELDS.status,
    filterable: false,
    headerAlign: 'center' as GridAlignment,
    headerName: t('programs:tableHeader.status'),
    hideable: false,
    sortable: false,
    width: 150,
    minWidth: 150,
    type: 'singleSelect',
    renderCell: (params: any) => {
      return (
        <StatusGroupingCell
          params={params}
          setGroupExpandedByDefault={setGroupExpandedByDefault}
        />
      );
    }
  };
};

export const getProgramsColumns = (
  architectures: {
    products: any[];
  }[] = [],
  architectureNameById: any,
  isDraftEnabled = false
) => {
  const initialColumns: GridColDef[] = [
    {
      align: 'left',
      disableColumnMenu: true,
      disableReorder: true,
      editable: true,
      field: PROGRAM_TABLE_FIELDS.name,
      filterable: true,
      flex: 2,
      headerAlign: 'left',
      headerName: t('programs:tableHeader.name'),
      hideable: true,
      sortable: false,
      type: 'string',
      filterOperators: getProgramNameOperators(),
      renderCell: ProgramNameColumn
    },
    {
      align: 'left',
      disableColumnMenu: true,
      disableReorder: true,
      editable: true,
      field: PROGRAM_TABLE_FIELDS.theme,
      filterable: true,
      flex: 1,
      headerAlign: 'left',
      headerName: t('programs:tableHeader.theme'),
      hideable: true,
      sortable: false,
      type: 'singleSelect',
      filterOperators: getThemeOperators(architectureNameById),
      valueOptions: architectures.map((architecture: any) => {
        return {
          label: architecture.name,
          value: architecture.id
        };
      }),
      renderCell: ({ row }: GridRenderCellParams) => {
        return (
          architectureNameById?.[row?.architectureId] ||
          architectureNameById?.[row?.architecture?.id] || // draft
          row?.architectureId ||
          row?.architecture?.id // draft
        );
      }
    },
    {
      align: 'left',
      disableColumnMenu: true,
      disableReorder: true,
      editable: true,
      field: PROGRAM_TABLE_FIELDS.type,
      filterable: true,
      flex: 1,
      headerAlign: 'left',
      headerName: t('programs:tableHeader.type'),
      hideable: true,
      sortable: false,
      type: 'singleSelect',
      filterOperators: getTypeOperators(
        getTypeByIdFromArchitectures(architectures)
      ),
      valueOptions: getBlueprintOptionsFromArchitectures(architectures),
      renderCell: ({ row }: GridRenderCellParams) => {
        return row?.products?.[0]?.name || row?.product?.name; // regular || draft automations
      }
    },
    {
      align: 'center',
      disableColumnMenu: true,
      disableReorder: true,
      editable: true,
      field: PROGRAM_TABLE_FIELDS.billingDetails,
      filterable: false,
      headerAlign: 'center',
      headerName: t('programs:tableHeader.budget'),
      sortable: false,
      type: 'string',
      width: 100,
      renderCell: params => {
        if (params.rowNode.type === 'group') {
          return null;
        }
        return getSpendAmount(params.value);
      }
    },
    {
      align: 'center',
      disableColumnMenu: true,
      disableReorder: true,
      editable: true,
      field: PROGRAM_TABLE_FIELDS.startDate,
      filterable: false,
      headerAlign: 'center',
      headerName: t('programs:tableHeader.startDate'),
      sortable: false,
      type: 'string',
      width: 125,
      renderCell: (params: GridRenderCellParams) => {
        if (params.rowNode.type === 'group') {
          return null;
        }
        if (!params.row.billingDetails.startDate) {
          return '-';
        }

        return (
          <DateCell
            data={params.row.billingDetails.startDate}
            dateFormat={DATE_FORMAT}
            size="inherit"
          />
        );
      }
    },
    {
      align: 'center',
      disableColumnMenu: true,
      disableReorder: true,
      editable: true,
      field: PROGRAM_TABLE_FIELDS.endDate,
      filterable: false,
      headerAlign: 'center',
      headerName: t('programs:tableHeader.endDate'),
      sortable: false,
      type: 'string',
      width: 125,
      renderCell: (params: GridRenderCellParams) => {
        if (params.rowNode.type === 'group') {
          return null;
        }

        if (!params.row.billingDetails.endDate) {
          return '-';
        }

        return (
          <DateCell
            data={params.row.billingDetails.endDate}
            dateFormat={DATE_FORMAT}
            size="inherit"
          />
        );
      }
    },
    {
      align: 'center',
      disableColumnMenu: true,
      disableReorder: true,
      editable: true,
      field: PROGRAM_TABLE_FIELDS.id,
      filterable: true,
      headerAlign: 'center',
      headerName: t('programs:tableHeader.orderId'),
      sortable: false,
      type: 'string',
      width: 200,
      filterOperators: getProgramOrderIdOperators(),
      renderCell: (params: GridRenderCellParams) => {
        if (params.rowNode.type === 'group') {
          return null;
        }

        if (params.row.orderItem?.status === programOrderStatusCode.draft) {
          return '-';
        }

        return params.value;
      }
    }
  ];

  // we don't get the grouping column when drafts are not enabled so we need to add it back in
  if (!isDraftEnabled) {
    initialColumns.unshift(groupingColumn(() => new Promise(() => {})));
  }

  return initialColumns;
};
