import Modal, { ModalHeader } from 'src/components/Modal';

import ConfirmationModalFooter from './ConfirmationModalFooter';

interface ConfirmationModalProps {
  onClose: (event?: any, reason?: any) => void;
  open: boolean;
  title: string;
  children: JSX.Element;
  onConfirm?: any;
  icon?: JSX.Element;
  cancelButtonText?: string;
  confirmButtonText?: string;
  confirmButtonDisabled?: boolean;
  confirmButtonLoading?: boolean;
  maxWidth?: string | number | false;
}

const ConfirmationModal = ({
  onClose,
  open,
  title,
  children,
  onConfirm,
  icon,
  cancelButtonText,
  confirmButtonText,
  confirmButtonDisabled,
  confirmButtonLoading,
  maxWidth = 'md'
}: ConfirmationModalProps) => {
  return (
    <Modal
      maxWidth={maxWidth}
      open={open}
      onClose={onClose}
      HeaderComponent={<ModalHeader icon={icon}>{title}</ModalHeader>}
      FooterComponent={
        <ConfirmationModalFooter
          onCancel={onClose}
          onConfirm={onConfirm}
          cancelButtonText={cancelButtonText}
          confirmButtonText={confirmButtonText}
          confirmButtonDisabled={confirmButtonDisabled}
          confirmButtonLoading={confirmButtonLoading}
        />
      }
    >
      {children}
    </Modal>
  );
};

export default ConfirmationModal;
