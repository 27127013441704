import { useState, useEffect, useRef } from 'react';
import { useLazyQuery } from '@apollo/client';
import { isEmpty } from 'lodash';

import { useSnackbar } from 'notistack';
import { t } from 'i18next';

import { Box } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { styled } from '@mui/system';

import Logger from 'src/common/Logger';
import SentryUtil from 'src/common/SentryUtil';

import TotalExpertIcon from 'src/components/Icons/TotalExpert';
import ConfirmationModal from 'src/components/Modal/ConfirmationModal';
import RenderAutocomplete from 'src/components/ReduxForm/RenderAutocomplete/RenderAutocomplete';

import { fetchEmbeddedLink, fetchContactGroups } from './queries';

interface WorkatoConnectionProps {
  tooltip?: string;
  readOnly?: boolean;
  helperText?: string;
  disabled: boolean;
  label: string;
  extraProps: {
    isMultiSelect: boolean;
  };
  meta: {
    error?: string;
  };
}

const WorkatoIframe = styled('iframe')(() => ({
  border: 'none'
}));

const RenderWorkatoConnection = (autocompleteProps: WorkatoConnectionProps) => {
  const { enqueueSnackbar } = useSnackbar();

  const [getEmbeddedLink] = useLazyQuery(fetchEmbeddedLink);
  const [getContactGroups] = useLazyQuery(fetchContactGroups);

  const [workatoIframeUrl, setWorkatoIframeUrl] = useState<
    string | null | undefined
  >(null);
  const [iframeHeight, setIframeHeight] = useState(300);
  const [workatoError, setWorkatoError] = useState<string | null | undefined>(
    null
  );
  const [options, setOptions] = useState<{ name: any; value: any }[]>([]);

  const [connectedToWorkato, setConnected] = useState(false);
  const previousConnectedRef = useRef(false);
  const [loading, setLoading] = useState(false);

  const [modalOpen, setModalOpen] = useState(false);
  const setCloseModal = () => {
    setModalOpen(false);
  };

  const getSelectOptions = async (onMount = false) => {
    setLoading(true);

    try {
      const contactGroups = await getContactGroups({
        fetchPolicy: 'no-cache'
      });

      const formattedOptions = contactGroups?.data?.fetchContactGroups?.map(
        (option: any) => {
          return {
            name: option?.groupName,
            value: option?.groupName
          };
        }
      );

      setOptions(formattedOptions || []);

      if (!isEmpty(formattedOptions)) {
        enqueueSnackbar(t('common:workato.snacks.optionsReady'), {
          variant: 'success'
        });
      } else if (!onMount) {
        // we don't want to show no data on mount b/c the user may not be connected yet
        enqueueSnackbar(t('common:workato.snacks.noOptions'), {
          variant: 'success'
        });
      }

      setLoading(false);
    } catch (error: any) {
      // Not setting errors on mount call b/c there is a good chance the user is not connected yet
      if (!onMount) {
        SentryUtil.addBreadcrumb({
          message:
            'Failed to get select options from Workato. Error fetching data.'
        });
        SentryUtil.captureException(error);
        enqueueSnackbar(t('common:workato.error.getOptions'), {
          variant: 'error'
        });
        Logger.error(error);
        setWorkatoError(t('common:workato.error.getOptions'));
      }

      setLoading(false);
    }
  };

  // onMount
  useEffect(() => {
    // try getting select options for case where user is already connected
    getSelectOptions(true).catch(() => {
      // doing this catch for TS to not complain about the promise not being handled
    });
  }, []);

  const setConnection = (isConnected: boolean) => {
    // only update if the connection status has changed
    if (isConnected !== connectedToWorkato) {
      // close modal on successful connection but allow it to stay open if we are already
      // connected and the user clicks it again to be able to disconnect
      if (!previousConnectedRef.current) {
        setModalOpen(false);
      }

      if (isConnected) {
        enqueueSnackbar(t('common:workato.snacks.connectSuccess'), {
          variant: 'success'
        });
        // get select options once we have made a connection
        getSelectOptions().catch(() => {
          // doing this catch for TS to not complain about the promise not being handled
        });
      }

      setConnected(isConnected);
    }
  };

  const connectWorkato = async () => {
    try {
      // get iframe link
      const embeddedLink = await getEmbeddedLink({
        fetchPolicy: 'no-cache'
      });

      // set iframe link
      setWorkatoIframeUrl(embeddedLink?.data?.fetchEmbeddedLink?.link);
      setModalOpen(true);
    } catch (error: any) {
      SentryUtil.addBreadcrumb({
        message:
          'Failed to get embedded link for Workato iframe. Error fetching link.'
      });
      SentryUtil.captureException(error);
      enqueueSnackbar(t('common:workato.error.link'), {
        variant: 'error'
      });
      Logger.error(error);

      setWorkatoError(t('common:workato.error.link'));
    }
  };

  useEffect(() => {
    const handleMessage = (event: any) => {
      const data =
        typeof event.data === 'string' ? JSON.parse(event.data) : event.data;

      if (!event.origin.includes('workato')) {
        // only accept messages from workato
        return;
      }

      const type = data?.type;

      if (type === 'connectionStatusChange') {
        const connected = data?.payload?.connected;
        setConnection(connected);
        previousConnectedRef.current = connected;
      }
      if (type === 'heightChange') {
        const height = data?.payload?.height;

        setIframeHeight(height);
      }

      if (type === 'error') {
        // the error does not report the correct hight for the iframe so setting it here
        setIframeHeight(400);

        setWorkatoError(data?.payload?.message);
      }
    };

    // TODO: if we are going to have more than one of these on a page we need to make sure we are only listening to the correct iframe

    window.addEventListener('message', handleMessage);

    return () => {
      window.removeEventListener('message', handleMessage);
    };
  }, []);

  const hasConnection = connectedToWorkato || !isEmpty(options);

  return (
    <>
      <RenderAutocomplete
        {...autocompleteProps}
        {...(workatoError && {
          meta: {
            ...autocompleteProps.meta,
            error: workatoError,
            touched: true
          }
        })}
        // change label if we have no options and are not connected
        {...(!hasConnection && {
          label: t('common:workato.input.loadDataLabel')
        })}
        readOnly={isEmpty(options)} // this is disabled for this input
        options={options}
      />

      <ConfirmationModal
        cancelButtonText={t('common:workato.modal.cancel')}
        title={t('common:workato.modal.title')}
        open={modalOpen}
        onClose={setCloseModal}
      >
        <WorkatoIframe
          src={workatoIframeUrl || undefined}
          width={350}
          height={iframeHeight}
          id="workatoId"
        />
      </ConfirmationModal>

      <LoadingButton
        loading={loading}
        color="primary"
        variant="contained"
        size="small"
        // eslint-disable-next-line @typescript-eslint/no-misused-promises
        onClick={connectWorkato}
        sx={{
          opacity: hasConnection ? 0.5 : 1,
          width: '100%'
        }}
      >
        <Box component="span" sx={{ marginRight: theme => theme.spacing() }}>
          {hasConnection
            ? t('common:workato.manage')
            : t('common:workato.connect')}
        </Box>
        {!loading && <TotalExpertIcon />}
      </LoadingButton>
    </>
  );
};

export default RenderWorkatoConnection;
