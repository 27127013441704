import { t } from 'i18next';
import { flow } from 'lodash';
import { reduxForm, SubmitHandler } from 'redux-form';

import { Button, Tooltip, Alert, AlertTitle } from '@mui/material';

import { DynamicForm } from 'src/components/ReduxForm';
import { INPUT_TYPES } from 'src/components/ReduxForm/DynamicForm/constants';

interface FormProps {
  handleSubmit: SubmitHandler;
  onSubmit: () => void;
  disabledAll: boolean;
  dirty: boolean;
  isSetup: boolean;
}

const inputs = [
  {
    name: 'emailIdentityId',
    displayName: 'Email Identity Id',
    displayMethodId: INPUT_TYPES.SINGLE_LINE_STRING,
    initialValue: '',
    reduxValidations: [],
    isRequired: true,
    isHidden: true,
    displayParameters: {
      inputData: {
        columnWidth: 2
      }
    }
  },
  {
    name: 'productName',
    displayName: 'Product Name',
    displayMethodId: INPUT_TYPES.SINGLE_LINE_STRING,
    initialValue: '',
    reduxValidations: [],
    isRequired: true,
    isHidden: false,
    displayParameters: {
      inputData: {
        columnWidth: 2
      }
    }
  },
  {
    name: 'primaryColor',
    displayName: 'Primary Color',
    displayMethodId: INPUT_TYPES.COLOR_INPUT,
    initialValue: '',
    reduxValidations: [],
    isRequired: true,
    isHidden: false,
    displayParameters: {
      inputData: {
        columnWidth: 1
      }
    }
  },
  {
    name: 'secondaryColor',
    displayName: 'Secondary Color',
    displayMethodId: INPUT_TYPES.COLOR_INPUT,
    initialValue: '',
    reduxValidations: [],
    isRequired: true,
    isHidden: false,
    displayParameters: {
      inputData: {
        columnWidth: 1
      }
    }
  },
  {
    name: 'mainLogo',
    displayName: 'Main Logo',
    displayMethodId: INPUT_TYPES.GALLERY_IMAGE_URL,
    initialValue: '',
    reduxValidations: [],
    isRequired: true,
    isHidden: false,
    displayParameters: {
      inputData: {
        internalOnly: true,
        internalOnlyAllowSvg: false,
        columnWidth: 2
      }
    }
  },
  {
    name: 'font',
    displayName: 'Font',
    displayMethodId: INPUT_TYPES.SINGLE_LINE_STRING,
    initialValue: '',
    reduxValidations: [],
    isRequired: true,
    isHidden: false,
    displayParameters: {
      inputData: {
        columnWidth: 2
      }
    }
  }
];

const EmailNotificationForm = ({
  handleSubmit,
  onSubmit,
  disabledAll,
  dirty,
  isSetup
}: FormProps) => {
  return (
    <form autoComplete="off" onSubmit={handleSubmit(onSubmit)}>
      <Alert severity="warning">
        <AlertTitle>
          {t('adminDashboard:notificationPreferences.disabledTitle')}
        </AlertTitle>
        {t('adminDashboard:notificationPreferences.disabledInfo')}
      </Alert>

      <DynamicForm disabled={!isSetup} inputs={inputs} />
      <Tooltip
        arrow
        title={
          isSetup
            ? ''
            : t('adminDashboard:notificationPreferences.disabledInfo')
        }
      >
        <span>
          <Button
            color="primary"
            variant="contained"
            type="submit"
            disabled={!dirty || disabledAll || !isSetup}
          >
            {t('adminDashboard:notificationPreferences.submitButton')}
          </Button>
        </span>
      </Tooltip>
    </form>
  );
};

export default flow(
  reduxForm({
    enableReinitialize: true,
    form: 'emailNotificationForm!!'
  })
)(EmailNotificationForm);
