import { Fab } from '@mui/material';
import { ExpandLess } from '@mui/icons-material';
import { useIsDrawerFullScreen } from './useIsDrawerFullScreen';

const BACKGROUND_COLOR = 'rgba(0, 0, 0, 0.75)';
const BACKGROUND_COLOR_HOVER = 'rgba(0, 0, 0, 0.9)';
const ICON_ROTATION_CLOSED = 0;
const ICON_ROTATION_OPEN = 180;
const BUTTON_TRANSLATE_Y_MOBILE_OPEN = 0;
const BUTTON_TRANSLATE_Y = '-100%';
const BUTTON_BORDER_RADIUS = 4;

const DrawerToggleButton = ({ onClick, isOpen, text }) => {
  // Rotate chevron to face which direction the menu will move when the toggle is pressed
  const iconRotation = isOpen ? ICON_ROTATION_OPEN : ICON_ROTATION_CLOSED;

  /*
    When the drawer opens up to 100% width at small screen sizes,
    need to move it inside the drawer so that it is not out of view to the left
  */

  const isDrawerFullScreen = useIsDrawerFullScreen();
  const isDrawerOpenAndFullScreen = isDrawerFullScreen && isOpen;
  const translateY =
    isDrawerFullScreen && isOpen
      ? BUTTON_TRANSLATE_Y_MOBILE_OPEN
      : BUTTON_TRANSLATE_Y;

  return (
    <Fab
      variant="extended"
      size="medium"
      onClick={onClick}
      sx={{
        position: isOpen ? 'fixed' : 'absolute',
        borderTopLeftRadius: isDrawerOpenAndFullScreen
          ? 0
          : BUTTON_BORDER_RADIUS,
        borderTopRightRadius: isDrawerOpenAndFullScreen
          ? 0
          : BUTTON_BORDER_RADIUS,
        borderBottomRightRadius: isDrawerOpenAndFullScreen
          ? BUTTON_BORDER_RADIUS
          : 0,
        borderBottomLeftRadius: isDrawerOpenAndFullScreen
          ? BUTTON_BORDER_RADIUS
          : 0,
        left: 0,
        top: '50%',
        transform: `rotate(-90deg) translate(-50%, ${translateY})`,
        boxShadow: 'none',
        transformOrigin: '0 0', // Rotation transformation will be blurry if the origin isn't 0, 0,
        backgroundColor: BACKGROUND_COLOR,
        borderColor: BACKGROUND_COLOR,
        fontWeight: 400,
        color: theme => theme.palette.getContrastText(BACKGROUND_COLOR),
        padding: theme => `0 ${theme.spacing(4.5)}`,
        zIndex: theme => theme.zIndex.drawer,
        '&:hover': {
          backgroundColor: BACKGROUND_COLOR_HOVER,
          borderColor: BACKGROUND_COLOR_HOVER
        }
      }}
    >
      {text}
      <ExpandLess
        sx={{
          marginRight: '-6px', // The icon has its own container with padding, the negative margin offsets that so that the button content is centered
          marginLeft: theme => theme.spacing(0.5),
          transform: `rotate(${iconRotation}deg)`
        }}
      />
    </Fab>
  );
};

export default DrawerToggleButton;
