import { createContext, useContext } from 'react';
import { cloneDeep, merge } from 'lodash';
import { lighten, rgba } from 'polished';

import { useThemeOffice } from 'src/api/get-theme';
import SentryUtil from 'src/common/SentryUtil';
import { DEFAULT_THEME } from 'src/components/theme/defaultTheme';
import generateColors from 'src/components/theme/generateColors';
import { OFFICE_THEME_OVERRIDE_LOCAL_STORAGE } from 'src/components/theme/Constants';
import { getFeaturesFromSettings } from 'src/components/Feature/constants';
import { s3BaseUrl } from './components/AdPreview/S3Image';
import AppThemeLoading from './AppThemeLoading';
import { formatDate } from './common/dates';

const mergeSettings = (DEFAULT_THEME, settings) => {
  const rawSkinSettings = settings?.rawSkinSettings;
  const baseUrl = import.meta.env.EVOCALIZE_LITHIUM_API_URL;

  let callbackUrl = settings?.auth0Details?.auth0SpaCallbackUrl;
  const auth0Details = settings?.auth0Details;

  const evHostnameOverride = localStorage.getItem('evHostnameOverride');

  // If evHostnameOverride is set, we hard redirect them back locally.
  if (
    evHostnameOverride ||
    // hardcode our callback url for PR based deployments
    window.location.host === 'office-staging-pr.evocalize.com'
  ) {
    callbackUrl = `https://${window.location.host}/#/authenticate`;
  }

  const primaryColor = generateColors(
    settings?.skinSettings?.palette?.primary?.main
  );
  const secondaryColor = generateColors(
    settings?.skinSettings?.palette?.secondary?.main
  );

  return {
    rawSkinSettings,
    // raw settings with localhost overrides
    rawSkinSettingsWithOverrides: settings?.skinSettings,
    organizationDomainId: 'hard-coded-office-id',
    organizationId: settings?.organizationId,
    organizationFqdn: settings?.organizationFqdn,
    authenticationUrl: settings?.authenticationUrl,
    logoutUrl: settings?.logoutUrl,
    themeColors: DEFAULT_THEME,
    colors: {
      // Status
      error: DEFAULT_THEME.error[500],
      errorBackground: rgba(DEFAULT_THEME.error[700], 0.4),
      errorText: DEFAULT_THEME.white,
      success: DEFAULT_THEME.success[500],
      successBackground: rgba(DEFAULT_THEME.success[700], 0.4),
      successText: DEFAULT_THEME.white,
      warning: DEFAULT_THEME.warn[500],
      warningBackground: rgba(DEFAULT_THEME.warn[700], 0.4),
      warningText: DEFAULT_THEME.white,
      // Progress
      progressBar: primaryColor[500],
      // Text
      deleteLink: DEFAULT_THEME.error[500],
      highlighted: secondaryColor[500],
      lightHeader: DEFAULT_THEME.seattleSkies[700],
      // Input
      inputBorder: DEFAULT_THEME.seattleSkies[100],
      inputBorderActive: secondaryColor[500],
      inputBorderError: DEFAULT_THEME.error[500],
      inputBorderValid: DEFAULT_THEME.success[500],
      inputLabel: DEFAULT_THEME.seattleSkies[700],
      inputLabelLight: DEFAULT_THEME.seattleSkies[300],
      inputLabelActive: secondaryColor[500],
      inputLabelError: DEFAULT_THEME.error[500],
      inputLabelValid: DEFAULT_THEME.success[500],
      radioBackground: DEFAULT_THEME.seattleSkies[100],
      radioBackgroundHover: DEFAULT_THEME.seattleSkies[200],
      radioBackgroundSelected: secondaryColor[500],
      toggleBall: DEFAULT_THEME.white,
      toggleFocus: secondaryColor[500],
      toggleSwitchActive: DEFAULT_THEME.success[500],
      toggleSwitchActiveDisabled: rgba(DEFAULT_THEME.success[300], 0.5),
      toggleSwitchBorder: DEFAULT_THEME.seattleSkies[100],
      toggleSwitchDisabled: rgba(DEFAULT_THEME.seattleSkies[100], 0.5),
      toggleSwitchInactive: DEFAULT_THEME.white,
      // Slider
      sliderBackground: DEFAULT_THEME.seattleSkies[200],
      sliderActiveBackground: primaryColor[500],
      sliderBorder: DEFAULT_THEME.seattleSkies[200],
      sliderPriceLabel: DEFAULT_THEME.seattleSkies[300],
      // Button
      button: secondaryColor[500],
      buttonActive: secondaryColor[700],
      buttonDisabled: lighten(0.075, DEFAULT_THEME.seattleSkies[100]),
      buttonDisabledText: DEFAULT_THEME.seattleSkies[100],
      buttonSecondary: DEFAULT_THEME.seattleSkies[700],
      buttonSecondaryActive: DEFAULT_THEME.seattleSkies[100],
      buttonSecondaryDisabled: DEFAULT_THEME.seattleSkies[700],
      buttonSecondaryDisabledText: DEFAULT_THEME.white,
      buttonSecondaryText: DEFAULT_THEME.white,
      buttonText: DEFAULT_THEME.white,
      buttonDangerBackground: DEFAULT_THEME.error[500],
      buttonDangerBackgroundActive: DEFAULT_THEME.error[700],
      buttonDangerText: DEFAULT_THEME.white,
      removeListDeleteButtonText: DEFAULT_THEME.white,
      stopUsingButtonText: lighten(0.075, DEFAULT_THEME.seattleSkies[100]),
      // Loading
      loadingOverlayBg: DEFAULT_THEME.black,
      loadingOverlayText: lighten(0.075, DEFAULT_THEME.seattleSkies[100]),
      // Navigation
      navBackground: DEFAULT_THEME.white,
      // Cart / Checkout
      lastUsedText: primaryColor[500],
      recommendedStar: secondaryColor[500],
      recommendedText: primaryColor[500],
      subscriptionCartBackground: primaryColor[500],
      // Table
      tableDivider: DEFAULT_THEME.seattleSkies[100],
      // Modal
      modalBackdrop: rgba(primaryColor[700], 0.85),
      modalBackground: DEFAULT_THEME.white,
      modalBorder: DEFAULT_THEME.seattleSkies[100],
      modalCloseButtonText: DEFAULT_THEME.white,
      // Dashboard
      dashboardHeadingText: DEFAULT_THEME.white,
      dashboardMessageBackground: rgba(DEFAULT_THEME.black, 0.4),
      // Gallery
      dragDropActive: primaryColor[500],
      dragDropBackground: secondaryColor[500],
      galleryBorder: DEFAULT_THEME.seattleSkies[300],
      galleryLink: secondaryColor[500],
      imgTileBackdrop: rgba(primaryColor[500], 0.75),
      // Ad Preview
      adPreviewBackground: DEFAULT_THEME.white,
      adPreviewBorder: DEFAULT_THEME.seattleSkies[100],
      adPreviewDescriptionText: DEFAULT_THEME.seattleSkies[400],
      adPreviewEmptyAvatar: secondaryColor[500],
      adPreviewHeaderText: DEFAULT_THEME.seattleSkies[400],
      adPreviewText: DEFAULT_THEME.black,
      // Facebook
      facebookLink: DEFAULT_THEME.facebook.link,
      // Auth0
      auth0ButtonBackground: primaryColor[500],
      auth0ButtonText: DEFAULT_THEME.white,
      // Icons
      cartIcon: primaryColor[500],
      dashboardProfileIcon: primaryColor[500],
      profileUploadIcon: secondaryColor[500],
      uploadIcon: secondaryColor[500],
      // Global App
      bodyBackground: DEFAULT_THEME.white,
      noImageAvatarBackground: secondaryColor[500],
      spinnerFill: primaryColor[500],
      // Settings
      settingsHeaderBoarder: DEFAULT_THEME.seattleSkies[100],
      settingsNavigationSelected: lighten(
        0.075,
        DEFAULT_THEME.seattleSkies[100]
      ),
      settingsSectionBackground: rgba(DEFAULT_THEME.seattleSkies[100], 0.25),
      // Metrics
      metricsText: primaryColor[500],
      // Leads
      leadsListArrow: DEFAULT_THEME.seattleSkies[300],
      leadsListBoarder: DEFAULT_THEME.seattleSkies[100],
      leadsListDetailsLabel: DEFAULT_THEME.seattleSkies[400],
      leadsListShowMoreBackground: rgba(DEFAULT_THEME.seattleSkies[100], 0.25),
      leadsListShowMoreBorder: DEFAULT_THEME.seattleSkies[100],
      leadsQualityIcon: DEFAULT_THEME.seattleSkies[300],
      leadsQualityIconSelected: primaryColor[500],
      leadsStatusText: DEFAULT_THEME.seattleSkies[300],
      // Expandable Container
      expandableContainerArrow: DEFAULT_THEME.seattleSkies[300],
      expandableContainerBoarder: DEFAULT_THEME.seattleSkies[100],
      expandableContainerShowMoreBackground: rgba(
        DEFAULT_THEME.seattleSkies[100],
        0.25
      ),
      // Lists
      listBorder: DEFAULT_THEME.seattleSkies[100],
      listItemHover: rgba(secondaryColor[300], 0.1),
      listItemActive: rgba(secondaryColor[300], 0.1),
      activeIcon: primaryColor[500],
      inactiveIcon: DEFAULT_THEME.seattleSkies[100],
      // Agent Switcher
      operatingAsBackground: rgba(DEFAULT_THEME.success[300], 0.9),
      operatingAsText: DEFAULT_THEME.black,
      help: DEFAULT_THEME.seattleSkies[700],
      helpHeader: primaryColor[500],
      lightNote: DEFAULT_THEME.seattleSkies[300],

      ...DEFAULT_THEME?.overrides?.colors
    },
    sizes: {
      adPreviewBorderRadius: '0.25rem',
      errorContainerBorderRadius: '0',
      inputBorderRadius: '0.25rem',
      logoHeight: '45px',
      modalBorderRadius: '0.25rem',
      textInputHeight: '40px',
      uploadBorderRadius: '0.25rem',
      ...settings?.skinSettings?.sizes
    },
    transitions: {
      short: '200ms linear 0s',
      ...(settings?.skinSettings?.transitions || {})
    },
    images: {
      dashboardHeroImageUrl:
        'https://s3.amazonaws.com/ev-helium/assets/images/dashboard_hero.png',
      logo: 'logos/evocalize-agents-logo.svg',
      logoUrl:
        settings?.skinSettings?.evAssets?.logoUrl ||
        'https://s3.amazonaws.com/ev-helium/assets/images/logos/evocalize-agents-logo.svg'
    },
    fonts: {
      primary: {
        fallbacks: 'Roboto, Helvetica, Arial, sans-serif',
        fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
        url: null,
        ...(settings?.skinSettings?.fonts?.primary || {})
      },
      secondary: {
        fallbacks: 'Roboto, Helvetica, Arial, sans-serif',
        fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
        url: null,
        ...(settings?.skinSettings?.fonts?.secondary || {})
        // ...(settings?.skinSettings?.fontsSecondary || {})
      }
    },
    app: {
      // we are combining evApp and app to be the same thing!
      // evApp
      ...(settings?.skinSettings?.evApp || {}),
      deactivation: {
        deactivationDate: settings?.deactivationDate, // '2021-07-26T09:28:06.349Z'
        ...(settings?.skinSettings?.evApp?.deactivation || {}) // so we can set a skin override used for testing right now
      },
      organizationName: settings?.organizationName,
      palette: {
        primary: settings?.skinSettings?.palette?.primary,
        secondary: settings?.skinSettings?.palette?.secondary,
        ...(settings?.skinSettings?.evApp.palette || {})
      },
      auth: {
        ...(settings?.skinSettings?.evApp.auth || {}),
        authProviderType: settings?.authProviderType,
        organizationFqdn: settings?.organizationFqdn,
        authenticationUrl: settings?.authenticationUrl,
        logoutUrl: settings?.logoutUrl
      },
      auth0: {
        audience: 'https://agents.evocalize.com/api',
        clientId: 'WaNdeiygkfFaFNax4DWqaOB7byNtsMW2',
        domain: 'auth.evocalize.com',
        logoutUrl: 'https://agents.evocalize.com/',
        // these 2 are required:
        responseType: 'token id_token',
        scope: 'openid profile email offline_access',
        ...(settings?.skinSettings?.evApp.auth0 || {}),
        ...{
          audience: auth0Details?.auth0SpaAudience, // https://evoc-direct.com/graphql
          callbackUrl, // 'https://ld2a.evocalize.com:3000/#/authenticate',
          clientId: auth0Details?.auth0SpaClientId, // CGiv1nJ9TrcwW0HZi3EQocpgCJzDiJXl
          domain: auth0Details?.auth0SpaDomain, // helium-staging-login.evocalize.com
          // First check for the newer auth section for the
          // logoutUrl - fallback to the auth0 one for
          // backwards compatibility with older clients.
          logoutUrl: settings?.logoutUrl || auth0Details?.auth0SpaLogoutUrl, // https://ld2a.evocalize.com:3000/#/logout
          ssoConnection: auth0Details?.auth0ConnectionType,
          auth0ConfigurationBaseUrl: auth0Details?.auth0ConfigurationBaseUrl
        }
      },
      oktaDirectSSO: {
        ...(settings?.oktaDirectSSODetails || {})
      },
      facebook: {
        oauthBaseUrl: 'https://www.facebook.com/v10.0/dialog/oauth',
        scopes:
          'pages_show_list,pages_manage_metadata,leads_retrieval,pages_read_engagement,business_management',
        appId: settings?.facebookClientId,
        ...(settings?.skinSettings?.evApp?.facebook || {})
      },
      trackers: {
        facebookPixelId: '1543419672632868',
        googleAnalyticsId: 'UA-36250654-6',
        ...(settings?.skinSettings?.evApp?.trackers || {})
      },
      general: {
        organizationId: settings?.organizationId,
        appName: 'CMP',
        baseTitle: 'Marketing Center',
        connectDevTools: false,
        defaultGalleryId: 15,
        // favIconUrl: 'https://ev-helium.s3.amazonaws.com/assets/images/favicon.png',
        isDemo: true,
        logLevel: 'DEBUG',
        mobileAppAndroidUrl:
          'https://play.google.com/store/apps/details?id=com.evocalize.helium',
        mobileAppIosUrl:
          'https://itunes.apple.com/us/app/evocalize/id1336456228?mt=8',
        s3BaseUrl: 'https://s3.amazonaws.com/ev-helium/assets/images/',
        privacyPolicyUrl: 'https://www.evocalize.com/privacy-policy/',
        // overrides
        ...(settings?.skinSettings?.evApp?.general || {}),
        ...{
          stripeKey: settings?.stripePublicKey,
          baseUrl
        }
      },
      features: {
        ...(settings?.skinSettings?.evApp?.features || {}),
        ...getFeaturesFromSettings(
          settings?.skinSettings?.evApp?.features || {}
        )
      },
      featureSettings: {
        ...(settings?.skinSettings?.evApp?.featureSettings || {}),
        automationAdoptionConfig: {
          callToActionText: 'Try Now',
          dismissText: 'Dismiss Forever',
          remindText: 'Remind Me Later',
          remindInterval: 12, // hours
          startDate: formatDate({ format: 'MM-DD-YYYY' }),
          ...(settings?.skinSettings?.evApp?.featureSettings
            ?.automationAdoptionConfig || {})
        }
      },
      demoOverrides: {
        ...(settings?.skinSettings?.evApp?.demoOverrides || {})
      },
      strings: {
        ...(settings?.skinSettings?.evApp?.strings || {})
      },
      support: {
        ...(settings?.skinSettings?.evApp?.support || {})
      },
      plans: {
        // overrides
        ...(settings?.skinSettings?.evApp?.plans || {})
      }
    },
    evAssets: {
      logoUrl: null,
      favIconUrl: null,
      tosUrl: null, // url needs to have http:// || https://
      tosName: 'Terms of Service',
      privacyUrl: null, // url needs to have http:// || https://
      privacyName: 'Privacy Policy',
      // start quickstarts assets
      // These are here to supplement the BE not having time to add icons to the quickstarts.
      // If we work with these at all we should move them over to be a property of quickstarts
      contentTypeIconsDefault: {
        foreground: `${s3BaseUrl}quickstartIcons/type-listing-foreground.svg`,
        background: `${s3BaseUrl}quickstartIcons/type-listing-background.svg`
      },
      contentTypeIconsListing: {
        foreground: `${s3BaseUrl}quickstartIcons/type-listing-foreground.svg`,
        background: `${s3BaseUrl}quickstartIcons/type-listing-background.svg`
      },
      contentTypeIconsAgent: {
        foreground: `${s3BaseUrl}quickstartIcons/type-agent-foreground.svg`,
        background: `${s3BaseUrl}quickstartIcons/type-agent-background.svg`
      },
      contentTypeIconsFranchise: {
        foreground: `${s3BaseUrl}quickstartIcons/type-franchise-foreground.svg`,
        background: `${s3BaseUrl}quickstartIcons/type-franchise-background.svg`
      },
      objectiveIconsTraffic: `${s3BaseUrl}quickstartIcons/objective-traffic.svg`,
      objectiveIconsLeadGeneration: `${s3BaseUrl}quickstartIcons/objective-lead-generation.svg`,
      objectiveIconsConversion: `${s3BaseUrl}quickstartIcons/objective-conversion.svg`,
      objectiveIconsAwareness: `${s3BaseUrl}quickstartIcons/objective-awareness.svg`,
      quickstartIconOverrides: {
        // 'id1': {
        //     icon: 'link',
        //     foreground: 'link',
        //     background: 'link'
        // }
      },
      ...(settings?.skinSettings?.evAssets || {}),
      ...(settings?.skinSettings?.evApp?.evAssets || {})
    },
    evSizes: {
      navigationWidth: 250,
      previewDrawerWidth: 402,
      toolBarHeight: 64,
      mobileToolBarHeight: 56,
      ...(settings?.skinSettings?.evSize || {})
    }
  };
};
/* 
  Relying on lodash's merge function to merge fonts was causing a bug when the
  override skin settings font was set to use theme default (no font field) and the
  current skin settings had values. It would merge and override the overrides with the values from the
  current skin settings.

  This function ensures that the fonts from the override skin settings in local storage
  actually override the skin settings for previews.
*/

const mergeFonts = (appSettings, evOfficeTheme) => {
  const merged = cloneDeep(appSettings);

  const overrideFontSettings = evOfficeTheme?.skinSettings?.fonts;
  const currentFontSettings = appSettings.skinSettings.fonts;

  if (!overrideFontSettings && currentFontSettings) {
    delete merged.skinSettings.fonts;
  } else if (overrideFontSettings) {
    merged.skinSettings.fonts = overrideFontSettings;
  }

  return merged;
};

// Merge with overrides if overrides exist in local storage
const mergeOverrides = (appSettings, evOfficeTheme) => {
  let merged = appSettings;

  if (evOfficeTheme) {
    merged = mergeFonts(appSettings, evOfficeTheme);

    merged.skinSettings = merge(
      {},
      merged.skinSettings,
      evOfficeTheme?.skinSettings
    );
  }
  return merged;
};

export const AppSettingsContext = createContext({});

export const withAppSettings = component => {
  const Component = component;

  return props => {
    const appSettings = useContext(AppSettingsContext);
    return <Component {...props} appSettings={appSettings} />;
  };
};

export const useAppSettings = () => {
  const appSettings = useContext(AppSettingsContext);
  return appSettings;
};

const AppSettings = ({ children }) => {
  const { loading, error, data } = useThemeOffice();

  if (error) {
    SentryUtil.addBreadcrumb({ message: 'Error getting theme.' });
    SentryUtil.captureException({
      error
    });
  }

  if (loading || error) {
    return <AppThemeLoading loading={loading} error={error} />;
  }

  const appSettings = { ...data?.public?.siteLoadV2 };
  // set the raw skin setting that are returned from the api so we can access in the skin settings tool
  const rawSkinSettings = cloneDeep(data?.public?.siteLoadV2?.skinSettings);

  appSettings.rawSkinSettings = rawSkinSettings;

  // Check for local storage theme
  const evOfficeTheme = JSON.parse(
    localStorage.getItem(OFFICE_THEME_OVERRIDE_LOCAL_STORAGE)
  );

  const mergedWithOverrides = mergeOverrides(appSettings, evOfficeTheme);

  return (
    <AppSettingsContext.Provider
      value={{
        ...mergeSettings(DEFAULT_THEME, mergedWithOverrides),
        loading,
        error
      }}
    >
      {children}
    </AppSettingsContext.Provider>
  );
};

export default AppSettings;
