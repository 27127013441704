import { Divider, Tooltip } from '@mui/material';

import withStyles from '@mui/styles/withStyles';

import { formatDate } from 'src/common/dates';

const styles = theme => ({
  wrapper: {
    position: 'relative'
  },
  extraTip: {
    position: 'absolute',
    top: '52px',
    right: '4px'
  },

  container: {
    alignItems: 'center',
    border: '3px solid',
    borderRadius: theme.spacing(),
    color: theme.palette.grey[600],
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    minWidth: '90px'
  },
  month: {
    background: theme.palette.grey[600],
    color: 'white',
    fontWeight: 'bold',
    padding: theme.spacing() / 2,
    textAlign: 'center',
    width: '100%'
  },
  day: {
    fontSize: '26px',
    fontWeight: 'bold',
    padding: theme.spacing()
  },
  year: {
    borderTop: `2px solid ${theme.palette.grey[600]}`,
    textAlign: 'center',
    width: '100%'
  }
});

const CalendarDay = props => {
  const {
    date,
    classes,
    monthFormat = 'MMMM',
    showTooltip = true,
    helpIcon = null,
    helpTip = null
  } = props;

  const tooltip = formatDate({ date, type: 'DAY' });

  return (
    <div>
      {helpTip && (
        <Tooltip
          className={classes.extraTip}
          title={helpTip}
          aria-label={helpTip}
        >
          {helpIcon}
        </Tooltip>
      )}
      <Tooltip title={showTooltip ? tooltip : ''} aria-label={tooltip}>
        <div className={classes.container}>
          <div className={classes.month}>
            {formatDate({ format: monthFormat, date })}
          </div>

          <div className={classes.day}>
            {formatDate({ format: 'DD', date })}
          </div>
          <Divider />
          <div className={classes.year}>
            {formatDate({ format: 'YYYY', date })}
          </div>
        </div>
      </Tooltip>
    </div>
  );
};

export default withStyles(styles)(CalendarDay);
