import { AiChatMessageType, aiChatMessageTypes } from '../constants';
import { InputField } from '../helpers';
import AdCopySuggestionMessage from './AdCopySuggestionMessage';
import ButtonInputMessage, { ButtonConfig } from './ButtonInputMessage';
import TextMessage from './TextMessage';

interface AiChatMessageProps {
  type: AiChatMessageType;
  text?: string | JSX.Element;
  buttons?: ButtonConfig[];
  input?: InputField;
  sharedInputProps: Record<string, any>;
  setIsThinking: (isThinking: boolean) => void;
}

const AiChatMessage = ({
  type,
  text,
  buttons,
  input,
  sharedInputProps,
  setIsThinking
}: AiChatMessageProps) => {
  if (aiChatMessageTypes.text === type && text) {
    return <TextMessage text={text} />;
  }

  if (aiChatMessageTypes.buttonInput === type && buttons?.length) {
    return <ButtonInputMessage buttons={buttons} />;
  }

  if (
    aiChatMessageTypes.adCopySuggestion === type &&
    input &&
    sharedInputProps &&
    setIsThinking
  ) {
    return (
      <AdCopySuggestionMessage
        input={input}
        sharedInputProps={sharedInputProps}
        setIsThinking={setIsThinking}
      />
    );
  }

  return null;
};

export default AiChatMessage;
