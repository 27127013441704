import { head } from 'lodash';
import { Trans } from 'react-i18next';
import { t } from 'i18next';

import { Typography, Grid, styled, Box } from '@mui/material';
import { useAppSettings } from 'src/AppSettings';
import DescriptionIcon from '@mui/icons-material/DescriptionOutlined';
import WarningIcon from '@mui/icons-material/Warning';

import { formatBlueprintsForIcon } from 'src/common/blueprints';
import { BILLING_METHODS } from 'src/common/paymentUtils';
import { getDeactivationDateFromTheme } from 'src/common/deactivation';
import { getAppName } from 'src/common/appBranding';
import { formatDate, dayjs } from 'src/common/dates';
import { formatPrice } from 'src/common/numbers';

import AdStatus from 'src/components/Status/AdStatus';
import OrderStatus from 'src/components/Status/OrderStatus';
import BillingStatus from 'src/components/Status/BillingStatus';
import CalendarDay from 'src/components/Calendar';
import BlueprintDisplayListItem from 'src/components/BlueprintSelector/BlueprintDisplayListItem';

import UpdateCreditCard from './UpdateCreditCard';

const TdTitle = styled('td')({ fontWeight: 'bold' });
const DateContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  marginRight: theme.spacing(2),
  position: 'relative',

  '&:last-child': {
    marginRight: 0
  }
}));

const formatRecurrence = key => {
  const map = {
    month: t('programPerf:details.perMonth')
  };

  return map?.[key] || key;
};

const pageText = ({
  scheduledTierAmount,
  scheduledBudgetDate,
  deactivationDate,
  deactivationDiffInDays,
  appName
}) => ({
  scheduledBudgetChange: t('programPerf:details.scheduledBudgetChange', {
    scheduledTierAmount,
    scheduledBudgetDate
  }),
  invoiceBilling: t('programPerf:billing.invoice'),
  deactivationTip: t('programPerf:deactivation.endDateDeactivationMessage', {
    deactivationDate,
    deactivationDiffInDays,
    appName
  })
});

const ProgramDetails = props => {
  const { program, promoCode, refetch } = props;
  const appSettings = useAppSettings();
  const hasDeactivationDate = getDeactivationDateFromTheme(appSettings);
  const appName = getAppName(appSettings);

  // Note: formatBlueprintsForIcon expects an array of blueprints
  const blueprintItem = head(
    formatBlueprintsForIcon([program?.orderItem?.product])
  );

  const cancellationDate =
    program?.billingDetails?.cancellationDetails?.cancellationDate;
  const endDate = program?.billingDetails?.endDate;
  const renewsOn = program?.billingDetails?.renewsOn;
  const scheduledBudgetDate = program?.billingDetails?.scheduledBudgetDate;
  const amount = program?.billingDetails?.amount;
  const scheduledTierAmount = program?.billingDetails?.scheduledTierAmount;
  const hasScheduledBudgetChange = scheduledTierAmount && scheduledBudgetDate;
  const currentPeriodEnd = program?.billingDetails?.currentPeriodEnd;

  const endDateToDiff = endDate || renewsOn || null;
  const deactivationDiffInDays =
    endDateToDiff && dayjs(endDateToDiff).diff(hasDeactivationDate, 'days');

  const deactivationDate = formatDate({
    format: 'ddd, MMM DD YYYY',
    date: hasDeactivationDate
  });

  const formattedScheduledBudgetDate = formatDate({
    date: scheduledBudgetDate,
    format: 'MM/DD/YYYY'
  });

  const text = pageText({
    scheduledTierAmount: formatPrice(scheduledTierAmount),
    scheduledBudgetDate: formattedScheduledBudgetDate,
    deactivationDate,
    deactivationDiffInDays,
    appName
  });

  // only show tooltip if end date is past the deactivation date
  const deactivationTip =
    hasDeactivationDate && deactivationDiffInDays > 0
      ? text.deactivationTip
      : '';

  const deactivationIcon = <WarningIcon sx={{ color: 'warning.600' }} />;
  const mostRecentBudgetAdjustmentAmount =
    program?.billingDetails?.mostRecentBudgetAdjustment?.updatedBudgetAmount;

  const getCurrentBudgetAmount = () => {
    // If there is a scheduled budget change, the correct amoutn returns from the billingDetails
    if (hasScheduledBudgetChange) {
      return amount;
    }

    // This return statement covers immediate subscription and one time purchase budget changes
    return mostRecentBudgetAdjustmentAmount || amount;
  };

  const currentBudgetAmount = getCurrentBudgetAmount();

  return (
    <>
      <Grid
        container
        direction="row"
        justifyContent="space-between"
        alignItems="flex-start"
        spacing={2}
      >
        <Grid item>
          {program?.orderItem?.status && (
            <Box component="table" sx={{ maxWidth: '280px' }}>
              <tbody>
                <tr>
                  <TdTitle>
                    <Trans i18nKey="programPerf:header.orderId" />:
                  </TdTitle>
                  <td>{program?.id}</td>
                </tr>
                <tr>
                  <TdTitle>
                    <Trans i18nKey="programPerf:header.orderStatus" />:
                  </TdTitle>
                  <td>
                    <OrderStatus order={program} renderTextStatus />
                  </td>
                </tr>
                <tr>
                  <TdTitle>
                    <Trans i18nKey="programPerf:header.adStatus" />:
                  </TdTitle>
                  <td>
                    <AdStatus order={program} renderTextStatus />
                  </td>
                </tr>
                <tr>
                  <TdTitle>
                    <Trans i18nKey="programPerf:header.billingStatus" />:
                  </TdTitle>
                  <td>
                    <BillingStatus order={program} renderTextStatus />
                  </td>
                </tr>
                {!!promoCode && (
                  <tr>
                    <TdTitle>
                      <Trans i18nKey="programPerf:meta.appliedPromo" />
                    </TdTitle>
                    <td>
                      <span>{promoCode}</span>
                    </td>
                  </tr>
                )}
              </tbody>
            </Box>
          )}
        </Grid>
        <Grid item>
          <Box sx={{ maxWidth: '280px' }}>
            <strong>
              <Trans i18nKey="programPerf:meta.blueprint" />
            </strong>
            <BlueprintDisplayListItem
              blueprint={blueprintItem}
              container="div"
              disableGutters
              hasChannels
              key="programDetialsBlueprint"
            />
          </Box>
        </Grid>

        <Grid item>
          <Box
            sx={{
              alignItems: 'center',
              display: 'flex',
              flexDirection: 'row',
              flexWrap: 'wrap'
            }}
          >
            <DateContainer>
              <strong>
                <Trans i18nKey="programPerf:meta.start" />
              </strong>
              <CalendarDay date={program?.billingDetails?.startDate} />
            </DateContainer>

            {renewsOn && !cancellationDate && (
              <DateContainer>
                <strong>
                  <Trans i18nKey="programPerf:meta.renews" />
                </strong>
                <CalendarDay
                  date={renewsOn}
                  helpIcon={deactivationIcon}
                  helpTip={deactivationTip}
                />
              </DateContainer>
            )}

            {endDate && !cancellationDate && (
              <DateContainer>
                <strong>
                  <Trans i18nKey="programPerf:meta.end" />
                </strong>
                <CalendarDay
                  date={endDate}
                  helpIcon={deactivationIcon}
                  helpTip={deactivationTip}
                />
              </DateContainer>
            )}

            {cancellationDate && (
              <DateContainer>
                <strong>
                  <Trans i18nKey="programPerf:meta.cancelled" />
                </strong>
                <CalendarDay
                  date={cancellationDate}
                  helpIcon={deactivationIcon}
                  helpTip={deactivationTip}
                />
              </DateContainer>
            )}
          </Box>
        </Grid>

        <Grid item>
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: 3 }}>
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
              {program?.billingDetails?.priceBeforeDiscount && (
                <Typography
                  sx={{
                    color: 'grey.600',
                    lineHeight: 1,
                    '& span': {
                      fontSize: '18px',
                      textDecoration: 'line-through'
                    }
                  }}
                >
                  <span>
                    {formatPrice(program?.billingDetails?.priceBeforeDiscount)}
                  </span>{' '}
                  Promo applied
                </Typography>
              )}
              <Typography
                sx={{
                  fontSize: '34px',
                  lineHeight: 1,
                  color: 'grey.600',
                  minWidth: '150px'
                }}
              >
                {formatPrice(currentBudgetAmount)}
                {program?.billingDetails?.interval && (
                  <span>
                    {formatRecurrence(program?.billingDetails?.interval)}
                  </span>
                )}
              </Typography>
              {hasScheduledBudgetChange && (
                <Typography sx={{ color: 'grey.600' }} variant="body1">
                  {text.scheduledBudgetChange}
                </Typography>
              )}
            </Box>

            {program?.billingDetails?.billingMethod ===
              BILLING_METHODS.card && (
              <UpdateCreditCard program={program} refetch={refetch} />
            )}
            {program?.billingDetails?.billingMethod ===
              BILLING_METHODS.partnerInvoice && (
              <Typography variant="body1">
                <DescriptionIcon style={{ verticalAlign: 'bottom' }} />{' '}
                {text.invoiceBilling}
              </Typography>
            )}
          </Box>
        </Grid>
      </Grid>
      {program?.billingDetails?.isSetToCancel &&
        program?.billingDetails?.offerType === 'subscription' &&
        program?.offer?.cancellationType === 'deferred' &&
        currentPeriodEnd &&
        dayjs().isBefore(currentPeriodEnd) && (
          <Box
            sx={{
              marginTop: 4,
              background: '#ED6C02',
              color: 'white',
              padding: 1,
              borderRadius: 2,
              display: 'inline-block'
            }}
          >
            {t('programPerf:cancelled.disclaimer', {
              cancellationDate: dayjs(currentPeriodEnd).format('MM/DD/YYYY')
            })}
          </Box>
        )}
    </>
  );
};

export default ProgramDetails;
