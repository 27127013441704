import { gql } from 'src/generated/gql';

export const fetchEmbeddedLink = gql(`
  query fetchEmbeddedLink {
    fetchEmbeddedLink {
      link
      errorMessage
    }
  }
`);

export const fetchContactGroups = gql(`
  query fetchContactGroups {
    fetchContactGroups {
      groupName
      groupId
    }
  }
`);
