import { Box } from '@mui/material';

interface ModalHeaderProps {
  icon?: JSX.Element;
  children: string | JSX.Element;
}

const ModalHeader = ({ icon, children }: ModalHeaderProps) => {
  return (
    <Box
      sx={{
        margin: 0,
        padding: 0,
        display: 'flex',
        justifyContent: 'space-between',
        gap: 1,
        fontSize: '26px',
        fontWeight: 'bold',
        alignItems: 'center'
      }}
    >
      {icon}

      {children}
    </Box>
  );
};

export default ModalHeader;
