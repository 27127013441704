import { useState, useEffect } from 'react';
import classNames from 'classnames';
import { flow, noop, isEqual, pick } from 'lodash';
import {
  getFormValues,
  getFormSyncErrors,
  reduxForm,
  SubmissionError,
  stopSubmit
} from 'redux-form';
import { connect } from 'react-redux';
import { useLocation } from 'react-router-dom';
import querystring from 'query-string';
import { t } from 'i18next';

import { useGlobalContext } from 'src/GlobalContextProvider';
import { useArchitecture } from 'src/pages/Architecture/ArchitectureProvider';

import {
  useMediaQuery,
  Typography,
  IconButton,
  Alert,
  AlertTitle,
  CircularProgress,
  Box
} from '@mui/material';

import { useTheme, makeStyles } from '@mui/styles';

import EditIcon from '@mui/icons-material/EditOutlined';

import { formErrorMapper } from 'src/components/ReduxForm/helpers';
import { AiChat, getAiAdCopyWriterInputs } from 'src/components/AiChat';

import {
  getOneOfEachChannelKeys,
  contentSelectable,
  hasCatalog
} from 'src/common/blueprints';
import useLockBodyScroll from 'src/hooks/useLockBodyScroll';
import { isProgramClone } from 'src/common/cloneProgram';
import useGenAiAdCopyWritingV3 from 'src/experiments/useGenAiAdCopyWritingV3';

import { getConditionalInputVisibilityFromBlueprint } from 'src/common/conditionals';
import { useSwitchTheme } from 'src/hooks/useSwitchTheme';
import { contentColumnsFromArchitecture } from 'src/common/dynamicUserInputs';

import { useSnackbar } from 'notistack';
import NavigationBlocker from 'src/components/NavigationBlocker';
import PageTitle from 'src/components/PageTitle';
import Instrumentation from 'src/instrumentation';
import {
  paymentErrorByBackendDisplayCode,
  genericCardDeclinedError
} from 'src/common/paymentUtils';

import { paths } from 'src/routes/paths';
import { BreadcrumbTrail } from 'src/components/BreadcrumbTrail/BreadcrumbTrail';

import StepSkipper from './ProgramSteps/StepSkipper';
import ProgramPreviewDrawerLegacy from './ProgramPreviewDrawer/ProgramPreviewDrawerLegacy';
import {
  PROGRAM_FORM_NAME,
  PROGRAM_STEP_NAME_TO_ORDER,
  programActions,
  programErrorTypes,
  programErrorMessageToMatch,
  PROGRAM_STEP_NAMES,
  programTrackingTypes,
  PROGRAM_FORM_SECTION_DYNAMIC_INPUTS_NAME
} from './Constants';

import { resetProgram } from './actions';
import { useIsDrawerFullScreen } from './ProgramPreviewDrawer/useIsDrawerFullScreen';
import { DRAWER_FULL_SCREEN_BREAKPOINT } from './ProgramPreviewDrawer/constants';
import useHandleStepNavigation from './utils/useHandleStepNavigation';
import useCreativeValidationsHandler from './useCreativeValidationsHandler';
import useProgram from './utils/useProgram';
import ProgramStepper from './ProgramSteps/ProgramStepper';

const useStyles = makeStyles(theme => {
  const getColumnLayout = spacing => ({
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(spacing)
  });

  const baseContentStyles = {
    flexGrow: 1,
    transition: theme.transitions.create('padding', {
      duration: 0
    })
  };

  const { previewDrawerWidth } = theme.evSizes;

  return {
    container: {
      display: 'flex',
      flexDirection: 'column',
      width: '100%'
    },

    content: {
      paddingLeft: theme.spacing(5),
      paddingTop: theme.spacing(3),
      paddingBottom: theme.spacing(5),
      flexDirection: 'row',
      display: 'flex',
      gap: theme.spacing(7.5),
      ...baseContentStyles,
      [theme.breakpoints.between(0, DRAWER_FULL_SCREEN_BREAKPOINT)]: {
        gap: 0
      },
      [theme.breakpoints.down('md')]: {
        padding: 0
      }
    },
    contentOpen: {
      paddingRight: 0,
      [theme.breakpoints.between(0, DRAWER_FULL_SCREEN_BREAKPOINT)]: {
        paddingRight: `calc(${theme.spacing(3)} + ${previewDrawerWidth}px)`
      }
    },
    contentClosed: {
      paddingRight: theme.spacing(12),
      [theme.breakpoints.down('xl')]: {
        paddingRight: theme.spacing(6)
      },
      [theme.breakpoints.down('md')]: {
        paddingRight: theme.spacing(0)
      }
    },
    stepperRoot: {
      background: 'inherit',
      padding: 0,

      width: '78%',
      margin: '0 auto',
      [theme.breakpoints.down('sm')]: {
        display: 'flex',
        justifyContent: 'space-between'
      },
      [theme.breakpoints.down('md')]: {
        width: '100%'
      }
    },
    paper: {
      padding: theme.spacing(2)
    },

    paperV2: ({ drawerPosition }) => ({
      padding: 0,
      marginTop: 0,
      marginRight: drawerPosition === 'fixed' ? 560 : 0,
      [theme.breakpoints.between(0, DRAWER_FULL_SCREEN_BREAKPOINT)]: {
        marginRight: 0
      },

      [theme.breakpoints.down('lg')]: {
        padding: theme.spacing(0, 0)
      }
    }),
    step: {
      transition: theme.transitions.create('height', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen
      })
    },
    stepLabelRoot: {
      cursor: 'pointer',
      textTransform: 'uppercase',
      [theme.breakpoints.down('sm')]: {
        display: 'flex',
        flexDirection: 'column',
        textAlign: 'center',
        '& .MuiStepLabel-labelContainer': {
          padding: 0
        }
      }
    },
    stepLabelIconContainer: {
      [theme.breakpoints.down('sm')]: {
        paddingRight: 0,
        paddingBottom: theme.spacing(1)
      }
    },
    previewButton: {
      borderRadius: '0',
      position: 'fixed',
      right: 0
    },
    missingBlueprintSubtitle: {
      color: theme.palette.error.main
    },
    missingBlueprintSubtitleClick: {
      cursor: 'pointer'
    },
    subtitle: {
      height: '28px',
      display: 'flex',
      alignItems: 'center'
    },
    blueprintContainer: {
      display: 'flex',
      alignItems: 'center'
    },
    contentMissing: {
      maxWidth: '500px',
      margin: '0 auto'
    },

    formContainer: {
      ...getColumnLayout(6)
    }
  };
});

const Program = props => {
  const {
    condensedSteps,
    condensedStepsAutomations,
    // redux form
    allFormValues,
    allFormErrors,
    handleSubmit,
    dirty,
    reset,
    destroy,
    resetProgram,
    touch
  } = props;

  const {
    previewDrawerOpen,
    togglePreviewDrawer,
    blueprints,
    handleSelectBlueprint,
    openBlueprintsModal,
    type,
    selectedBlueprint,
    facebook,
    isAutomated,
    isAutomatedEdit,
    preselectedBusinessObjectIds,
    initialValues,
    offersChanged,
    handleProgramSubmit,
    programStepper: { stepRefs, currentStep, selectExactStep },
    aiChatContext: { toggleAiChatWindow, aiChatOpen, selectAiChatInput },
    disableNavigationBlocker
  } = useProgram();

  const architecture = useArchitecture();
  const location = useLocation();
  const globalContext = useGlobalContext();
  const params = querystring.parse(location.search);
  const { enqueueSnackbar } = useSnackbar();

  const { value: isGenAiAdCopyWritingV3Enabled, experimentsLoaded } =
    useGenAiAdCopyWritingV3();
  const { setThemeName, THEME_NAMES } = useSwitchTheme();
  const isDrawerFullScreen = useIsDrawerFullScreen();
  const userMetadataFields = globalContext?.me?.metadata?.fields;
  const isClone = isProgramClone(location.search);
  const isContentSelectable = contentSelectable(
    architecture,
    selectedBlueprint
  );

  const [selectedBusinessObjects, setSelectedBusinessObjects] = useState({
    selectedBusinessObjects: [],
    loading:
      isContentSelectable &&
      type !== programActions.automatedEdit &&
      type !== programActions.automatedCreate,
    error: null
  });

  const isConfigureStep = currentStep === PROGRAM_STEP_NAME_TO_ORDER.configure;

  const {
    handleAdContentChannelValidation,
    isValidatingCreative,
    isPollingPreview,
    setIsPollingPreview,
    setIsValidatingCreative,
    creativeValidationErrors: channelValidationErrors,
    clearUpdatedInputCreativeErrors,
    inputValidators: channelInputValidators
  } = useCreativeValidationsHandler({
    product: selectedBlueprint,
    isAutomated
  });

  const conditionalInputsVisibility =
    getConditionalInputVisibilityFromBlueprint(
      selectedBlueprint,
      allFormValues,
      userMetadataFields,
      selectedBusinessObjects?.selectedBusinessObjects
    );

  const [drawerPosition, setDrawerPosition] = useState('relative');

  // User can't scroll body (program form underneath) while the drawer is open and full screen
  useLockBodyScroll(previewDrawerOpen && isDrawerFullScreen);

  // Sets the body background-color to off-white for program and automation checkout forms
  // Sets theme back to default when component unmounts
  useEffect(() => {
    setThemeName(THEME_NAMES.programCreatePage);

    return () => {
      setThemeName(THEME_NAMES.default);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const classes = useStyles({
    drawerPosition
  });

  // this tracks progressing through the steps one by one
  // if the step=spend|summary parameter is set
  const [skipStep, setSkipStep] = useState(() => {
    // This conditional ensures that an attempt to deeplink to step 3 (summary) will not crash the form in V3 treatment UI
    if (params.step === PROGRAM_STEP_NAMES.summary) {
      return;
    }
    if (params.step && PROGRAM_STEP_NAME_TO_ORDER[params.step]) {
      return {
        currentStep: 0,
        targetStep: PROGRAM_STEP_NAME_TO_ORDER[params.step],
        // we have to make sure we have tried to pull any missing content before we try and skip steps
        // this is just for pull providers and it sucks
        contentVerified: !(
          isContentSelectable && preselectedBusinessObjectIds.length > 0
        )
      };
    }
  });

  // this is when we are verifying if any content isn't pulled via pull provider
  const [fetchingContent, setFetchingContent] = useState(false);

  const [orderSuccess, setOrderSuccess] = useState(false);

  const stepTrackingData = {
    architectureId: architecture?.id,
    productId: selectedBlueprint?.id,
    channel: getOneOfEachChannelKeys(selectedBlueprint?.blueprint?.channels),
    ...(!isAutomated && { isClone })
  };

  const allConfigureStepErrors = () => {
    return pick(allFormErrors, ['configureStep', 'dynamicUserInputs']);
  };

  const formStepErrors = allConfigureStepErrors();

  const showValidationErrors = () => {
    if (!skipStep) {
      enqueueSnackbar(t('program:snackbar.formErrors'), {
        variant: 'error'
      });
    }

    // mark all fields with errors as "touched" so we show the errors
    touch(PROGRAM_FORM_NAME, ...formErrorMapper(formStepErrors));
    // not sure we need this? not sure if it's even doing anything. but it's here.
    stopSubmit(PROGRAM_FORM_NAME, formStepErrors);
  };

  const { handleNext, lockSubmit } = useHandleStepNavigation({
    skipStep,
    setSkipStep,
    trackingData: stepTrackingData,
    showValidationErrors
  });

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const trackingData = {
    architectureId: architecture?.id,
    productId: selectedBlueprint?.id,
    type: isAutomated ? 'automation' : 'program',
    channel: getOneOfEachChannelKeys(selectedBlueprint?.blueprint?.channels)
  };

  const handleStepChange = step => {
    if (step === currentStep) {
      return;
    }
    if (step < currentStep) {
      selectExactStep(step, trackingData);
    } else {
      handleNext({ currentStep, formStepErrors });
    }
  };

  useEffect(() => {
    // component will mount
    // Note We need to reset() redux form on mount because we have set destroyOnUnmount:false to
    //      preserve the form state for the stepper. This will allow form elements in each step to
    //      mount in and out and maintain their state.
    reset();

    // reset program back to initial state
    resetProgram();

    if (isMobile && previewDrawerOpen) {
      togglePreviewDrawer();
    }

    if (isAutomated) {
      Instrumentation.logEvent('program-automation-load');
    } else {
      Instrumentation.logEvent('program-load', {
        isClone,
        type: programTrackingTypes.program
      });
    }

    // compunent will unmount
    return () => {
      destroy();
      // reset program back to initial state
      resetProgram();
    };
  }, []);

  const strings = {
    [programActions.create]: {
      title: t('program:create.title')
    },
    [programActions.automatedCreate]: {
      title: t('program:automatedCreate.title'),
      subtitle: t('program:automatedCreate.subtitle')
    },
    [programActions.automatedEdit]: {
      title: t('program:automatedEdit.title'),
      subtitle: t('program:automatedCreate.subtitle')
    },
    previewButtonTip: t('program:preview.button'),
    missingBlueprintSubtitle: t('program:create.missingBlueprintSubtitle')
  };

  const handleSubmitHandler = data => {
    return handleProgramSubmit(data, {
      architecture,
      selectedBlueprint,
      selectedBusinessObjects,
      userMetadataFields
    })
      .then(({ afterSuccess }) => {
        // we have to set the form to success before we can navigate etc.
        setOrderSuccess(true);
        setTimeout(() => afterSuccess(), 0);
      })
      .catch(({ error, postErrorMessage }) => {
        const errorName = error?.graphQLErrors[0]?.extensions?.errorName;
        const errorDisplayCode =
          error?.graphQLErrors[0]?.extensions?.additionalExceptionDetails
            ?.displayCode;

        // if card declined / billing error
        if (
          errorName === programErrorTypes.billingException ||
          errorName === programErrorTypes.paymentAuthorizationException
        ) {
          const errorMessage =
            paymentErrorByBackendDisplayCode(errorDisplayCode) ||
            genericCardDeclinedError;

          enqueueSnackbar(errorMessage, {
            variant: 'error'
          });

          // set a submission error that we look for on the payment selection component
          throw new SubmissionError({
            paymentMethodId: errorMessage
          });
        }

        // validation errors
        if (
          error?.graphQLErrors?.[0]?.extensions?.errorName ===
          programErrorTypes?.validationException
        ) {
          // if start date is before today
          if (
            error?.graphQLErrors?.[0]?.message &&
            error?.graphQLErrors?.[0]?.message.match(
              programErrorMessageToMatch.startDateBeforeToday
            )
          ) {
            enqueueSnackbar(t('programCreate:snackbar.invalidStartDate'), {
              variant: 'error'
            });

            // set a submission error on startDate
            throw new SubmissionError({
              spendStep: {
                startDate: t(
                  'programCreate:snackbar.invalidStartDateSubmissionError'
                )
              }
            });
          }
        }

        enqueueSnackbar(
          postErrorMessage || t('programCreate:snackbar.submitErrorGeneric'),
          {
            variant: 'error'
          }
        );
      });
  };

  const aiAdCopyWriterInputs = getAiAdCopyWriterInputs({
    dynamicUserInputs:
      allFormValues?.[PROGRAM_FORM_SECTION_DYNAMIC_INPUTS_NAME],
    selectedBlueprint,
    businessObjects: selectedBusinessObjects?.selectedBusinessObjects,
    formValues: allFormValues,
    userMetadataFields
  });

  const getString = stringId => {
    return strings?.[type]?.[stringId];
  };

  const architectureHasCatalog = hasCatalog(architecture, selectedBlueprint);
  const contentName =
    architecture?.catalog?.friendlyName ??
    t('programCreate:configure.contentDefaultName');

  let title = getString('title');

  if (architecture?.name) {
    title += `: ${architecture.name}`;
  }

  // we need to remove the fixSyncErrorInputNameFilters from the form values so it does not trigger the navigation blocker
  const dirtyCheckFormValues = allFormValues;
  delete dirtyCheckFormValues?.fixSyncErrorInputNameFilters;

  const isAutomatedDirty =
    isAutomated && !isEqual(initialValues, dirtyCheckFormValues);

  // Warn the user when they navigate away from the page only if the form
  // has been changed (i.e. keep them from throwing away order work
  // they've done on accident.
  // Note: Once the user has placed the order, the form stays in a dirty
  //       state so we override this variable to always be false if we
  //       have a successful order.
  let shouldBlockNavigation = isAutomated ? isAutomatedDirty : dirty;

  if (disableNavigationBlocker || orderSuccess) {
    shouldBlockNavigation = false;
  }

  const submitForm = values => {
    handleSubmit(handleSubmitHandler)(values);
  };

  const programStepProps = {
    allFormValues,
    architecture,
    architectureHasCatalog,
    blueprints,
    contentName,
    facebook,
    formName: PROGRAM_FORM_NAME,
    initialValues,
    isAutomated,
    isAutomatedEdit,
    isContentSelectable,
    selectedBlueprint,
    handleSelectBlueprint,
    selectedBusinessObjects,
    setSelectedBusinessObjects,
    stepRefs, // test
    type,
    skipStep,
    setSkipStep,
    fetchingContent,
    setFetchingContent,
    submitForm,
    handleNext,
    lockSubmit,
    showValidationErrors,
    conditionalInputsVisibility,
    ...(isConfigureStep && {
      channelValidationErrors,
      channelInputValidators,
      isChannelValidationLoading: isPollingPreview || isValidatingCreative,
      adCreativeErrors: channelValidationErrors,
      ...(isGenAiAdCopyWritingV3Enabled && {
        aiAdCopyWriterInputs,
        aiChat: { toggleAiChatWindow, aiChatOpen, selectAiChatInput }
      })
    }),
    ...(currentStep === PROGRAM_STEP_NAME_TO_ORDER.spend && {
      offersChanged
    })
  };

  if (fetchingContent) {
    return (
      <Alert
        severity="info"
        icon={<CircularProgress />}
        className={classes.contentMissing}
      >
        <AlertTitle>
          {t('programCreate:contentMissing.title', {
            contentName
          })}
        </AlertTitle>
        {t('programCreate:contentMissing.description', {
          contentName
        })}
      </Alert>
    );
  }

  const formSteps = isAutomated ? condensedStepsAutomations : condensedSteps;
  const isBreadcrumbVisible = !isAutomatedEdit;

  const breadcrumbPieces = [
    {
      text:
        type === programActions.create
          ? t('program:kind')
          : t('program:automatedKind'),
      to:
        type === programActions.create
          ? paths.programs.base
          : paths.automations.base
    },
    {
      text: getString('title')
    }
  ];

  return (
    <div className={classes.container} data-cy={type}>
      <PageTitle subPageTitle={title} />
      <NavigationBlocker block={shouldBlockNavigation} />

      <Box
        className={classNames(classes.content, {
          [classes.contentOpen]: previewDrawerOpen,
          [classes.contentClosed]: !previewDrawerOpen
        })}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: 5,
            width: '100%'
          }}
        >
          <Box>
            {isBreadcrumbVisible && (
              <BreadcrumbTrail
                sx={theme => ({ marginBottom: theme.spacing(1) })}
                pieces={breadcrumbPieces}
              />
            )}
            <div className={classes.blueprintContainer}>
              {/* TODO update to uuse heading */}
              <Typography
                component="h1"
                variant="h4"
                sx={{ fontWeight: 'bold', fontSize: '30px' }}
                data-cy="selected-bp-title"
                className={classNames({
                  [classes.missingBlueprintSubtitle]: !selectedBlueprint,
                  [classes.missingBlueprintSubtitleClick]:
                    !isAutomatedEdit && isAutomated
                })}
                onClick={
                  isAutomated && !isAutomatedEdit ? openBlueprintsModal : noop
                }
              >
                {selectedBlueprint?.name ?? strings.missingBlueprintSubtitle}
              </Typography>
              {!isAutomatedEdit && isAutomated && currentStep === 0 && (
                // we don't allow blueprint changes on edit
                <IconButton
                  data-cy="change-blueprint-button"
                  size="small"
                  color={selectedBlueprint ? 'default' : 'error'}
                  onClick={openBlueprintsModal}
                >
                  <EditIcon fontSize="inherit" />
                </IconButton>
              )}
            </div>
          </Box>
          <StepSkipper skipStep={skipStep} setSkipStep={setSkipStep}>
            <Box>
              {/* Necessary for all BusinessObject components using shared context */}
              <form onSubmit={handleSubmit(handleSubmitHandler)}>
                <ProgramStepper
                  isMobile={isMobile}
                  formSteps={formSteps}
                  classes={classes} // remove
                  programStepProps={programStepProps}
                  handleStepChange={handleStepChange}
                  drawerPosition={drawerPosition}
                />
              </form>
            </Box>
          </StepSkipper>
        </Box>
        <ProgramPreviewDrawerLegacy
          isMobile={isMobile}
          architecture={architecture}
          contentName={contentName}
          selectedBlueprint={selectedBlueprint}
          selectedBusinessObjects={selectedBusinessObjects}
          defaultFacebookPage={facebook?.getSelectedFacebookPage({
            pageGroupId: allFormValues?.dynamicUserInputs?.pageGroupId,
            pageId: allFormValues?.dynamicUserInputs?.pageId
          })}
          isAutomatedProgram={isAutomated}
          blueprintsLoading={blueprints?.loading}
          conditionalInputsVisibility={conditionalInputsVisibility}
          facebook={facebook}
          drawerPosition={drawerPosition}
          setDrawerPosition={setDrawerPosition}
          {...(isConfigureStep && {
            handleAdContentChannelValidation,
            setIsPollingPreview,
            isPollingPreview,
            setIsValidatingCreative,
            clearUpdatedInputCreativeErrors
          })}
        />
      </Box>
      {isGenAiAdCopyWritingV3Enabled &&
        experimentsLoaded &&
        isConfigureStep && (
          <AiChat
            inputs={aiAdCopyWriterInputs}
            sharedInputProps={{
              blueprint: selectedBlueprint,
              businessObjects: selectedBusinessObjects?.selectedBusinessObjects,
              isContentSelectable,
              contentName,
              contentColumns:
                contentColumnsFromArchitecture(architecture) || [],
              formName: PROGRAM_FORM_NAME
            }}
          />
        )}
    </div>
  );
};

const mapStateToProps = state => {
  const allFormValues = getFormValues(PROGRAM_FORM_NAME)(state);
  const allFormErrors = getFormSyncErrors(PROGRAM_FORM_NAME)(state);

  return {
    steps: state?.program.steps,
    condensedSteps: state?.program.condensedSteps,
    condensedStepsAutomations: state?.program?.condensedStepsAutomations,
    allFormValues,
    allFormErrors
  };
};

export default flow(
  reduxForm({
    form: PROGRAM_FORM_NAME,
    // Note We set destroyOnUnmount=false because we need to preserve the form state for
    //      the stepper. By default redux form will remove a field from state if the field unmounts.
    destroyOnUnmount: false,
    enableReinitialize: true,
    keepDirtyOnReinitialize: true,
    forceUnregisterOnUnmount: true,
    updateUnregisteredFields: true,
    persistentSubmitErrors: true
  }),
  connect(mapStateToProps, {
    resetProgram
  })
)(Program);
