import { useTheme, Step, StepLabel, Stepper, Box } from '@mui/material';

import ProgramStep from './ProgramStep';
import useProgram from '../utils/useProgram';

// TODO: Replace with actual props and types
interface ProgramStepperProps {
  [any: string]: any;
}

const ProgramStepper = ({
  isMobile,
  classes,
  programStepProps,
  handleStepChange,
  drawerPosition
}: ProgramStepperProps) => {
  const theme = useTheme();
  const {
    programStepper: { currentStep, steps, stepRefs }
  } = useProgram();

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: theme.spacing(6)
      }}
    >
      <Stepper
        sx={{
          position: 'relative',
          background: 'inherit',
          padding: `0 ${theme.spacing(2)}`,
          maxWidth: drawerPosition === 'fixed' ? 'calc(100% - 560px)' : '100%',
          minWidth: '500px',

          display: 'flex',
          justifyContent: 'space-between',

          [theme.breakpoints.down('md')]: {
            width: '100%'
          }
        }}
        orientation="horizontal"
        nonLinear
        activeStep={currentStep}
        {...(isMobile ? { connector: null } : {})}
      >
        {steps.map((step, i) => {
          return (
            <Step
              completed={currentStep > i}
              key={`createProgramStep-${step.label}`}
              id={`program-step-${i}`}
              ref={stepRefs[i]}
            >
              <StepLabel
                error={step.error}
                onClick={() => handleStepChange(i)}
                classes={{
                  root: classes.stepLabelRoot,
                  iconContainer: classes.stepLabelIconContainer
                }}
              >
                {step.label}
              </StepLabel>
            </Step>
          );
        })}
      </Stepper>
      <Box
        sx={{
          minHeight: '650px'
        }}
        className={classes.paperV2}
      >
        <ProgramStep
          component={steps[currentStep].component}
          {...programStepProps}
        />
      </Box>
    </Box>
  );
};

export default ProgramStepper;
