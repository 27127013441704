import { alpha, Box } from '@mui/system';
import { Trans } from 'react-i18next';
import { t } from 'i18next';
import {
  AutoAwesome as AiChatIcon,
  HelpOutline as HelpIcon,
  ExpandMore as CloseIcon
} from '@mui/icons-material';

import { IconButton, Tooltip } from '@mui/material';
import { AiChatType } from './constants';

interface AiChatHeaderProps {
  onClose: () => void;
  type: AiChatType;
}

const AiChatHeader = ({ onClose, type }: AiChatHeaderProps) => {
  return (
    <Box
      data-cy="ai-chat-header"
      sx={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        py: 2,
        pl: 3,
        pr: 2,
        bgcolor: theme => alpha(theme.palette.primary.main, 0.1),
        boxShadow: 1
      }}
    >
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          fontWeight: 'bold'
        }}
      >
        <AiChatIcon fontSize="small" sx={{ mr: 3 }} />
        <Trans i18nKey="aiSuggestion:chat.featureName" /> -
        <Box
          component="span"
          sx={{
            ml: 0.5,
            mr: 1,
            textTransform: 'capitalize',
            fontWeight: 'normal'
          }}
        >
          {type}
        </Box>{' '}
        <Tooltip title={t('aiSuggestion:chat.headerTooltip')}>
          <HelpIcon
            sx={{
              fill: theme => theme.palette.grey[600],
              fontSize: 'medium'
            }}
          />
        </Tooltip>
      </Box>
      <IconButton
        data-cy="close-ai-chat-header-button"
        onClick={onClose}
        sx={{ p: 1, ml: 2 }}
      >
        <CloseIcon />
      </IconButton>
    </Box>
  );
};

export default AiChatHeader;
