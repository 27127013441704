import { useEffect, useMemo, useState } from 'react';
import { useTheme, lighten } from '@mui/system';
import { Box, Button, IconButton, Tooltip, Typography } from '@mui/material';
import { find, forEach, map, uniq } from 'lodash';
import { useFormContext, useWatch } from 'react-hook-form';
import { t } from 'i18next';
import {
  ChevronLeft as PrevIcon,
  ChevronRight as NextIcon,
  Loop as RegenerateIcon,
  RecordVoiceOverOutlined as StylizeOutlinedIcon,
  RecordVoiceOver as StylizeFilledIcon,
  ThumbDownOutlined as DislikeOutlinedIcon,
  ThumbDown as DislikeFilledIcon
} from '@mui/icons-material';
import { useLazyQuery } from '@apollo/client';

import { useArchitecture } from 'src/pages/Architecture/ArchitectureProvider';
import FormInput from 'src/components/ReduxForm/DynamicForm/FormInput';
import { useGlobalContext } from 'src/GlobalContextProvider';
import { AssetMenu, useMenu } from 'src/components/Menus';
import Instrumentation from 'src/instrumentation';
import useProgram from 'src/pages/Program/utils/useProgram';

import {
  getAiStylizeText,
  selectableStyles
} from 'src/common/aiAdCopySuggestion';
import MessageBackground from './MessageBackground';
import { InputField } from '../helpers';
import { getAiTextSuggestions as getAiTextSuggestionsQuery } from '../queries';
import useHandleError from '../useHandleError';

interface AdCopySuggestionMessageProps {
  input: InputField;
  sharedInputProps: Record<string, any>;
  setIsThinking: (isThinking: boolean) => void;
}

const AdCopySuggestionMessage = ({
  input,
  sharedInputProps,
  setIsThinking
}: AdCopySuggestionMessageProps) => {
  const buttonId = `ai-chat-suggestion-message-menu-button-${input?.fieldName}`;
  const menuId = `ai-chat-suggestion-message-menu-${input?.fieldName}`;
  const text = useMemo(() => getAiStylizeText(), []);
  const [currentSuggestionIndex, setCurrentSuggestionIndex] = useState(0);
  const context = useFormContext();
  const architecture = useArchitecture();
  const [getAiTextSuggestions, { loading, error }] = useLazyQuery(
    getAiTextSuggestionsQuery
  );
  const handleError = useHandleError();

  const { handleOpenMenu, isMenuOpen, menuAnchorEl, handleCloseMenu } =
    useMenu();
  const theme = useTheme();
  const bgcolor = lighten(theme.palette.primary.main, 0.9);

  const metadata = { ...input, isRequired: false };
  const disabled = metadata?.disabled ?? false;
  const globalContext = useGlobalContext();
  const { selectedBlueprint } = useProgram();

  const displayName = input?.displayName;
  const inputData = useWatch({ name: input?.blueprintVariableId });
  const productId = selectedBlueprint?.id;
  const businessObjectId = sharedInputProps?.businessObjects?.[0].id;

  const commonTrackingData = {
    input: displayName,
    productId,
    architectureId: selectedBlueprint?.architectureId
  };

  const formValues = context.watch();

  const handleGetAiTextSuggestions = async ({
    fields = []
  }: {
    fields?: { blueprintVariableId: string; dislikedIndexes?: number[] }[];
  }) => {
    const catalogId = architecture.catalog?.id || '';
    const catalogFilter = {
      id: {
        in: businessObjectId ? [businessObjectId] : []
      }
    };

    const formattedFields = map(fields, field => {
      return {
        blueprintVariableId: field.blueprintVariableId,
        dislikedGeneration: map(field.dislikedIndexes, index => {
          return formValues?.[field.blueprintVariableId]?.suggestions?.[index]
            ?.text;
        })
      };
    });

    const variables = {
      input: {
        fields: formattedFields,
        catalogId,
        catalogFilter,
        productId
      }
    };

    const response = await getAiTextSuggestions({
      variables,
      fetchPolicy: 'no-cache'
    }).catch(e => {
      handleError(e);
    });

    const responseFields = response?.data?.aiTextSuggestions?.fields;

    forEach(responseFields, field => {
      const argumentField = find(fields, {
        blueprintVariableId: field?.blueprintVariableId
      });

      if (
        field?.blueprintVariableId &&
        field?.suggestions?.[0] &&
        argumentField
      ) {
        const currentFieldValues = formValues?.[field?.blueprintVariableId];
        const currentSuggestions = currentFieldValues?.suggestions || [];

        context.setValue(field?.blueprintVariableId, {
          suggestions: [
            ...currentSuggestions,
            { text: field?.suggestions?.[0] }
          ],
          ...(argumentField?.dislikedIndexes && {
            disliked: argumentField?.dislikedIndexes
          })
        });
      }
    });
  };

  const handleDislike = async () => {
    const isDisliked = inputData.disliked?.includes(currentSuggestionIndex);

    Instrumentation.logEvent(Instrumentation.Events.ClickAiCopyDislike, {
      ...commonTrackingData,
      action: isDisliked ? 'remove' : 'add'
    });

    let newDisliked = [...(inputData?.disliked || [])];

    // If isDisliked, we want to remove the current suggestion from the disliked list
    if (isDisliked) {
      newDisliked = newDisliked.filter(
        suggestion => suggestion !== currentSuggestionIndex
      );
      context.setValue(input.blueprintVariableId, {
        ...inputData,
        disliked: newDisliked
      });
    } else {
      setIsThinking(true);
      const existingDisliked = inputData?.disliked || [];
      newDisliked = uniq([...existingDisliked, currentSuggestionIndex]);

      // Get new suggestions if disliked
      await handleGetAiTextSuggestions({
        fields: [
          {
            blueprintVariableId: input.blueprintVariableId,
            dislikedIndexes: newDisliked
          }
        ]
      }).catch(handleError);
      setCurrentSuggestionIndex(inputData.suggestions.length);
      setIsThinking(false);
    }
  };

  const handleRegenerate = async () => {
    setIsThinking(true);
    Instrumentation.logEvent(
      Instrumentation.Events.ClickAiCopyRegenerate,
      commonTrackingData
    );

    const existingDisliked = inputData?.disliked || [];
    await handleGetAiTextSuggestions({
      fields: [
        {
          blueprintVariableId: input.blueprintVariableId,
          dislikedIndexes: existingDisliked
        }
      ]
    })
      .catch(handleError)
      .finally(() => {
        setIsThinking(false);
      });

    setCurrentSuggestionIndex(prevState => prevState + 1);
  };

  // TODO: When we implement the apply button functionality, check the lift required to add `isEdited` to the event data
  const handleApply = () => {
    Instrumentation.logEvent(
      Instrumentation.Events.ClickAiCopyApply,
      commonTrackingData
    );
  };

  const handleSuggestionChange = (value: string) => {
    const newSuggestion = {
      ...inputData.suggestions[currentSuggestionIndex],
      text: value
    };

    const newSuggestions = [...inputData.suggestions];
    newSuggestions[currentSuggestionIndex] = newSuggestion;
    context.setValue(input.blueprintVariableId, {
      ...inputData,
      suggestions: newSuggestions
    });
  };

  const value = inputData?.suggestions[currentSuggestionIndex]?.text;
  const suggestion = inputData?.suggestions[currentSuggestionIndex];

  const suggestions = inputData?.suggestions || [];
  const suggestionCount = suggestions.length;
  const nextSuggestion = () => {
    setCurrentSuggestionIndex(prev => Math.min(prev + 1, suggestionCount - 1));
  };

  const prevSuggestion = () => {
    setCurrentSuggestionIndex(prev => Math.max(prev - 1, 0));
  };

  useEffect(() => {
    if (suggestionCount - 1 !== currentSuggestionIndex) {
      setCurrentSuggestionIndex(suggestionCount - 1);
    }
  }, [suggestionCount]);

  useEffect(() => {
    if (error) {
      handleError(error);
    }
  }, [error]);

  return (
    <MessageBackground bgcolor={bgcolor}>
      <FormInput
        isHookForm
        isAdCopySuggestionMessageInput
        metadata={metadata}
        disabled={disabled}
        globalContext={globalContext}
        labelBackground={lighten(theme.palette.primary.main, 0.9)}
        onChange={handleSuggestionChange}
        value={value}
        {...sharedInputProps}
      />
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between'
        }}
      >
        <Box sx={{ display: 'flex', gap: 0.5 }}>
          {suggestionCount > 1 && (
            <Box
              data-cy="ad-copy-suggestion-pagination"
              sx={{ display: 'flex', alignItems: 'center' }}
            >
              <IconButton
                data-cy="ad-copy-suggestion-pagination-prev"
                size="small"
                onClick={prevSuggestion}
                disabled={loading}
              >
                <PrevIcon fontSize="small" />
              </IconButton>
              <Typography variant="body2">{`${currentSuggestionIndex + 1}/${suggestionCount}`}</Typography>
              <IconButton
                data-cy="ad-copy-suggestion-pagination-next"
                size="small"
                onClick={nextSuggestion}
                disabled={loading}
              >
                <NextIcon fontSize="small" />
              </IconButton>
            </Box>
          )}
          <Tooltip title={t('aiSuggestion:chat.regenerateButtonTooltip')}>
            <IconButton
              data-cy="ad-copy-suggestion-regenerate-button"
              size="small"
              onClick={() => {
                handleRegenerate().catch(handleError);
              }}
              disabled={loading}
            >
              <RegenerateIcon fontSize="small" />
            </IconButton>
          </Tooltip>
          <Tooltip title={t('aiSuggestion:chat.dislikeButtonTooltip')}>
            <IconButton
              data-cy="ad-copy-suggestion-dislike-button"
              size="small"
              onClick={() => {
                handleDislike().catch(handleError);
              }}
              disabled={loading}
            >
              {inputData?.disliked?.includes(currentSuggestionIndex) ? (
                <DislikeFilledIcon fontSize="small" />
              ) : (
                <DislikeOutlinedIcon fontSize="small" />
              )}
            </IconButton>
          </Tooltip>
          <>
            <Tooltip
              title={`${t('aiSuggestion:chat.stylizeButtonTooltip')}${suggestion?.style ? `: ${text.styleOption[suggestion.style.toLowerCase() as keyof typeof selectableStyles]}` : ''}`}
            >
              <IconButton
                data-cy="ad-copy-suggestion-stylize-button"
                size="small"
                aria-controls={isMenuOpen ? menuId : undefined}
                aria-expanded={isMenuOpen ? 'true' : undefined}
                aria-haspopup="true"
                onClick={handleOpenMenu}
                id={buttonId}
                disabled={loading}
              >
                {suggestion?.style ? (
                  <StylizeFilledIcon fontSize="small" />
                ) : (
                  <StylizeOutlinedIcon fontSize="small" />
                )}
              </IconButton>
            </Tooltip>

            <AssetMenu
              id={menuId}
              handleClose={handleCloseMenu}
              open={isMenuOpen}
              ariaLabeledBy={buttonId}
              anchorEl={menuAnchorEl}
              disabled={loading}
              options={Object.keys(selectableStyles).map(style => {
                return {
                  label:
                    text.styleOption[style as keyof typeof selectableStyles],
                  onClick: () => {
                    Instrumentation.logEvent(
                      Instrumentation.Events.ClickAiStylize,
                      {
                        input: displayName
                      }
                    );

                    setCurrentSuggestionIndex(prevState => prevState + 1);
                    const newSuggestions = [
                      ...inputData.suggestions,
                      { text: 'This is my stylized suggestion', style }
                    ];

                    context.setValue(input.blueprintVariableId, {
                      ...inputData,
                      suggestions: newSuggestions
                    });
                  }
                };
              })}
            />
          </>
        </Box>
        <Button disabled={loading} sx={{ mr: '6px' }} onClick={handleApply}>
          {t('aiSuggestion:chat.applyButton')}
        </Button>
      </Box>
    </MessageBackground>
  );
};

export default AdCopySuggestionMessage;
