import { Fab, Tooltip } from '@mui/material';
import {
  AutoAwesome as AiChatIcon,
  Close as CloseIcon
} from '@mui/icons-material';
import { t } from 'i18next';

import { aiChatWindowDimensions } from './constants';

interface AiChatFloatingButtonProps {
  onClick: () => void;
  isOpen: boolean;
}

const AiChatFloatingButton = ({
  onClick,
  isOpen
}: AiChatFloatingButtonProps) => {
  return (
    <Tooltip title={t('aiSuggestion:chat.fabTooltip')}>
      <Fab
        data-cy="open-ai-chat-button"
        color="primary"
        sx={{
          position: 'fixed',
          bottom: 16,
          right: aiChatWindowDimensions.xPosition,
          zIndex: theme => theme.zIndex.appBar
        }}
        onClick={onClick}
      >
        {isOpen && <CloseIcon />}
        {!isOpen && <AiChatIcon />}
      </Fab>
    </Tooltip>
  );
};

export default AiChatFloatingButton;
