import { ReactElement, JSXElementConstructor } from 'react';
import { noop } from 'lodash';
import Loading from 'src/components/Loading';
import { getByType } from 'src/common/utilities/checkElementType';

import {
  Dialog,
  DialogTitle as MuiDialogTitle,
  DialogContent as MuiDialogContent,
  DialogActions as MuiDialogActions,
  IconButton,
  Breakpoint,
  DialogProps,
  SxProps
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

type Component = ReactElement | JSXElementConstructor<unknown> | null;

interface ModalProps extends Omit<DialogProps, 'maxWidth'> {
  children?: React.ReactNode;
  HeaderComponent?: Component;
  headerText?: string | ReactElement;
  FooterComponent?: Component;
  footerText?: string;
  showClose?: boolean;
  onClose?: () => void;
  loading?: boolean;
  open: boolean;
  height?: string;
  maxWidth?: false | Breakpoint | string | number;
  dialogContentSx?: SxProps;
}

const Modal = (props: ModalProps) => {
  const {
    children = null,
    HeaderComponent = null,
    headerText = null,
    FooterComponent = null,
    footerText,
    showClose = true,
    onClose = noop,
    loading = false,
    open = false,
    height,
    maxWidth,
    dialogContentSx = {},
    ...restProps
  } = props;
  const hasFooter = FooterComponent || footerText;
  const hasHeader = HeaderComponent || headerText;

  // Checks if the FooterComponent/HeaderComponent is a Component or a function that returns a Component/JSX
  // and stores the renderable element in a variable
  const Footer = getByType(FooterComponent);
  const Header = getByType(HeaderComponent);

  // MUI only accepts a breakpoint (xs, sm, md, lg, xl), false or a string (but not a px value string)
  // Check if maxWidth is a number or a px value string and set the dialog paper's max width
  // instead of using it as a prop on Dialog

  // This probably needs an extra check to see if the value is a string AND it includes `px`
  const isCustomMaxWidth =
    typeof maxWidth === 'number' || (maxWidth as string)?.includes('px');

  return (
    <Dialog
      {...restProps}
      {...(!isCustomMaxWidth && { maxWidth: maxWidth as false | Breakpoint })}
      open={open}
      onClose={onClose}
      sx={{
        '.MuiDialog-paper': {
          pb: !hasFooter ? 2 : 0,
          height,
          ...(isCustomMaxWidth && {
            maxWidth: maxWidth as string
          })
        }
      }}
    >
      {(hasHeader || showClose) && (
        <MuiDialogTitle
          sx={{
            borderBottom: theme => `1px solid ${theme.palette.divider}`,
            margin: 0,
            px: 3,
            py: 2,
            display: 'flex',
            justifyContent: 'space-between',
            gap: 2,
            fontSize: '26px',
            fontWeight: 'bold'
          }}
        >
          {HeaderComponent ? Header : headerText}
          {showClose && (
            <IconButton
              aria-label="Close"
              sx={{
                position: 'relative',
                left: theme => theme.spacing(1.5),
                color: 'text.primary'
              }}
              onClick={onClose}
              size="medium"
              data-cy="modal-close-button"
            >
              <CloseIcon />
            </IconButton>
          )}
        </MuiDialogTitle>
      )}

      <MuiDialogContent
        sx={{
          px: 3,
          pt: theme => `${theme.spacing(2)} !important`,
          pb: 0,
          m: 0,
          ...dialogContentSx
        }}
      >
        {loading ? <Loading /> : <>{children}</>}
      </MuiDialogContent>
      {hasFooter && !loading && (
        <MuiDialogActions
          sx={{
            m: 0,
            px: 2.5,
            py: 2
          }}
        >
          {FooterComponent ? Footer : footerText}
        </MuiDialogActions>
      )}
    </Dialog>
  );
};

export default Modal;
