import { Trans } from 'react-i18next';

const ClipboardButtonText = props => {
  const { isCopied } = props;
  return isCopied ? (
    <Trans i18nKey="copyToClipBoardButton.copied">Copied</Trans>
  ) : (
    <Trans i18nKey="copyToClipBoardButton.copy">Copy</Trans>
  );
};

export default ClipboardButtonText;
