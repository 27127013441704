import { flow, isEmpty } from 'lodash';
import { FieldArray, getFormValues } from 'redux-form';
import { connect } from 'react-redux';
import 'draft-js/dist/Draft.css';

import { Grid } from '@mui/material';

import withStyles from '@mui/styles/withStyles';

import { withGlobalContext } from 'src/GlobalContextProvider';
import { isConditionalVisible } from 'src/common/conditionals';

import NavigationBlocker from 'src/components/NavigationBlocker';
import HookFormFieldArray from 'src/components/ReduxForm/DynamicForm/HookFormFieldArray';

import { EDIT_MODES, DEFAULT_MD_BY_DISPLAY_METHOD_ID } from './constants';
import FormInput from './FormInput';
import MultiInput from './MultiInput';
import { getFieldName, sortAndFilterFormInputs } from './helpers';

const styles = () => ({});

const DynamicForm = props => {
  const {
    blueprint,
    contentColumns,
    inputs,
    dirty,
    disabled,
    editType,
    businessObjects,
    formName,
    isContentSelectable,
    contentName,
    formValues: reduxFormValues,
    globalContext,
    isHookForm = false,
    channelInputValidators,
    formSectionName,
    hookFormMethods,
    selectedLocationsMetadata = [],
    isMultiLocation,
    aiAdCopyWriterInputs,
    aiChat
  } = props;
  const usableInputs = sortAndFilterFormInputs(inputs);
  const userMetadataFields = globalContext?.me?.metadata?.fields;

  const formValues = isHookForm ? hookFormMethods?.watch() : reduxFormValues;

  return (
    <Grid container spacing={3}>
      <NavigationBlocker block={!!dirty} />
      {usableInputs.map(inputMetadata => {
        let md = Number(
          inputMetadata?.displayParameters?.inputData?.columnWidth
        );

        if (!md) {
          md =
            DEFAULT_MD_BY_DISPLAY_METHOD_ID[inputMetadata?.displayMethodId] ||
            2;
        }

        const hasConditional = !isEmpty(
          inputMetadata?.displayParameters?.inputData?.conditionalRender
        );

        // if has conditionalRender
        if (hasConditional) {
          const conditionalRender =
            inputMetadata?.displayParameters?.inputData?.conditionalRender;

          // find the input name the conditional depends on
          if (
            !isConditionalVisible({
              inputMetadata,
              formValues,
              conditionalRender,
              userMetadataFields,
              businessObjects
            })
          ) {
            // Note: This return prevents the input from rendering
            //       when the conditional should not be visible
            return;
          }
        }

        let disableInput = inputMetadata?.disabled ?? false;
        // NOTE: If disabled is passed we want the all inputs disabled so this
        //       will override the individual input disabled.
        if (disabled) {
          disableInput = disabled;
        }

        const fieldName = getFieldName(inputMetadata);

        // Since layouts are in quarters (1 - 4), we multiply by 3
        // to get it into a 12-column grid.
        md *= 3;
        const key = `i-${fieldName}`;
        // hide hidden fields
        const style = inputMetadata?.isHidden ? { display: 'none' } : {};

        // these same props get sent to 2 components
        const sharedProps = {
          blueprint,
          metadata: inputMetadata,
          contentColumns,
          disabled: disableInput,
          editType,
          businessObjects,
          formName,
          isContentSelectable,
          contentName,
          channelInputValidators,
          isHookForm,
          formSectionName,
          selectedLocationsMetadata,
          isMultiLocation,
          aiAdCopyWriterInputs,
          aiChat
        };

        return (
          <Grid
            key={key}
            item
            xs={12}
            sm={12}
            md={md}
            style={style}
            data-cy={`${fieldName}-root-ele`}
          >
            {/* check if it's an array of inputs then defer to multiInput */}
            {(inputMetadata?.isMultiInput || inputMetadata?.childInputs) &&
              !isHookForm && (
                <FieldArray
                  name={fieldName}
                  component={MultiInput}
                  DynamicForm={DynamicForm}
                  {...sharedProps}
                />
              )}
            {/* TODO: Add react hook form FieldArray component */}
            {(inputMetadata?.isMultiInput || inputMetadata?.childInputs) &&
              isHookForm && (
                <HookFormFieldArray
                  name={fieldName}
                  component={MultiInput}
                  DynamicForm={DynamicForm}
                  {...sharedProps}
                />
              )}
            {!inputMetadata?.isMultiInput && !inputMetadata?.childInputs && (
              <FormInput {...sharedProps} />
            )}
          </Grid>
        );
      })}
    </Grid>
  );
};

DynamicForm.EDIT_MODES = EDIT_MODES;

const mapStateToProps = (state, ownProps) => {
  if (ownProps.formName) {
    // if formName exists we want to get the form values for the conditional inputs
    const formValues = getFormValues(ownProps.formName)(state);
    return { formValues };
  }
  return {};
};

export default flow(
  connect(mapStateToProps),
  withGlobalContext,
  withStyles(styles)
)(DynamicForm);
