import { gql } from 'src/generated/gql';

export const getAiTextSuggestions = gql(`
    query getAiTextSuggestionsForAiChat($input: AiTextSuggestionsInput!) {
        aiTextSuggestions(input: $input) {
            promptType
            fields {
                blueprintVariableId
                prompt
                suggestions
            }
        }
    }
`);
