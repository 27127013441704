export const Events = {
  AddNewProgramClicked: 'click-add-new-program',
  AutomationEditPurchaseClicked: 'click-purchase-edit-automation',
  AutomationGrowthExperimentClose: 'click-automation-growth-experiment-close',
  AutomationPurchaseClicked: 'click-purchase-automation',
  BillingHistoryClicked: 'click-billing-history',
  CancelProgramClicked: 'click-cancel-program',
  ClickQuickProgramsContent: 'click-quick-programs-content',
  ClickQuickProgramsContinue: 'click-quick-programs-continue',
  DeleteGalleryClicked: 'click-delete-gallery',
  EditProgramActionsClicked: 'click-edit-program-actions',
  EditProgramClicked: 'click-edit-program',
  GoogleAdDetailsClicked: 'click-google-ad-details',
  GraphQLRequest: 'graphql-request',
  InitialLoad: 'initial-load',
  MediaLibraryAssetUploaded: 'media-library-asset-uploaded',
  MediaLibrarySelectFromGallery: 'click-media-library-select-from-gallery',
  MediaLibraryShutterstock: 'media-library-shutterstock',
  MediaLibraryUpload: 'media-library-upload',
  PageExit: 'page-exit',
  PageLoaded: 'page-loaded',
  PageView: 'page-view',
  ProgramBusinessObjectSelectedClicked:
    'click-program-business-object-selected-clicked',
  ProgramBusinessObjectsSelectorClicked:
    'click-program-business-objects-selector-clicked',
  ProgramBusinessObjectsSelectorCloseClicked:
    'click-program-business-objects-selector-close-clicked',
  ProgramChooseBlueprintModalClicked:
    'click-program-choose-blueprint-modal-clicked',
  ProgramLinkFacebookPageClicked: 'click-program-link-facebook-page',
  ProgramPreviewVisibilityClicked: 'click-program-preview-visibility',
  ProgramSelectFacebookPageClicked: 'click-program-select-facebook-page',
  ProgramStartStepClicked: 'click-program-start-step',
  ProgramAutomationBlueprintSelect: 'program-automation-blueprint-select',
  ProgramAutomationWizardNextClick: 'program-automation-wizard-next-click',
  PurchaseClicked: 'click-purchase',
  QuickProgramsLoad: 'quick-programs-load',
  SelectProgramStepClicked: 'click-select-program-step',
  SubmitProgramPromoCodeClicked: 'click-submit-program-promo-code',
  ViewContactClicked: 'click-view-contact',
  ViewOrderClicked: 'click-view-order',
  ViewProgramsClicked: 'click-view-programs',
  ClickAiCopy: 'click-ai-copy',
  ClickAiStylize: 'click-ai-stylize',
  ClickTeamObjectShare: 'click-team-object-share',
  ClickTeamObjectShareConfirm: 'click-team-object-share-confirm',
  ClickTeamObjectUnshare: 'click-team-object-unshare',
  ClickTeamObjectUnshareConfirm: 'click-team-object-unshare-confirm',
  ClickObjectDelete: 'click-object-delete',
  ClickObjectDeleteConfirm: 'click-object-delete-confirm',
  NotificationClick: 'click-notification',
  NotificationInboxOpen: 'click-notification-bell-open',
  NotificationInboxClose: 'click-notification-bell-close',
  ClickAutomationTableExpand: 'click-automation-table-expand',
  ClickAutomationTableCollapse: 'click-automation-table-collapse',
  ClickQuickstartFilter: 'click-quickstart-filter',
  ClickQuickAutomationButton: 'click-quick-automation-button',
  ClickQuickAutomationRule: 'click-quick-automation-rule',
  AdCreativeValidationError: 'ad-creative-validation-error',
  ClickInputSectionExpanded: 'click-input-section-expand',
  ClickInputSectionCollapsed: 'click-input-section-collapse',
  FacebookFetchPages: 'facebook-fetch-pages',
  FacebookLinkPageSuccess: 'facebook-link-page-success',
  FacebookLinkPageFailure: 'facebook-link-page-failure',
  KpiDataLoaded: 'kpi-data-loaded',
  ClickCloneProgram: 'click-clone-program',
  ClickCloneProgramConfirm: 'click-clone-program-confirm',
  ClickCloneProgramCancel: 'click-clone-program-cancel',
  UpdateLocale: 'update-locale',
  ClickSelectLocationsConfirm: 'click-select-locations-confirm',
  ClickSelectLocation: 'click-select-location',
  ClickMlpEditDefaults: 'click-mlp-edit-defaults',
  ClickMlpApplyDefaults: 'click-mlp-apply-defaults',
  ClickMlpModifyLocations: 'click-mlp-modify-locations',
  ClickMlpModifyLocation: 'click-mlp-modify-location',
  ClickMlpModifyLocationConfirm: 'click-mlp-modify-location-confirm',
  ClickMlpRestoreLocationToDefault: 'click-mlp-restore-location-to-default',
  ClickMlpShowAdPreview: 'click-mlp-show-ad-preview',
  ClickMlpRemoveLocation: 'click-mlp-remove-location',
  ViewMlpParent: 'view-mlp-parent',
  ClickDeleteDraft: 'click-delete-draft',
  ClickDeleteDraftConfirm: 'click-delete-draft-confirm',
  ClickDeleteDraftCancel: 'click-delete-draft-cancel',
  ClickViewDraft: 'click-view-draft',
  ClickSaveDraft: 'click-save-draft',
  ClickAiCopyApply: 'click-ai-copy-apply',
  ClickAiCopyRegenerate: 'click-ai-copy-regenerate',
  ClickAiCopyDislike: 'click-ai-copy-dislike',
  ClickEvocalizeAi: 'click-evocalize-ai'
} as const;

export type Event = (typeof Events)[keyof typeof Events];
export type EventMap = typeof Events;
