import { Box, Button, Typography } from '@mui/material';
import { t } from 'i18next';
import { Trans } from 'react-i18next';
import Instrumentation from 'src/instrumentation';
import useProgram from 'src/pages/Program/utils/useProgram';
import AiLoadingSpinner from './AiLoadingSpinner';

interface AiChatFooterProps {
  loading?: boolean;
  applyAllDisabled?: boolean;
}

const AiChatFooter = ({
  loading = false,
  applyAllDisabled = true
}: AiChatFooterProps) => {
  const { selectedBlueprint } = useProgram();

  // TODO: When we implement the apply button functionality, check the lift required to add `isEdited` to the event data
  const handleApplyAll = () => {
    Instrumentation.logEvent(Instrumentation.Events.ClickAiCopyApply, {
      input: 'All',
      productId: selectedBlueprint?.id,
      architectureId: selectedBlueprint?.architectureId
    });
  };
  return (
    <Box
      sx={{
        borderTop: theme => `1px solid ${theme.palette?.grey?.[300]}`,
        py: 1,
        px: 2,
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center'
      }}
    >
      {loading && (
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
          <AiLoadingSpinner />
          <Typography color="GrayText">
            <Trans i18nKey="aiSuggestion:chat.loading" />
            ...
          </Typography>
        </Box>
      )}
      <Button
        onClick={handleApplyAll}
        color="primary"
        sx={{ ml: 'auto' }}
        disabled={applyAllDisabled}
      >
        {t('aiSuggestion:chat.applyAllButton')}
      </Button>
    </Box>
  );
};

export default AiChatFooter;
