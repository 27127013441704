import { noop } from 'lodash';
import classnames from 'classnames';
import { red } from '@mui/material/colors';
import { Button } from '@mui/material';

import withStyles from '@mui/styles/withStyles';

import Instrumentation from 'src/instrumentation';
import { Trans } from 'react-i18next';
import { WrappedFieldProps } from 'redux-form';
import { InjectedStyledClasses } from 'src/common/Style';
import { AssetType } from 'src/generated/gql/graphql';
import { GALLERY_TYPE } from './constants';
import TileWrapper from './Tiles/TileWrapper';

const styles = () =>
  ({
    container: {
      width: '100%',
      minHeight: '56px' // input height
    },
    image: {
      width: '100%',
      '& img': {
        maxHeight: '100px'
      }
    },
    label: {
      display: 'flex',
      flexDirection: 'column'
    },
    error: {
      color: red[500],
      borderColor: red[500]
    }
  }) as const;

export interface GalleryModalProps extends WrappedFieldProps {
  onClick: () => void;
  selection: AssetType | AssetType[] | { type: AssetType };
  galleryType: string;
  name: string;
  disabled: boolean;
  isHookForm?: boolean;
}

const GalleryModalButton = (
  props: GalleryModalProps & InjectedStyledClasses<typeof styles>
) => {
  const {
    onClick: handleModalOpen,
    selection,
    classes,
    galleryType,
    meta: { error, touched },
    name,
    disabled,
    isHookForm
  } = props;
  const selectionType =
    galleryType === GALLERY_TYPE.media ? (selection as any)?.type : galleryType; // using galleryType for asset type is legacy

  const triggerLogEvent = () => {
    const selectionIsArray = Array.isArray(selection);
    if (typeof selection === 'string' || selectionIsArray) {
      const eventMeta = {
        hasContent: !!selection,
        contentCount: selectionIsArray ? selection.length : +!!selection
      };

      Instrumentation.logEvent(
        Instrumentation.Events.MediaLibrarySelectFromGallery,
        eventMeta
      );
    }
  };

  const inputInError = isHookForm ? error : error && touched;

  return (
    <div>
      <Button
        disabled={disabled}
        data-cy={`gallery-modal-button-${name.replace('.', '-')}`}
        color="primary"
        variant="outlined"
        className={classnames(classes.container, {
          [classes.error]: inputInError
        })}
        classes={{ label: classes.label } as any}
        onClick={() => {
          triggerLogEvent();
          handleModalOpen();
        }}
      >
        {selection && (
          <div className={classes.image}>
            <TileWrapper
              asset={selection}
              galleryType={galleryType}
              onClick={noop}
              type={selectionType}
              showThumbnail={galleryType === GALLERY_TYPE.video}
            />
          </div>
        )}
        {selection && galleryType === GALLERY_TYPE.video ? null : (
          <Trans i18nKey="gallery:button.label" />
        )}
      </Button>
    </div>
  );
};

export default withStyles(styles)(GalleryModalButton);
