import { t } from 'i18next';
import { useMemo, useCallback, useEffect } from 'react';
import { useMutation } from '@apollo/client';

import { Button, Paper, Typography } from '@mui/material';
import withStyles from '@mui/styles/withStyles';

import { flow } from 'lodash';
import { reduxForm } from 'redux-form';

import { DynamicForm } from 'src/components/ReduxForm';
import { getChannelAllocationWeightInputs } from './constants';
import { upsertChannelSpendAllocationConfig } from './queries';

const UPDATE_CHANNEL_SPEND_ALLOCATION_CONFIG_FORM =
  'updateChannelSpendAllocationConfigForm';

const pageText = () => ({
  title: t('adminChannelSpendAllocationConfigUpdater:title'),
  success: t('adminChannelSpendAllocationConfigUpdater:success'),
  configError: t('adminChannelSpendAllocationConfigUpdater:configError')
});

const styles = theme => ({
  paper: {
    marginTop: theme.spacing(2),
    padding: theme.spacing(2)
  }
});

const ChannelSpendAllocationConfigUpdater = props => {
  const { config, setCurrentConfig, classes, handleSubmit } = props;

  const text = useMemo(() => pageText(), []);

  const [upsertConfiguration, { data: upsertResult, loading, error }] =
    useMutation(upsertChannelSpendAllocationConfig);

  const upsertCallback = useCallback(
    data => {
      if (loading) {
        return;
      }

      upsertConfiguration({
        variables: {
          upsert: {
            poolTypeSlug: config.poolTypeSlug,
            enabled: data.enabled,
            organizationId: config.organizationId || null,
            projectId: config.projectId || null,
            rules: [
              {
                channelCode: 'fb',
                weight: data.fb
              },
              {
                channelCode: 'google',
                weight: data.google
              },
              {
                channelCode: 'tiktok',
                weight: data.tiktok
              }
            ]
          }
        }
      });
    },
    [loading, upsertConfiguration]
  );

  // If we updated the config, we need to update the state elsewhere
  useEffect(() => {
    if (!loading && upsertResult?.upsertChannelSpendAllocationConfig) {
      setCurrentConfig(upsertResult.upsertChannelSpendAllocationConfig);
    }
  }, [upsertResult, loading, setCurrentConfig]);

  return (
    <Paper className={classes.paper}>
      <Typography component="h6" variant="h6">
        {text.title}
      </Typography>
      <form autoComplete="false" onSubmit={handleSubmit(upsertCallback)}>
        {config?.rules && config.rules.length > 0 && (
          <DynamicForm
            inputs={getChannelAllocationWeightInputs(config.rules)}
          />
        )}
        <Button
          color="primary"
          type="submit"
          variant="outlined"
          disabled={loading}
        >
          Update configuration
        </Button>
        {!loading && error && <b> {text.configError}</b>}
        {!loading && !error && upsertResult && <b> {text.success}</b>}
      </form>
    </Paper>
  );
};

export default flow(
  reduxForm({
    form: UPDATE_CHANNEL_SPEND_ALLOCATION_CONFIG_FORM,
    enableReinitialize: true
  }),
  withStyles(styles)
)(ChannelSpendAllocationConfigUpdater);
