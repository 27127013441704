import { Button, Grid } from '@mui/material';
import { kebabCase } from 'lodash';

export interface ButtonConfig {
  label: string;
  onClick: () => void;
  disabled?: boolean;
}

interface ButtonInputMessageProps {
  buttons: ButtonConfig[];
}

const ButtonInputMessage = ({ buttons }: ButtonInputMessageProps) => {
  return (
    <Grid container data-cy="ai-chat-button-inputs" spacing={1}>
      {buttons.map(button => {
        return (
          <Grid item key={button.label} xs={6}>
            <Button
              data-cy={`ai-chat-button-${kebabCase(button.label)}`}
              variant="outlined"
              onClick={button.onClick}
              disabled={button.disabled}
              sx={{ width: '100%' }}
            >
              {button.label}
            </Button>
          </Grid>
        );
      })}
    </Grid>
  );
};

export default ButtonInputMessage;
