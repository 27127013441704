import React from 'react';
import { useForm, FormProvider, Mode } from 'react-hook-form';

type HookFormProps = {
  initialValues: { [key: string]: any };
  mode?: Mode;
  children: React.ReactElement;
};
const HookForm = (props: HookFormProps) => {
  const { children, initialValues, mode = 'onChange' } = props;

  const formMethods = useForm({
    defaultValues: initialValues,
    mode
  });

  return <FormProvider {...formMethods}>{children}</FormProvider>;
};

export default HookForm;
