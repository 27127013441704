import { ContentPaste, Check } from '@mui/icons-material';

const ClipboardButtonIcon = props => {
  const { isCopied } = props;
  return isCopied ? (
    <Check color="secondary" />
  ) : (
    <ContentPaste color="secondary" />
  );
};

export default ClipboardButtonIcon;
