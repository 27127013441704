import React, { useState } from 'react';
import { isFunction } from 'lodash';
import { UseFormGetValues, FieldValues } from 'react-hook-form';
import { useMutation } from '@apollo/client';
import { LoadingButton } from '@mui/lab';
import { useSnackbar } from 'notistack';
import { useHistory } from 'react-router-dom';

import Instrumentation from 'src/instrumentation';
import useProgram from 'src/pages/Program/utils/useProgram';
import { useArchitecture } from 'src/pages/Architecture/ArchitectureProvider';
import {
  extractProgramMutationParams,
  createMlpMutiationParams,
  formatAutomatedProgramsMutationParams
} from 'src/pages/Program/utils/submitHandlers/utils';
import {
  useExperiment,
  EXPERIMENT_NAMES
} from 'src/experiments/ExperimentContextProvider';
import { useGlobalContext } from 'src/GlobalContextProvider';
import {
  saveProgramDraft,
  saveMultiLocationProgramDraft,
  saveAutomationDraft
} from 'src/pages/Program/mutations';
import {
  scheduleTypes,
  getProgramTrackingType
} from 'src/pages/Program/Constants';
import { paths } from 'src/routes/paths';
import { generateLinkPath } from 'src/routes/RouteUtil';

export const DRAFT_TYPES = {
  PROGRAM: 'PROGRAM',
  MLP: 'MLP',
  AUTOMATION: 'AUTOMATION'
} as const;

type SaveDraftProps = {
  formValues?: any;
  getFormValues?: UseFormGetValues<FieldValues>;
  selectedBusinessObjects: string[];
  type?: (typeof DRAFT_TYPES)[keyof typeof DRAFT_TYPES];
};

const SaveDraftButton = ({
  formValues,
  getFormValues,
  selectedBusinessObjects,
  type = DRAFT_TYPES.PROGRAM
}: SaveDraftProps) => {
  const [submitting, setSubmitting] = useState(false);
  const {
    selectedBlueprint,
    setCurrentDraftId,
    currentDraftId,
    isAutomatedEdit,
    setDisableNavigationBlocker,
    trackingData,
    programStepper
  } = useProgram();
  const history = useHistory();

  const globalContext = useGlobalContext();
  const userMetadataFields = globalContext?.me?.metadata?.fields;

  const architecture = useArchitecture();

  const { experimentsLoaded, value } = useExperiment(
    EXPERIMENT_NAMES.PROGRAM_DRAFTS_V1
  );

  const { enqueueSnackbar } = useSnackbar();
  const [saveProgramDraftMutation] = useMutation(saveProgramDraft);
  const [saveMultiLocationProgramDraftMutation] = useMutation(
    saveMultiLocationProgramDraft
  );
  const [saveAutomationDraftMutation] = useMutation(saveAutomationDraft);

  if (!experimentsLoaded || !value || isAutomatedEdit) {
    return null;
  }

  const handleSaveDraft = async (data: any) => {
    setSubmitting(true);

    const mutationParams = extractProgramMutationParams({
      data,
      architecture,
      selectedBlueprint,
      selectedBusinessObjects,
      userMetadataFields
    });
    const draftParams = {
      ...(currentDraftId && { programDraftId: currentDraftId }),
      name: mutationParams?.name,
      offerId: mutationParams?.offerId,
      priceAmount: mutationParams?.paymentAmount,
      tierId: mutationParams?.tierId,
      catalogId: mutationParams?.catalogId,
      catalogFilter: mutationParams?.catalogFilter,
      variableValues: mutationParams?.variableValues
    };

    let draftId = currentDraftId;
    let navigateToLink = generateLinkPath(paths.programs.base);

    try {
      let isSupervisable = false;
      let isMultiLocation = false;
      if (type === DRAFT_TYPES.PROGRAM) {
        const result = await saveProgramDraftMutation({
          variables: {
            input: draftParams
          }
        });
        draftId = result?.data?.saveProgramDraft?.id;

        isMultiLocation = false;
        isSupervisable = false;
      }

      if (type === DRAFT_TYPES.MLP) {
        const mlpParamsRaw = createMlpMutiationParams({
          data,
          isOneTimePurchase:
            data?.spendStep?.scheduleType === scheduleTypes.purchase.value,
          isSubscription:
            data?.spendStep?.scheduleType === scheduleTypes.subscription.value,
          mutationParams
        });

        const result = await saveMultiLocationProgramDraftMutation({
          variables: {
            input: {
              ...(currentDraftId && { programDraftId: currentDraftId }),
              name: mlpParamsRaw?.name,
              offerId: mlpParamsRaw?.offerId,
              priceAmount: mlpParamsRaw?.defaultOrderAmount,
              tierId: mlpParamsRaw?.tierId,
              catalogId: mlpParamsRaw?.catalogId,
              catalogFilter: mlpParamsRaw?.catalogFilter,
              variableValues: mlpParamsRaw?.variableValues,
              locations: (mlpParamsRaw?.locations || []).map(location => ({
                locationId: location?.locationId,
                ...(location?.amountOverride && {
                  priceAmountOverride: location?.amountOverride
                }),
                ...(location?.tierIdOverride && {
                  tierIdOverride: location?.tierIdOverride
                }),
                ...(location?.variableValuesOverride && {
                  variableValuesOverride: location?.variableValuesOverride
                })
              }))
            }
          } as any
        });
        draftId = result?.data?.saveMultiLocationProgramDraft?.id;

        isMultiLocation = true;
        isSupervisable = false;
      }

      if (type === DRAFT_TYPES.AUTOMATION) {
        const automationParamsRaw = formatAutomatedProgramsMutationParams({
          data,
          architecture,
          selectedBlueprint,
          isEdit: false
        });
        const result = await saveAutomationDraftMutation({
          variables: {
            input: {
              ...(currentDraftId && { programDraftId: currentDraftId }),
              name: automationParamsRaw?.name,
              offerId: automationParamsRaw?.offerId,
              priceAmount: automationParamsRaw?.paymentAmount,
              tierId: automationParamsRaw?.tierId,
              catalogId: automationParamsRaw?.catalogId,
              catalogFilter: automationParamsRaw?.catalogFilter,
              variableValues: automationParamsRaw?.variableValues,
              childOrderDurationDays:
                automationParamsRaw?.childOrderDurationDays,
              childOrderNameTemplate:
                automationParamsRaw?.childOrderNameTemplate
            }
          }
        } as any);
        draftId = result?.data?.saveAutomationDraft?.id;
        navigateToLink = generateLinkPath(paths.automations.base);

        isMultiLocation = false;
        isSupervisable = true;
      }

      Instrumentation.logEvent(Instrumentation.Events.ClickSaveDraft, {
        type: getProgramTrackingType({
          isSupervisable,
          isMultiLocation
        }),
        draftId,
        step: programStepper?.currentStep,
        ...trackingData
      });

      // once we save a draft we want to disable the navigation blocker
      setDisableNavigationBlocker(true);
    } finally {
      setSubmitting(false);
    }

    // we always want to edit the current draft id
    setCurrentDraftId(draftId);

    history.push(navigateToLink);
  };

  return (
    <LoadingButton
      type="button"
      variant="outlined"
      color="primary"
      loading={submitting}
      disabled={submitting}
      data-cy="save-draft-button"
      onClick={() => {
        let values = formValues || {};
        if (getFormValues && isFunction(getFormValues)) {
          values = getFormValues();
        }
        handleSaveDraft(values)
          .then(() => {
            enqueueSnackbar('Draft saved successfully', {
              variant: 'success',
              persist: false
            });
          })
          .catch(() => {
            enqueueSnackbar('Error saving draft', {
              variant: 'error',
              persist: false
            });
          });
      }}
    >
      Save as Draft
    </LoadingButton>
  );
};

export default SaveDraftButton;
