import { useState } from 'react';
import { isEmpty } from 'lodash';
import { t } from 'i18next';
import { useMutation } from '@apollo/client';
import { useFormContext } from 'react-hook-form';

import { Grid, Button, Tooltip, IconButton, Typography } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { Edit as EditIcon } from '@mui/icons-material';
import { useSnackbar } from 'notistack';

import { useGlobalContext } from 'src/GlobalContextProvider';
import { dayjs, formatDate } from 'src/common/dates';

import Instrumentation from 'src/instrumentation';
import Logger from 'src/common/Logger';
import { businessObjectsFromOrder } from 'src/common/businessObjects';
import {
  formatBlueprints,
  hasCatalog,
  contentSelectable,
  getOneOfEachChannelKeys
} from 'src/common/blueprints';
import { contentColumnsFromArchitecture } from 'src/common/dynamicUserInputs';
import { useInterval } from 'src/hooks/useInterval';
import FooterValidationErrors from 'src/pages/Program/FooterValidationErrors';

import ProgramDynamicUserInputs from 'src/pages/Program/ProgramDynamicUserInputs';
import Modal from 'src/components/Modal';

import AdPreview from 'src/components/AdPreview';
import SpendSelector, { useMinSpendState } from 'src/components/SpendSelector';
import ScheduleSelector from 'src/components/ScheduleSelector';
import { PROGRAM_FORM_SECTION_SPEND_NAME } from 'src/pages/Program/Constants';
import useCreativeValidationsHandler from 'src/pages/Program/useCreativeValidationsHandler';
import { OFFER_TYPES } from 'src/common/offers';
import ProgramName from 'src/pages/Program/ProgramName/ProgramName';

import {
  EDIT_PROGRAM_FORM_NAME,
  ORDER_PROCESSING_STATUS,
  SUBSCRIPTION_TIER_CHANGE_METHODS,
  mostRecentBudgetAdjustmentOutcomes
} from '../Constants';
import { editOrder as editOrderMutation } from '../mutations';

import {
  getProgramNameTranslated,
  getProgramNameLabel
} from '../../Program/ProgramName/ProgramNameUtil';
import {
  canMakeScheduleUpdates,
  getProgramStatuses,
  getEditPermissionsFromOrder
} from '../helpers';
import { getEditButtonStatus } from './helpers';

const EditProgram = props => {
  const {
    architecture,
    refetch,
    order,
    disabled = false,
    hasWritePermission,
    contentName
  } = props;
  const { enqueueSnackbar } = useSnackbar();
  const [editOrder] = useMutation(editOrderMutation);
  const globalContext = useGlobalContext();
  const userMetadataFields = globalContext?.me?.metadata?.fields;

  const {
    reset,
    submitting,
    handleSubmit,
    formState: { errors: formErrors },
    watch
  } = useFormContext();

  const {
    orderItem: { status },
    billingDetails,
    offer: { subscriptionTierChangeMethod }
  } = order;

  const { startDate, endDate, amount, renewsOn } = billingDetails;

  const { isSubscription } = getProgramStatuses(order);

  const {
    canEditSubscriptionTier,
    canEditPurchaseOrderAmount,
    canEditPurchaseSchedule
  } = getEditPermissionsFromOrder(order);

  const formValues = watch();
  const hasFormErrors = !isEmpty(formErrors);
  const programStartDate = startDate;
  const programEndDate = endDate;
  const orderStatus = status;
  const currentEndDate = dayjs(endDate);
  const currentStartDate = dayjs(startDate);
  const currentSpend = amount;
  const isScheduledSubscriptionTierChange =
    subscriptionTierChangeMethod === SUBSCRIPTION_TIER_CHANGE_METHODS.scheduled;

  const canEditBudgetOrSchedule =
    canEditPurchaseOrderAmount ||
    canEditPurchaseSchedule ||
    canEditSubscriptionTier;

  const mostRecentBudgetAdjustment =
    order?.billingDetails?.mostRecentBudgetAdjustment;

  const {
    handleAdContentChannelValidation,
    isValidatingCreative,
    isPollingPreview,
    setIsPollingPreview,
    setIsValidatingCreative,
    creativeValidationErrors,
    clearUpdatedInputCreativeErrors,
    inputValidators: channelInputValidators
  } = useCreativeValidationsHandler({
    product: order?.orderItem?.product,
    isAutomated: false
  });

  const [modal, setModal] = useState(null);
  const {
    minSpendLoading,
    setMinSpendLoading,
    hasMinSpendError,
    setHasMinSpendError
  } = useMinSpendState();

  const [isUpdating, setIsUpdating] = useState(
    mostRecentBudgetAdjustment?.outcome ===
      mostRecentBudgetAdjustmentOutcomes.pending
  );
  const [count, setCount] = useState(0);

  const checkForUpdates = () => {
    if (
      mostRecentBudgetAdjustment?.outcome ===
        mostRecentBudgetAdjustmentOutcomes.success &&
      count > 3 && // ensure we are updated. if a successful update has already happed the outcome starts out as a success
      order?.executionStatus?.outcome ===
        mostRecentBudgetAdjustmentOutcomes.success
    ) {
      setIsUpdating(false);
      setCount(0);
      return;
    }

    // stop after 30 tries / seconds
    if (count > 30) {
      setIsUpdating(false);
      setCount(0);
      return;
    }

    if (
      mostRecentBudgetAdjustment?.outcome ===
      mostRecentBudgetAdjustmentOutcomes.error
    ) {
      enqueueSnackbar(t('programPerf:editModal.editError'), {
        variant: 'error'
      });
      Logger.error(
        `mostRecentBudgetAdjustment error: ${mostRecentBudgetAdjustment.errorMessage}`
      );
      setIsUpdating(false);
      setCount(0);
      return;
    }

    refetch();
    setCount(count + 1);
  };

  useInterval(checkForUpdates, isUpdating ? 1000 : null);

  const handleClose = () => {
    setModal(null);
  };

  const handleOpen = () => {
    Instrumentation.logEvent(Instrumentation.Events.EditProgramClicked, {
      orderId: order?.orderItem?.id,
      architectureId: order?.architectureId,
      productId: order?.orderItem?.product?.id,
      paymentAmount: order?.billingDetails?.amount,
      channel: getOneOfEachChannelKeys(order?.channels?.[0]?.channels)
    });
    setModal(true);
  };

  const contentColumns = contentColumnsFromArchitecture(architecture);
  const product = {
    ...order?.orderItem?.product,
    offers: [{ ...order?.offer, isActive: true }]
  };
  const blueprint = formatBlueprints([product] || []);
  const selectedBlueprint = blueprint?.[0];

  const dynamicUserInputSections = blueprint[0].inputSections
    // disable editing legacy audiences
    .map(section => {
      return {
        ...section,
        inputFields: section.inputFields.map(input => {
          if (input.displayMethodId === 'facebook_audience_id') {
            return { ...input, isEditable: false, disabled: true };
          }
          // when input is not editable we want it in read only mode
          if (!input.isEditable) {
            return {
              ...input,
              readOnly: true
            };
          }
          return input;
        })
      };
    });

  const paymentPlans = selectedBlueprint?.paymentPlans;

  const businessObjects = businessObjectsFromOrder(order);
  const hasBusinessObjects = !isEmpty(businessObjects);

  const previewData = {
    blueprint: selectedBlueprint,
    dynamicUserInputs: formValues?.dynamicUserInputs || {},
    businessObjects
  };

  const architectureHasCatalog = hasCatalog(architecture, selectedBlueprint);
  const displayNameTemplate = architecture?.catalog?.displayNameTemplate;
  const isContentSelectable = contentSelectable(architecture, product);
  const orderItemId = order?.orderItem?.id;
  const offerId = order?.orderItem?.offerId;
  const orderIsPending = orderStatus === ORDER_PROCESSING_STATUS.pending;

  const { startDateEditable, endDateEditable } = canMakeScheduleUpdates({
    isEdit: true,
    orderStatus,
    programStartDate,
    programEndDate,
    isSubscription
  });

  const handleSave = data => {
    const businessObjects = businessObjectsFromOrder(order);

    if (hasFormErrors) {
      enqueueSnackbar(t('programPerf:editModal.editInvalid'), {
        variant: 'error'
      });
      return;
    }

    const programName = getProgramNameTranslated(
      data?.spendStep?.programName,
      product,
      businessObjects,
      displayNameTemplate,
      userMetadataFields
    );

    return editOrder({
      variables: {
        orderItemId,
        programName,
        variableValues: data?.dynamicUserInputs,
        ...(canEditPurchaseSchedule &&
          startDateEditable && {
            scheduleStartEpochSeconds: dayjs(data?.spendStep?.startDate).unix()
          }),
        ...(canEditPurchaseSchedule &&
          endDateEditable && {
            scheduleEndEpochSeconds: dayjs(data?.spendStep?.endDate).unix()
          }),
        ...((canEditSubscriptionTier || canEditPurchaseOrderAmount) && {
          updatedBudgetAmount:
            OFFER_TYPES.subscription === data?.spendStep?.scheduleType
              ? data?.spendStep?.subscriptionSpend
              : data?.spendStep?.oneTimeSpend
        }),
        ...(canEditSubscriptionTier && {
          updatedTierId: data?.spendStep?.subscription?.split('tier:')[1]
        })
      }
    })
      .then(() => {
        enqueueSnackbar(t('programPerf:editModal.successfulUpdate'), {
          variant: 'success'
        });
        refetch();
        Instrumentation.logEvent(
          Instrumentation.Events.EditProgramActionsClicked,
          { action: 'save' }
        );
        handleClose();
        setIsUpdating(true);
        return null;
      })
      .catch(() => {
        enqueueSnackbar(t('programPerf:editModal.editError'), {
          variant: 'error'
        });
        handleClose();
        return null;
      });
  };

  const editButtonStatus = getEditButtonStatus({
    disabled,
    hasOrder: !!order,
    architectureHasCatalog,
    hasBusinessObjects,
    hasWritePermission
  });

  return (
    <>
      <Tooltip title={editButtonStatus.tooltip}>
        <span>
          <IconButton
            color="primary"
            onClick={handleOpen}
            data-cy="edit-program-button"
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              gap: 0.5
            }}
            disabled={editButtonStatus.isDisabled}
          >
            <EditIcon fontSize="small" />
            <Typography component="span" variant="body2">
              {t('programPerf:editModal.edit')}
            </Typography>
          </IconButton>
        </span>
      </Tooltip>
      {modal && (
        <Modal
          open={!!modal}
          headerText={t('programPerf:editModal.title')}
          fullWidth
          maxWidth="lg"
          onClose={(e, reason) => {
            if (reason === 'backdropClick') {
              return;
            }
            handleClose();
            reset();
          }}
          FooterComponent={() => (
            <>
              <Button
                onClick={() => {
                  // putting Tracker here b/c handleClose is called from multiple places
                  Instrumentation.logEvent(
                    Instrumentation.Events.EditProgramActionsClicked,
                    {
                      action: 'cancel'
                    }
                  );
                  handleClose();
                  reset();
                }}
              >
                {t('programPerf:editModal.cancel')}
              </Button>
              <LoadingButton
                variant="contained"
                onClick={handleSubmit(handleSave)}
                disabled={
                  submitting ||
                  isValidatingCreative ||
                  creativeValidationErrors ||
                  minSpendLoading ||
                  hasMinSpendError
                }
                loading={submitting || isValidatingCreative || minSpendLoading}
              >
                {t('programPerf:editModal.save')}
              </LoadingButton>
            </>
          )}
        >
          <Grid container>
            <Grid item xs={12} sm={6}>
              <form>
                <ProgramName
                  contentColumns={contentColumns}
                  businessObjects={previewData.businessObjects}
                  displayNameTemplate={displayNameTemplate}
                  selectedBlueprint={product}
                  formName={EDIT_PROGRAM_FORM_NAME}
                  label={getProgramNameLabel()}
                  formValues={formValues}
                />

                <ProgramDynamicUserInputs
                  blueprint={blueprint[0]}
                  dynamicUserInputSections={dynamicUserInputSections}
                  contentColumns={contentColumns}
                  businessObjects={previewData.businessObjects}
                  formName={EDIT_PROGRAM_FORM_NAME}
                  isContentSelectable={isContentSelectable}
                  contentName={contentName}
                  channelInputValidators={channelInputValidators}
                  isHookForm
                />
                {canEditBudgetOrSchedule && (
                  <>
                    {canEditPurchaseSchedule && (
                      <ScheduleSelector
                        paymentPlans={paymentPlans}
                        selectedBlueprint={selectedBlueprint}
                        programStartDate={programStartDate}
                        programEndDate={programEndDate}
                        orderStatus={orderStatus}
                        isEdit
                        orderIsPending={orderIsPending}
                        formSectionName={PROGRAM_FORM_SECTION_SPEND_NAME}
                      />
                    )}
                    {(canEditPurchaseOrderAmount ||
                      canEditSubscriptionTier) && (
                      <SpendSelector
                        architecture={architecture}
                        paymentPlans={paymentPlans}
                        selectedBlueprint={selectedBlueprint}
                        isAutomated={false}
                        isEdit
                        orderItemId={orderItemId}
                        offerId={offerId}
                        currentEndDate={currentEndDate}
                        currentStartDate={currentStartDate}
                        currentSpend={currentSpend}
                        orderIsPending={orderIsPending}
                        // formSectionName={formSectionName}
                        setMinSpendLoading={setMinSpendLoading}
                        setHasMinSpendError={setHasMinSpendError}
                        {...(isScheduledSubscriptionTierChange && {
                          subscriptionTierChangeRenewalDate: formatDate({
                            date: renewsOn,
                            format: 'MM/DD/YYYY'
                          })
                        })}
                        formSectionName={PROGRAM_FORM_SECTION_SPEND_NAME}
                      />
                    )}
                  </>
                )}
              </form>
            </Grid>
            <Grid
              item
              xs={12}
              sm={6}
              sx={theme => ({
                pl: 2,

                [theme.breakpoints.down('sm')]: {
                  pl: 0
                }
              })}
            >
              <AdPreview
                architecture={architecture}
                previewData={previewData}
                handleAdContentChannelValidation={
                  handleAdContentChannelValidation
                }
                setIsPollingPreview={setIsPollingPreview}
                isPollingPreview={isPollingPreview}
                setIsValidatingCreative={setIsValidatingCreative}
                clearUpdatedInputCreativeErrors={
                  clearUpdatedInputCreativeErrors
                }
              />
            </Grid>
            {!isEmpty(creativeValidationErrors) && (
              <Grid item xs={12}>
                <FooterValidationErrors
                  channelValidationErrors={creativeValidationErrors}
                />
              </Grid>
            )}
          </Grid>
        </Modal>
      )}
    </>
  );
};

export default EditProgram;
