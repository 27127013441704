import { FormHelperText } from '@mui/material';

/**
 * Renders the error footer for redux form components in a consistent way.
 */
export const ErrorFooter = ({ touched, error, variant, isHookForm }) => {
  const inputInError = isHookForm ? error : error && touched;

  if (!inputInError) {
    return null;
  }

  return (
    <FormHelperText sx={{ marginLeft: '14px' }} error variant={variant}>
      {error}
    </FormHelperText>
  );
};
