import { useState, useMemo } from 'react';
import { flow, clone, isNil } from 'lodash';
import { Field, getFormValues } from 'redux-form';
import { graphql } from '@apollo/client/react/hoc';
import { connect } from 'react-redux';
import classnames from 'classnames';

import { IconButton, Grid, Button } from '@mui/material';

import withStyles from '@mui/styles/withStyles';

import DeleteIcon from '@mui/icons-material/DeleteForever';
import SettingsIcon from '@mui/icons-material/Settings';
import ArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import ArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import AddIcon from '@mui/icons-material/Add';

import RenderTextField from 'src/components/ReduxForm/RenderTextField';
import { DynamicForm } from 'src/components/ReduxForm';
import Loading from 'src/components/Loading';
import {
  getInitialValuesFromInputsConfig,
  configureInputs
} from 'src/components/ReduxForm/helpers';

import RenderDisplayParameters from 'src/pages/Admin/common/ReduxForm/RenderDisplayParameters';
import { getContentSetFieldDisplayMethods } from '../queries';
import {
  BLUEPRINT_BUILDER_FORM_NAME,
  getUserInputMetaDataInputs
} from '../Constants';

const styles = theme => ({
  inputContainer: {
    alignItems: 'center',
    display: 'flex',
    paddingRight: theme.spacing(1),
    paddingLeft: theme.spacing(1)
  },
  placeHolderInput: {
    width: '100%'
  },
  inputIcon: {
    marginLeft: theme.spacing(1)
  },
  addInputButtonContainer: {
    padding: theme.spacing(1),
    width: '100%'
  },
  addInputButton: {
    width: '100%'
  },
  arrowInputContainer: {
    display: 'flex',
    flexDirection: 'column',
    marginLeft: theme.spacing(1)
  },
  selectedInput: {
    borderStyle: 'solid',
    borderWidth: '1px 2px 1px 1px',
    borderColor: `${theme.palette.grey[500]} ${theme.palette.grey[50]} ${theme.palette.grey[500]} ${theme.palette.grey[500]}`,
    background: theme.palette.grey[50],
    borderRadius: '5px 0px 0px 5px',
    marginRight: '-1px',
    position: 'relative'
  },
  parameterContainer: {
    borderStyle: 'solid',
    borderWidth: '1px 1px 1px 1px',
    borderColor: theme.palette.grey[500],
    padding: theme.spacing(0, 2, 2, 0),
    borderRadius: '5px',
    background: theme.palette.grey[50],
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(2.5)
  }
});

const RenderMoveInputButtons = props => {
  const { classes, onMoveUp, onMoveDown, total, currentIndex } = props;
  const disableUp = total === 1 || currentIndex === 0;
  const disableDown = total === 1 || total - 1 === currentIndex;

  return (
    <span className={classes.arrowInputContainer}>
      <IconButton
        disabled={disableUp}
        onClick={() => {
          onMoveUp(currentIndex, 'up');
        }}
        size="small"
      >
        <ArrowUpIcon />
      </IconButton>
      <IconButton
        disabled={disableDown}
        onClick={() => {
          onMoveDown(currentIndex, 'down');
        }}
        size="small"
      >
        <ArrowDownIcon />
      </IconButton>
    </span>
  );
};

const newInput = getInitialValuesFromInputsConfig(getUserInputMetaDataInputs());

const RenderSectionInputs = props => {
  const {
    classes,
    fields,
    variableNames,
    contentSetFieldDisplayMethods: {
      loading,
      contentSetFieldDisplayMethods = []
    }
  } = props;
  const [selectedFieldName, setSelectedFieldName] = useState(null);

  const move = (currentIndex, direction) => {
    if (
      (direction === 'up' && currentIndex === 0) ||
      (direction === 'down' && currentIndex === fields.length - 1)
    ) {
      return;
    }

    const nextIndex = direction === 'up' ? currentIndex - 1 : currentIndex + 1;

    // close panel
    setSelectedFieldName(null);
    // move field
    fields.move(currentIndex, nextIndex);
  };

  const userInputMetaDataInputs = getUserInputMetaDataInputs();

  const updatedUserInputMetaDataInputsUpdated = useMemo(() => {
    return configureInputs({
      inputs: userInputMetaDataInputs,
      enumInputs: {
        variableName: 'variableNames',
        displayMethod: 'displayMethods'
      },
      enumerationValues: {
        variableNames,
        displayMethods: contentSetFieldDisplayMethods.map(id => ({
          key: id.id,
          name: id.name
        }))
      }
    });
  }, [userInputMetaDataInputs, variableNames]);

  const inputsToRender = clone(updatedUserInputMetaDataInputsUpdated).map(
    input => {
      const updatedInput = clone(input);
      updatedInput.name = `${selectedFieldName}.${input.name}`;

      return updatedInput;
    }
  );

  const addNewInput = () => {
    fields.push(newInput);
  };

  if (loading) {
    return <Loading />;
  }

  return (
    <Grid container spacing={3}>
      <Grid item xs={12} md={selectedFieldName ? 6 : 12}>
        {fields.map((field, index) => {
          return (
            <div
              key={field}
              className={classnames(classes.inputContainer, {
                [classes.selectedInput]: selectedFieldName === field
              })}
            >
              <Field
                className={classes.placeHolderInput}
                component={RenderTextField}
                disabled
                name={`${field}.displayName`}
              />

              <RenderMoveInputButtons
                classes={classes}
                onMoveUp={move}
                onMoveDown={move}
                total={fields.length}
                currentIndex={index}
              />

              <span className={classes.inputIcon}>
                <IconButton
                  onClick={() =>
                    selectedFieldName === field
                      ? setSelectedFieldName(null)
                      : setSelectedFieldName(field)
                  }
                  size="small"
                >
                  <SettingsIcon />
                </IconButton>
              </span>
              <span className={classes.inputIcon}>
                <IconButton onClick={() => fields.remove(index)} size="small">
                  <DeleteIcon />
                </IconButton>
              </span>
            </div>
          );
        })}
        <div className={classes.addInputButtonContainer}>
          <Button
            className={classes.addInputButton}
            startIcon={<AddIcon />}
            variant="outlined"
            onClick={addNewInput}
          >
            Add User Input
          </Button>
        </div>
      </Grid>

      {selectedFieldName && (
        <Grid item xs={12} md={6} className={classes.parameterContainer}>
          <DynamicForm inputs={inputsToRender} />
          <Field
            component={RenderDisplayParameters}
            name={`${selectedFieldName}.displayParameters.inputData`}
            selectedFieldName={selectedFieldName}
          />
        </Grid>
      )}
    </Grid>
  );
};

const mapStateToProps = state => {
  // get blueprint variables from the form state
  const variableNamesFiltered = (
    getFormValues(BLUEPRINT_BUILDER_FORM_NAME)(state)?.document?.blueprint
      ?.variables || []
  )
    .map(val => ({ name: val.friendlyName, key: val.name }))
    .filter(item => {
      // filter out ones that are null so we don't break the select with null values when creating enums
      if (isNil(item.name) || isNil(item.key)) {
        return false;
      }
      return true;
    });

  return {
    variableNames: variableNamesFiltered
  };
};

export default flow(
  graphql(getContentSetFieldDisplayMethods, {
    name: 'contentSetFieldDisplayMethods',
    options: () => ({
      notifyOnNetworkStatusChange: true,
      fetchPolicy: 'no-cache'
    })
  }),
  connect(mapStateToProps),
  withStyles(styles)
)(RenderSectionInputs);
