import { useEffect, useRef, useState } from 'react';
import { Box, useTheme } from '@mui/system';
import {
  filter,
  find,
  forEach,
  includes,
  map,
  omitBy,
  reduce,
  uniq
} from 'lodash';
import { Trans } from 'react-i18next';
import { Button } from '@mui/material';
import { connect } from 'react-redux';
import { t } from 'i18next';
import { FormProvider, useForm } from 'react-hook-form';
import { useLazyQuery } from '@apollo/client';

import { showBusinessObjectSelectorModal } from 'src/components/BusinessObjectSelector/actions';
import { useArchitecture } from 'src/pages/Architecture/ArchitectureProvider';
import { DRAWER_FULL_SCREEN_BREAKPOINT } from 'src/pages/Program/ProgramPreviewDrawer/constants';
import useProgram from 'src/pages/Program/utils/useProgram';

import AiChatFloatingButton from './AiChatFloatingButton';
import {
  AiChatMessageType,
  aiChatMessageTypes,
  AiChatType,
  aiChatTypes,
  aiChatWindowDimensions
} from './constants';
import AiChatHeader from './AiChatHeader';
import AiChatMessage from './AiChatMessage';
import AiChatFooter from './AiChatFooter';
import { InputField } from './helpers';
import { logClickAiCopy } from './amplitudeEventLoggers';
import { getAiTextSuggestions as getAiTextSuggestionsQuery } from './queries';
import useHandleError from './useHandleError';

interface SharedInputProps {
  blueprint: Record<string, any>;
  businessObjects: Record<string, any>[];
  formName: string;
  isContentSelectable: boolean;
  contentName: string;
  contentColumns: Record<string, any>[];
}

interface AiChatProps {
  showButton?: boolean;
  type: AiChatType;
  inputs: InputField[];
  sharedInputProps: SharedInputProps;
  showBusinessObjectSelectorModal: () => void;
}

const AiChat = ({
  type = aiChatTypes.copywriter,
  inputs = [],
  sharedInputProps,
  showBusinessObjectSelectorModal
}: AiChatProps) => {
  const methods = useForm();
  const formValues = methods.watch();
  const selectedInputValues = omitBy(formValues, value => !value);
  const [inputsLoading, setInputsLoading] = useState<string[]>([]);
  const [isThinking, setIsThinking] = useState(false);

  const architecture = useArchitecture();

  const {
    trackingData: { productId },
    aiChatContext: { aiChatOpen, toggleAiChatWindow },
    showContentSelector: requiresSelectableContent
  } = useProgram();

  const businessObjectId = sharedInputProps?.businessObjects?.[0]?.id;
  const contentSelectionMissing =
    !businessObjectId && requiresSelectableContent;

  const [getAiTextSuggestions, { loading, error }] = useLazyQuery(
    getAiTextSuggestionsQuery
  );

  const handleError = useHandleError();

  useEffect(() => {
    if (error) {
      handleError(error);
    }
  }, [error]);

  const messagesEndRef = useRef<HTMLDivElement>(null);

  const buttons = inputs.map(input => {
    return {
      label: input.displayName,
      onClick: async () => {
        logClickAiCopy(input.displayName);

        setInputsLoading(prev => uniq([...prev, input.blueprintVariableId]));
        // TODO will need to test against contentless BP
        // TODO check to see if BP requires content
        // TODO ad UI for when user has not selected business object but BP requires content
        const result = await getAiTextSuggestions({
          variables: {
            input: {
              fields: [{ blueprintVariableId: input.blueprintVariableId }],
              catalogId: architecture.catalog?.id || '',
              catalogFilter: {
                id: {
                  in: businessObjectId ? [businessObjectId] : []
                }
              },
              productId
            }
          }
        }).catch(e => {
          handleError(e);
        });

        const generatedSuggestion =
          result?.data?.aiTextSuggestions?.fields?.[0]?.suggestions?.[0];

        if (generatedSuggestion) {
          methods.setValue(input.blueprintVariableId, {
            suggestions: [{ text: generatedSuggestion }]
          });
        }

        setInputsLoading(prev =>
          prev.filter(id => id !== input.blueprintVariableId)
        );
      },
      disabled:
        formValues?.[input.blueprintVariableId]?.suggestions?.length > 0 ||
        inputsLoading.includes(input.blueprintVariableId)
    };
  });

  const adCopySuggestionMessages = reduce(
    formValues,
    (result, suggestionData, blueprintVariableId) => {
      if (suggestionData) {
        const input = find(inputs, { blueprintVariableId });

        if (input) {
          result.push({
            type: aiChatMessageTypes.adCopySuggestion,
            input
          });
        }
      }

      return result;
    },
    [] as {
      type: AiChatMessageType;
      input: InputField;
    }[]
  );

  interface AiChatMessageConfig {
    type: AiChatMessageType;
    text?: string | JSX.Element;
    buttons?: { label: string; onClick: () => void; disabled?: boolean }[];
    input?: InputField;
    setIsThinking?: (isThinking: boolean) => void;
  }

  const { contentName } = sharedInputProps;

  const messages: AiChatMessageConfig[] = [
    {
      type: aiChatMessageTypes.text,
      text: contentSelectionMissing ? (
        <Trans
          i18nKey="aiSuggestion:chat.contentSelectionMissingMessage"
          values={{ contentName: contentName.toLocaleLowerCase() }}
          components={[
            <Button
              onClick={showBusinessObjectSelectorModal}
              sx={{
                fontSize: 'inherit',
                backgroundColor: 'transparent',
                color: 'primary.main',
                textTransform: 'none',
                padding: 0,
                minWidth: 0,
                verticalAlign: 'baseline',
                lineHeight: 'inherit',
                '&:hover': {
                  textDecoration: 'underline',
                  backgroundColor: 'transparent'
                }
              }}
            />
          ]}
        />
      ) : (
        t('aiSuggestion:chat.greetingMessage')
      )
    },
    ...(!contentSelectionMissing
      ? [
          {
            type: aiChatMessageTypes.buttonInput,
            buttons: [
              {
                label: t('aiSuggestion:chat.selectAllLabel'),
                onClick: () => {
                  logClickAiCopy('All');
                  setInputsLoading(
                    inputs.map(input => input.blueprintVariableId)
                  );
                  // Get remaining inputs using the form values
                  const remainingInputs = filter(inputs, input => {
                    return !includes(formValues, input.blueprintVariableId);
                  });

                  const fields = map(remainingInputs, input => {
                    return { blueprintVariableId: input.blueprintVariableId };
                  });

                  getAiTextSuggestions({
                    variables: {
                      input: {
                        fields,
                        catalogId: architecture.catalog?.id || '',
                        catalogFilter: {
                          id: {
                            in: businessObjectId ? [businessObjectId] : []
                          }
                        },
                        productId
                      }
                    }
                  })
                    .then(result => {
                      const fields = result?.data?.aiTextSuggestions?.fields;
                      if (fields) {
                        forEach(fields, field => {
                          const input = find(inputs, {
                            blueprintVariableId: field?.blueprintVariableId
                          });

                          if (input) {
                            const existingSuggestions =
                              formValues?.[input.blueprintVariableId]
                                ?.suggestions || [];

                            methods.setValue(input.blueprintVariableId, {
                              suggestions: [
                                ...existingSuggestions,
                                { text: field?.suggestions?.[0] }
                              ]
                            });
                          }
                        });
                      }
                    })
                    .catch(e => {
                      handleError(e);
                    })
                    .finally(() => {
                      setInputsLoading([]);
                    });
                },
                disabled:
                  Object.keys(selectedInputValues).length === inputs.length ||
                  inputsLoading.length === inputs.length
              },
              ...buttons
            ]
          },
          ...adCopySuggestionMessages
        ]
      : [])
  ];

  useEffect(() => {
    if (messagesEndRef.current) {
      messagesEndRef.current.scrollIntoView();
    }
  }, [messages.length]);

  const theme = useTheme();

  interface Theme {
    zIndex: { appBar: number; tooltip: number };
  }

  return (
    <FormProvider {...methods}>
      <>
        {aiChatOpen && (
          <Box
            data-cy="ai-chat-window-container"
            sx={{
              position: 'fixed',
              bottom: 80,
              right: aiChatWindowDimensions.xPosition,
              zIndex: theme => (theme as Theme)?.zIndex?.appBar - 10,
              display: 'flex',
              flexDirection: 'column',
              width: aiChatWindowDimensions.width,
              height: aiChatWindowDimensions.height,
              minHeight: aiChatWindowDimensions.minHeight,
              bgcolor: 'background.default',
              boxShadow: 2,
              [theme.breakpoints.down(DRAWER_FULL_SCREEN_BREAKPOINT)]: {
                zIndex: theme => (theme as Theme).zIndex.appBar - 20
              },
              [theme.breakpoints.down('sm')]: {
                right: 0,
                width: '100%',
                height: '100vh',
                bottom: 0,
                zIndex: theme => (theme as Theme).zIndex.tooltip
              }
            }}
          >
            <AiChatHeader onClose={toggleAiChatWindow} type={type} />
            <Box
              sx={{
                display: 'flex',
                flexGrow: 1,
                px: 2,
                py: 1,
                flexDirection: 'column',
                gap: 2,
                overflowY: 'auto'
              }}
            >
              {messages.map(message => (
                <AiChatMessage
                  // TODO: Figure out a key?
                  // key={message.text}
                  type={message.type}
                  text={message.text}
                  buttons={message.buttons}
                  input={message.input}
                  sharedInputProps={sharedInputProps}
                  setIsThinking={setIsThinking}
                />
              ))}

              {/* This is the reference for scrolling to the bottom */}
              <Box ref={messagesEndRef} />
            </Box>
            <AiChatFooter
              loading={loading || isThinking}
              applyAllDisabled={!Object.keys(selectedInputValues)?.length}
            />
          </Box>
        )}
        <AiChatFloatingButton
          isOpen={aiChatOpen}
          onClick={toggleAiChatWindow}
        />
      </>
    </FormProvider>
  );
};

export default connect(null, { showBusinessObjectSelectorModal })(AiChat);
